import React from 'react'
import './review-options-panel.css'
import moment from 'moment'
import { AuthService } from '../../../../../../../../../services/auth-service'

const ReviewOptionsPanel = ({
  mentors,
  courses,
  modules,
  selectedDate,
  onSelectedMentorChange,
  onSelectedCourseChange,
  onSelectedModuleChange,
  onDateChange,
  isEdit,
}) => {
  const mentorsOption = mentors.map(mentor => {
    const { id, firstName, lastName } = mentor.key
    return (
      <option value={id} key={`mentor_${id}`}>
        {firstName} {lastName}
      </option>
    )
  })

  const coursesOptions = courses.map(({ id, name }) => {
    return (
      <option value={id} key={`module_option_${id}`}>
        {name}
      </option>
    )
  })

  const modulesOptions = modules.map(({ id, name, position }) => {
    return (
      <option value={id} key={`module_option_${id}`}>
        {`${position}. ${name}`}
      </option>
    )
  })
  const currentMentor = AuthService.currentUserValue()

  return isEdit ? null : (
    <div className="review-options-block">
      <select
        className="form-control"
        id="review-mentor-select"
        defaultValue={currentMentor.id}
        onChange={() => onSelectedMentorChange()}
      >
        {mentorsOption}
      </select>

      <select className="form-control" id="review-course-select" onChange={() => onSelectedCourseChange()}>
        {coursesOptions}
      </select>

      <select className="form-control" id="review-module-select" onChange={() => onSelectedModuleChange()}>
        {modulesOptions}
      </select>
      <input
        type="date"
        value={moment(selectedDate).format('yyyy-MM-DD')}
        className="ScheduleSettings__date"
        onChange={event => {
          const oldValue = moment(selectedDate)
          const newValue = moment(event.target.value)
          oldValue.year(newValue.year())
          oldValue.month(newValue.month())
          oldValue.date(newValue.date())
          onDateChange(moment(oldValue).toDate())
        }}
      />
      <input
        type="time"
        value={moment(selectedDate).format('HH:mm')}
        className="ScheduleSettings__date"
        onChange={event => {
          const oldValue = moment(selectedDate)
          const newValue = event.target.value
          oldValue.hour(parseInt(newValue.substring(0, 2), 10))
          oldValue.minute(parseInt(newValue.substring(3, 5), 10))
          onDateChange(moment(oldValue).toDate())
        }}
      />
    </div>
  )
}

export default ReviewOptionsPanel
