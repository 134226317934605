import React from 'react'
import cn from 'classnames'
import { Col, Form, FormControl } from 'react-bootstrap'

type Option = {
  id: number | string
  name: string
}

interface OptionSelectProps {
  labelTitle: string
  handleSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
  isDisabled: boolean
  optionsList: Option[]
  defaultOption: string
}

export const OptionSelect: React.FC<OptionSelectProps> = ({
  labelTitle,
  handleSelect,
  isDisabled,
  optionsList,
  defaultOption,
}) => {
  return (
    <Col>
      <Form.Label
        className={cn({
          'analytics-options__label_disabled': isDisabled,
        })}
      >
        {labelTitle}
      </Form.Label>
      <FormControl
        as="select"
        className="analytics-options__control"
        onChange={handleSelect}
        disabled={isDisabled}
        defaultValue=""
      >
        <option value="">{defaultOption}</option>
        {optionsList.map(item => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </FormControl>
    </Col>
  )
}
