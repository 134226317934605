import React from 'react'
import './prrmoderation-comments-list-row.css'
import { prepareCommentText } from '../../../../../utils/ComentsUtils'
import { studentHost } from '../../../../../config'

const PreModerCommentsListRow = ({ comment, onSubmit, onDelete }) => {
  const {
    text,
    courseTaskPosition,
    chapterPosition,
    modulePosition,
    coursePosition,
    user: { id, email, firstName, lastName },
  } = comment
  const preparedText = prepareCommentText(text)
  return (
    <tr>
      <td>
        <pre className="moder-comment-text">{preparedText}</pre>
      </td>
      <td>
        {' '}
        {email} <br /> {`${firstName} ${lastName}`}
      </td>
      <td>
        <div className="moder-comment-actions">
          <a
            className="btn btn-sm btn-info"
            target="_blank"
            rel="noopener noreferrer"
            href={`${studentHost}/fake/user/${id}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}#comment${comment.id}`}
          >
            Перейти
          </a>
          <button type="button" className="btn btn-sm btn-success" onClick={() => onSubmit(comment.id)}>
            Подтвердить
          </button>
          <button type="button" className="btn btn-sm btn-danger" onClick={() => onDelete(comment.id)}>
            Удалить
          </button>
        </div>
      </td>
    </tr>
  )
}

export default PreModerCommentsListRow
