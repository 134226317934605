import React from 'react'
import { positions, Provider as AlertProvider, transitions } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert'
import AlertTemplate from 'react-alert-template-basic'

const StudentsMentorItem = ({ student, onDelete, onSelectModal }) => {
  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '50px',
    transition: transitions.SCALE,
  }

  const confirmDeleteStudentFromDirection = () => {
    confirmAlert({
      title: `Удаление студента из направления`,
      message:
        'Вы уверены что хотите удалить студента с направления, тем самым студент потеряет все свои решения задач?',
      buttons: [
        {
          label: 'Удалить',
          onClick: onDelete,
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <div className="list-group-item task-item" style={{ borderRadius: 0 }}>
          <div className="row">
            <div className="col-sm-6">
              <p>
                {student.firstName} {student.lastName}
              </p>
              {student.email}
            </div>
            <div className="col-sm-6" style={{ textAlign: 'right' }}>
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-default" onClick={() => onSelectModal(student)}>
                  Выбрать другого ментора
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-default"
                  onClick={() => confirmDeleteStudentFromDirection()}
                >
                  <span className="mdi mdi-close-outline" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </AlertProvider>
    </>
  )
}

export default StudentsMentorItem
