import React from 'react'
import { FormControl, FormLabel } from 'react-bootstrap'
import { withLastLocation } from 'react-router-last-location'
import CKEditor from 'ckeditor4-react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import MentorCheckTaskService, {
  answerTypeLabel,
} from '../../../../../services/admin-services/tasks/mentor-check-tasks-service'
import { formatErrors } from '../../helper'

const answerTypeOptions = [
  { label: '', value: '' },
  ...Object.entries(answerTypeLabel).map(([type, label]) => ({ label, value: type })),
]

class MentorCheckTaskForm extends React.Component {
  service = new MentorCheckTaskService()

  state = {
    task: {},
    error: '',
    validationErrors: {},
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate = () => {
    const {
      match: {
        params: { taskId = 0 },
      },
      catchErrorAlert,
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({ task })
        })
        .catch(error => catchErrorAlert(error))
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  editAnswerType = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          answerType: value,
        },
      }
    })
  }

  validateForm = () => {
    const { title, description } = this.state.task
    const isAnyError = title === '' || description === ''
    this.setState({ error: isAnyError ? 'Ошибка валидации' : '' })
    return isAnyError
  }

  render() {
    const { callback, lastLocation, history } = this.props
    const { task, error, validationErrors } = this.state
    const { id = 0, title = '', points = 0, description = '', answerType } = task
    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} проверяемой задачи</h1>
        <div className="form-group">
          <label>Заголовок задачи</label>
          <FormControl
            onChange={this.editTitle}
            type="text"
            value={title}
            style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
          />
          {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label>Тип ответа</label>
          <FormControl
            as="select"
            style={{ borderColor: validationErrors.answerType ? '#DC3645' : '' }}
            value={answerType}
            onChange={this.editAnswerType}
          >
            {answerTypeOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormControl>
          {validationErrors.answerType && <FormLabel className="text-danger">{validationErrors.answerType}</FormLabel>}
        </div>
        <div className="form-group">
          <label>Количество очков за решение задачи</label>
          <FormControl type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {description !== undefined && (
              <CKEditor name="description" onChange={this.editDescription} data={description} />
            )}
          </div>
          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
        </div>

        {error !== '' && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="btn-group">
          <div id="msg_box" className="" />
          <button
            className="btn btn-lg btn-success"
            type="button"
            onClick={() => {
              callback(task)
                .then(() => {
                  document.location = lastLocation ? lastLocation.pathname : '/admin/tasks/check'
                })
                .catch(err => {
                  const formattedErrors = formatErrors(err.errors)

                  this.setState({
                    error:
                      err.code && err.code === 500
                        ? 'Ошибка сервера. Подробная информация - в консоли'
                        : 'Ошибка валидации',
                    validationErrors: formattedErrors ?? {},
                  })
                })
            }}
          >
            Сохранить
          </button>
          <button
            className="btn btn-lg btn-danger"
            type="button"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              if (lastLocation) {
                history.push(lastLocation.pathname)
              } else {
                history.push('/admin/tasks/check')
              }
            }}
          >
            Отменить
          </button>
        </div>
      </>
    )
  }
}

export default withLastLocation(withReactAlert(MentorCheckTaskForm))
