import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { withPagination } from '../../../hoc'
import { GenericTask } from '../../../../services/admin-services/tasks/generic-service/generic-task-service'
import { GenericTaskFormPage } from './generic-task-form-page'

export interface GenericTaskPageProps<T extends GenericTask> {
  linkName: string
  service: any
  pageTitle: string
  formPageTitlePart: string
  TaskListComponent: any
  FormTaskComponent: any
  presendInterceptor: (val: Partial<T>) => any
  pregetInterceptor: (val: Partial<T>) => any
}

export interface GenericTaskFormComponent {
  taskId?: number
  callback: any
}

function GenericTaskPage<T extends GenericTask>({
  linkName,
  pageTitle,
  service,
  formPageTitlePart,
  TaskListComponent,
  FormTaskComponent,
  presendInterceptor,
  pregetInterceptor,
}: GenericTaskPageProps<T>) {
  const PaginationTasksList = withReactAlert(withPagination(TaskListComponent, service, pageTitle))

  useEffect(() => {
    document.title = `Администратор | ${pageTitle}`
  }, [])

  return (
    <div>
      <Switch>
        <Route path={`/admin/tasks/${linkName}/`} exact render={props => <PaginationTasksList {...props} />} />

        <Route
          path={`/admin/tasks/${linkName}/add`}
          exact
          render={props => (
            <GenericTaskFormPage<T>
              {...props}
              linkName={linkName}
              pageTitlePart={formPageTitlePart}
              taskId={0}
              callback={service.add}
              getTaskCallback={service.getById}
              FormTaskComponent={FormTaskComponent}
              presendInterceptor={presendInterceptor}
              pregetInterceptor={pregetInterceptor}
            />
          )}
        />

        <Route
          path={`/admin/tasks/${linkName}/edit/:taskId`}
          exact
          render={props => (
            <GenericTaskFormPage<T>
              {...props}
              linkName={linkName}
              pageTitlePart={formPageTitlePart}
              callback={service.update}
              getTaskCallback={service.getById}
              FormTaskComponent={FormTaskComponent}
              presendInterceptor={presendInterceptor}
              pregetInterceptor={pregetInterceptor}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default GenericTaskPage
