import RestPaginationService from './rest-pagination-service'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { UnknownThing, ZoomServiceInterface } from './interfaces/zoom-service'

class ZoomService extends RestPaginationService implements ZoomServiceInterface {
  constructor() {
    super('/api/zoom')
  }

  public createMeeting = (reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/create_meeting/${reviewId}`, queryOption)
  }

  public getBusyAccount = (filterDate: string) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<UnknownThing>(`${this.sourceURL}/planning?filterDate=${filterDate}`, queryOption)
  }
}

/**
 * @todo Понять, что описывает тип и переименовать
 * @deprecated
 * @see getBusyAccount
 * @author velialiev
 */

export default ZoomService
