import React, { Component } from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import './review-questions-list.css'
import ReactModal from 'react-modal'
import { SortableContainer } from 'react-sortable-hoc'
import { FormControl } from 'react-bootstrap'
import ReviewQuestionsListItem from './review-questions-list-item'
import ChiefQuestionsService from '../../../../../../services/chief-services/chief-question-service'
import ReviewQuestionsHeader from '../review-questions-header'
import Spinner from '../../../../../spinner'
import { copyArray } from '../../../../../../utils/ArraysUtils'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../shared/modal-view'

class ReviewQuestionsList extends Component {
  questionsService = new ChiefQuestionsService()

  state = {
    questions: [],
    modules: [],
    courses: [],
    directions: [],
    selectedModule: {},
    questionsLoaded: true,
    modulesLoaded: true,
    directionsLoaded: true,
    coursesLoaded: true,
    isModalOpen: false,
    isCreate: true,
    isNotFoundCourse: false,
    isNotFoundModule: false,
  }

  componentDidMount() {
    this.updateDirection()
    document.title = 'Шеф-ментор | Вопросы к ревью'
  }

  onChangeSelector = e => {
    const moduleId = e.target.value
    this.setState({ selectedModule: moduleId })
    this.updateQuestionsList(moduleId)
  }

  onChangeSelectorCourse = e => {
    const id = e.target.value
    this.updateModules(id)
  }

  onChangeSelectorDirection = e => {
    const id = e.target.value
    this.updateCourse(id)
  }

  onQuestionLoaded = questions => {
    this.setState({
      questions,
      questionsLoaded: false,
    })
  }

  onModulesLoaded = modules => {
    if (modules.length === 0) {
      this.setState({ modulesLoaded: false, isNotFoundModule: true })
      return
    }
    const { id } = modules[0]
    this.setState(
      {
        modules,
        selectedModule: id,
        modulesLoaded: false,
        isNotFoundModule: false,
      },
      () => {
        this.updateQuestionsList(id)
      }
    )
  }

  onCoursesLoaded = courses => {
    if (courses.length === 0) {
      this.setState({ coursesLoaded: false, isNotFoundCourse: true, isNotFoundModule: true })
      return
    }
    const { id } = courses[0]
    this.setState(
      {
        courses,
        coursesLoaded: false,
        isNotFoundCourse: false,
      },
      () => {
        this.updateModules(id)
      }
    )
  }

  onDirectionLoaded = directions => {
    const { id } = directions[0]
    this.setState(
      {
        directions,
        directionsLoaded: false,
      },
      () => this.updateCourse(id)
    )
  }

  openModal = async isCreate => {
    this.setState({ isModalOpen: true, isCreate })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  removeQuestion = id => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedModule } = this.state
    this.questionsService
      .removeQuestionById(id)
      .then(() => {
        reactAlert.success(`Вопрос успешно удален`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  updateQuestion = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedModule } = this.state
    const { value } = document.getElementById(`question-area`)
    const answer = document.getElementById(`answer-area`).value
    const id = document.getElementById(`id-question`).value
    const question = {
      id,
      value,
      answer,
    }
    this.questionsService
      .updateQuestion(question)
      .then(() => {
        reactAlert.success(`Вопрос успешно обновлен`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  replaceQuestions = ({ oldIndex, newIndex }) => {
    const { catchErrorAlert } = this.props
    const { questions } = this.state
    const newQuestions = copyArray(questions)
    const first = questions[oldIndex]
    const second = questions[newIndex]
    this.questionsService
      .replaceQuestionsPositions(first.id, second.id, oldIndex, newIndex)
      .then(() => {
        newQuestions[oldIndex] = questions[newIndex]
        newQuestions[newIndex] = first
        this.setState({ questions: newQuestions })
      })
      .catch(err => catchErrorAlert(err))
  }

  createQuestion = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedModule } = this.state
    const { value } = document.getElementById(`question-area`)
    const answer = document.getElementById(`answer-area`).value
    const { questions } = this.state
    const position = questions.length + 1
    const question = {
      value,
      answer,
      position,
    }

    this.questionsService
      .createQuestionByModuleId(question, selectedModule)
      .then(() => {
        reactAlert.success(`Вопрос успешно создан`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  updateQuestionsList = id => {
    this.questionsService.getQuestionsListByModuleId(id).then(this.onQuestionLoaded)
  }

  updateModules = id => {
    this.questionsService.getModulesByCourseId(id).then(this.onModulesLoaded)
  }

  updateCourse = id => {
    this.questionsService.getCoursesByDirectionId(id).then(this.onCoursesLoaded)
  }

  updateDirection = () => {
    this.questionsService.getDirections().then(this.onDirectionLoaded)
  }

  render() {
    const {
      questions,
      modules,
      directions,
      directionsLoaded,
      questionsLoaded,
      modulesLoaded,
      isModalOpen,
      isCreate,
      coursesLoaded,
      courses,
      isNotFoundCourse,
      isNotFoundModule,
    } = this.state
    if (questionsLoaded || modulesLoaded || coursesLoaded || directionsLoaded) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    }
    const SortableList = SortableContainer(({ quest }) => {
      return (
        <ol className="list-group list-question mentor-review-questions" style={{ marginBottom: 0 }}>
          {quest.map((question, index) => (
            <ReviewQuestionsListItem
              key={`question-${question.position}`}
              index={index}
              question={question}
              removeQuestion={this.removeQuestion}
              openModal={this.openModal}
            />
          ))}
        </ol>
      )
    })

    const modalHeader = isCreate ? `Добавление теоретического вопроса` : `Изменение теоретического вопроса`
    const modalFunc = isCreate ? this.createQuestion : this.updateQuestion
    ReactModal.setAppElement('#root')

    return (
      <div className="mentor-content">
        <div className="mentor-header">
          <ReviewQuestionsHeader
            modules={modules}
            courses={courses}
            directions={directions}
            onChangeSelectorCourse={this.onChangeSelectorCourse}
            onChangeSelectorModule={this.onChangeSelector}
            onChangeSelectorDirection={this.onChangeSelectorDirection}
            openModal={this.openModal}
            isNotFoundCourse={isNotFoundCourse}
            isNotFoundModule={isNotFoundModule}
          />
        </div>

        <div className="mentor-section">
          {isNotFoundCourse || isNotFoundModule ? (
            <ol className="mentor-review-questions" />
          ) : (
            <SortableList quest={questions} onSortEnd={this.replaceQuestions} distance={5} />
          )}
          <ReactModal
            isOpen={isModalOpen}
            contentLabel="Добавление вопроса"
            onRequestClose={() => this.closeModal()}
            ariaHideApp
            shouldFocusAfterRender
            shouldCloseOnEsc
            parentSelector={() => document.body}
            style={customStyles}
            appElement={document.getElementById(`root`)}
          >
            <ModalWrapper>
              <ModalHeader>
                {modalHeader}
                <span id="id-question"> </span>
              </ModalHeader>
              <ModalContent>
                <div className="modal-section">
                  <div className="question-text-header">Текст вопроса</div>
                  <FormControl type="textarea" id="question-area" />
                  <div className="question-text-header">Текст ответа</div>
                  <FormControl type="textarea" id="answer-area" />
                </div>
              </ModalContent>
              <ModalFooter>
                <ModalButton
                  action="assign"
                  onClick={() => {
                    modalFunc()
                    this.closeModal()
                  }}
                >
                  Сохранить
                </ModalButton>
                <ModalButton action="back" onClick={() => this.closeModal()} className="btn btn-danger">
                  Закрыть
                </ModalButton>
              </ModalFooter>
            </ModalWrapper>
          </ReactModal>
        </div>
      </div>
    )
  }
}

export default withReactAlert(ReviewQuestionsList)
