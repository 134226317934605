import { useState, useEffect } from 'react'
import { store } from 'react-notifications-component'
import UserProfileService from 'src/services/common-services/user-profile-service'
import { userEditSuccessTemplate } from 'src/config'
import { parseBirthdayToServer } from './parserBirthday'
import UserLaconicDto from '../../../model/user-dto/user-laconic-dto'

const userProfleSerivce = new UserProfileService()

export const useUserProfile = () => {
  const [userInfo, setUserInfo] = useState<UserLaconicDto>({
    id: -1,
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
  })
  const [isLoaded, handleLoad] = useState<boolean>(true)

  const handleInfo = (e: React.FormEvent<HTMLInputElement>, field: string): void => {
    const { value } = <HTMLInputElement>e.target
    setUserInfo({ ...userInfo, [field]: value })
  }

  const handleUpdateUser = (): void => {
    userProfleSerivce.updateUser({ ...userInfo, birthday: parseBirthdayToServer(userInfo.birthday) }).then(() => {
      store.addNotification({
        ...userEditSuccessTemplate,
        message: `Редактирование успешно выполнено`,
      })
    })
  }

  useEffect(() => {
    userProfleSerivce.getUserPrincipal().then((data: any) => {
      const { id, firstName, lastName, email, birthday } = data
      setUserInfo({ id, firstName, lastName, email, birthday })
      handleLoad(false)
    })
  }, [])

  return {
    userInfo,
    isLoaded,
    handleInfo,
    handleUpdateUser,
  } as const
}
