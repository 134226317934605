import React, { useState, useEffect } from 'react'
import './student-debts.css'
import { useReactAlert } from 'src/hooks/useReactAlert'
import MentorReviewService from 'src/services/mentor-services/mentor-review-service'
import StudentDebtsList from './studetn-debts-list'

const mentorReviewService = new MentorReviewService()

const StudentDebts = ({
  selectedStudentDebtsInfo: { studentQuestions, studentTasks, studentName, studentId, studentReviewId },
  closeQuestion,
  reviewId,
  updateReview,
  moduleName,
  reviewTime,
  studentReviewCommentDtos,
  creatOrUpdateStudentReviewComment,
  deleteStudentReviewComment,
  setOldStudentQuestionStatusSuccess,
  setOldStudentQuestionStatusFailed,
}) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const [debtsList, setDebtsList] = useState(null)

  const updateStudentDebtsList = reviewStudentId => {
    mentorReviewService
      .getAllOldQuestionByStudentsReviewId(reviewStudentId)
      .then(debts => {
        setDebtsList(debts)
      })
      .then(() => {
        updateReview()
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  // eslint-disable-next-line no-shadow
  setOldStudentQuestionStatusSuccess = (studentReviewId, studentQuestionId) => {
    mentorReviewService
      .setOldStudentQuestionStatusSuccess(studentReviewId, studentQuestionId)
      .then(() => {
        reactAlert.success('Долг успешно закрыт')
        updateStudentDebtsList(studentReviewId)
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  // eslint-disable-next-line no-shadow
  setOldStudentQuestionStatusFailed = (studentReviewId, studentQuestionId) => {
    mentorReviewService
      .setOldStudentQuestionStatusFailed(studentReviewId, studentQuestionId)
      .then(() => {
        reactAlert.error('Долг оставлен')
        updateStudentDebtsList(studentReviewId)
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  useEffect(() => {
    if (studentReviewId) {
      updateStudentDebtsList(studentReviewId)
    }
  }, [studentReviewId])

  return (
    <div className="student-debts">
      <StudentDebtsList
        studentQuestions={studentQuestions}
        studentDebtsList={debtsList}
        studentName={studentName}
        studentTasks={studentTasks}
        closeQuestion={closeQuestion}
        studentId={studentId}
        studentReviewId={studentReviewId}
        reviewId={reviewId}
        moduleName={moduleName}
        reviewTime={reviewTime}
        studentReviewCommentDtos={studentReviewCommentDtos}
        creatOrUpdateStudentReviewComment={creatOrUpdateStudentReviewComment}
        deleteStudentReviewComment={deleteStudentReviewComment}
        setOldStudentQuestionStatusSuccess={setOldStudentQuestionStatusSuccess}
        setOldStudentQuestionStatusFailed={setOldStudentQuestionStatusFailed}
      />
    </div>
  )
}

export default StudentDebts
