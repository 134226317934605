import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import Spinner from 'src/components/spinner'
import Pagination from 'rc-pagination'
import { setTasksRatingPage } from 'src/store/review-analytics'
import { store } from 'react-notifications-component'
import CommonTable from 'src/components/shared/common-table'
import { Link } from 'react-router-dom'
import { useGetTasksRating } from './useGetTasksRating'
import SelectSortType from '../select-sort-type'
import { ReviewCourseTaskStatisticDto } from '../../../../../../../model/review-statistic/review-course-task-statistic-dto'

interface TasksRatingPageProps {
  startDate: string | null
  endDate: string | null
  isLoading: boolean
  errorMessage: string | null
  tasksRating: ReviewCourseTaskStatisticDto[] | null
  page: number
  count: number | null
  setPage: (page: number) => void
  sortType: string | null
  directionId: number | null
  courseId: number | null
  moduleId: number | null
  paymentType: string | null
}

const TasksRatingPage: React.FC<TasksRatingPageProps> = ({
  startDate,
  endDate,
  isLoading,
  errorMessage,
  tasksRating,
  page,
  count,
  setPage,
  sortType,
  directionId,
  courseId,
  moduleId,
  paymentType,
}) => {
  useGetTasksRating({
    startDate,
    endDate,
    page,
    sortType,
    directionId,
    courseId,
    moduleId,
    paymentType,
  })

  useEffect(() => {
    if (errorMessage) {
      store.addNotification({
        id: 'generalInfoError',
        message: errorMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
  }, [errorMessage])

  useEffect(() => {
    setPage(1)
  }, [directionId, courseId, moduleId, startDate, endDate, sortType])

  const TaskTableHeaders = [
    '#',
    'Расположение задачи',
    'Идентификатор \n(по позициям)',
    'Заголовок задачи',
    'Количество \n"доработок"',
    'Ссылка',
  ]

  const TaskTableBody = tasksRating
    ? tasksRating.map(row => [
        row.taskId,
        row.courseTaskLocationByName,
        row.courseTaskLocationByPosition,
        row.taskTitle,
        row.reviewsFailCount,
        <Link to={`/admin/tasks/code/edit/${row.courseTaskId}`} target="_blank">
          Посмотреть задачу
        </Link>,
      ])
    : null

  if (isLoading) {
    return (
      <div className="spinner-wrapper spinner-wrapper_center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <SelectSortType tab="tasks" />
      <CommonTable tableHeaders={TaskTableHeaders} tableBody={TaskTableBody} />
      <Pagination current={page} total={count || 0} onChange={current => setPage(current)} />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  startDate: state.reviewAnalytics.startDate,
  endDate: state.reviewAnalytics.endDate,
  isLoading: state.reviewAnalytics.tasksRating.isLoading,
  errorMessage: state.reviewAnalytics.tasksRating.errorMessage,
  tasksRating: state.reviewAnalytics.tasksRating.data,
  page: state.reviewAnalytics.tasksRating.page,
  count: state.reviewAnalytics.tasksRating.count,
  sortType: state.reviewAnalytics.tasksRating.sortType,
  directionId: state.reviewAnalytics.currentDirectionId,
  courseId: state.reviewAnalytics.currentCourseId,
  moduleId: state.reviewAnalytics.currentModuleId,
  paymentType: state.reviewAnalytics.currentPaymentType,
})

export default connect(mapStateToProps, {
  setPage: setTasksRatingPage,
})(TasksRatingPage)
