import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useReactAlert } from 'src/hooks/useReactAlert'
import UserProfileService from '../../../../services/common-services/user-profile-service'

import styles from './report-card.module.scss'
import { ReportGetDto } from '../report-wraper/report-wraper'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'

const cn = classNames.bind(styles)

type ReportCardProps = {
  report: ReportGetDto
}

const userProfileService = new UserProfileService()

const ReportCard: FC<ReportCardProps> = ({ report }) => {
  const { catchErrorAlert } = useReactAlert()
  const { text, countHours, firstName, lastName, reportDate, id, studentId } = report
  const { t } = useTranslation()
  const maxTextLength = 100
  const [isTextShort, setIsTextShort] = useState(true)
  const [uploadedImageURL, setUploadedImageURL] = useState<string | null>(null)

  useEffect(() => {
    userProfileService
      .getUserProfileById(studentId)
      .then(user => {
        if (user.avatarUrl) {
          setUploadedImageURL(convertResponseImageUrl(user.avatarUrl))
        }
      })
      .catch(error => catchErrorAlert(error))
  }, [])

  return (
    <div className={cn('report-card')}>
      <div className={cn('card-avatar')}>
        <img className={cn('card-img')} src={uploadedImageURL ?? '/images/man.png'} id="user_pic" alt="avatar" />
      </div>
      <div className={cn('report-data')}>
        <div className={cn('student-name')}>
          {firstName} {lastName}
        </div>
        <div className={cn('student-birthday')}>{reportDate}</div>
        <div className={cn('spend-time')}>
          <b>{t('TimeTaken')}:</b> {countHours}
          {t('HourShort')}
        </div>
        <p className={cn('task-progress-text', { 'task-progress-text--expand_true': !isTextShort })}>
          <b>{t('WhatDone')}:</b> {text}
        </p>
        {text.length > maxTextLength && (
          <button type="button" onClick={() => setIsTextShort(!isTextShort)} className={cn('toggle-expand-text')}>
            {isTextShort ? t('Show') : t('Hide')}
          </button>
        )}
      </div>
    </div>
  )
}

export default ReportCard
