import React, { Component, FC } from 'react'

const Table: FC<Props> = ({ header = [], data = [] }) => {
  return (
    <table className="table students-table">
      <thead>
        <tr>
          {header.map(cell => (
            <th key={cell.id}>{cell.content}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(row => (
          <tr key={row.id}>
            {row.cells.map(cell => (
              <td key={cell.id}>{cell.content}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

type Props = {
  header?: TableCell[]
  data?: TableRow[]
}

export type TableCell<Id = string | number, Content = string | number | Component<any, any>> = {
  id: Id
  content: Content
}

export type TableRow = {
  id: string | number
  cells: TableCell[]
}

export default Table
