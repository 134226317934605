import React from 'react'
import { Button } from 'react-bootstrap'
import NotificationsListRow from '../notifictions-list-row'
import './notifiation-list.css'
import NotificationEditModal from '../notifiaction-edit-modal/notification-edit-modal'

class NotificationsList extends React.Component {
  state = {
    show: false,
    modal: {
      notification: {},
    },
  }

  onSelectForEdit = entity => {
    this.setState({
      show: true,
      modal: {
        notification: entity,
      },
    })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleSaveEdit = notification => {
    const { onSaveEntity } = this.props
    onSaveEntity(notification)
    this.setState({
      show: false,
      modal: {
        notification: {},
      },
    })
  }

  render() {
    const { entities, onDelete } = this.props
    const { show } = this.state
    const {
      modal: { notification },
    } = this.state
    const notificationsRender = entities.map(noti => {
      return (
        <NotificationsListRow
          key={noti.id}
          onDelete={onDelete}
          onSelectForEdit={this.onSelectForEdit}
          notification={noti}
        />
      )
    })
    return (
      <>
        <Button id="create-notification-button" variant="primary" onClick={() => this.onSelectForEdit({})}>
          Создать уведомление
        </Button>

        <table className="table table-striped admin-notification-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Заголовок</th>
              <th>Текст уведомления</th>
              <th>Важность уведомления</th>
              <th>Время важности уведомления</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{notificationsRender}</tbody>
        </table>

        <NotificationEditModal
          show={show}
          onClose={this.handleClose}
          onSave={this.handleSaveEdit}
          notification={notification}
        />
      </>
    )
  }
}

export default NotificationsList
