import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import AdminNotificationService from '../../../../services/admin-services/admin-notification-service'
import { withPagination } from '../../../hoc'
import NotificationsList from './notifications-list/notifications-list'

const NotificationsPage = () => {
  useSetDocumentTitle('Администратор | Уведомления')
  const service = new AdminNotificationService()
  const PaginationPreModerComments = withReactAlert(withPagination(NotificationsList, service, 'Уведомления', `admin`))
  return <PaginationPreModerComments />
}

export default NotificationsPage
