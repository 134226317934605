import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { serverHost } from '../../config'
import { ChiefStudentServiceInterface } from '../interfaces/chief-services/chief-student-service'
import { PaymentTypeGetDto } from '../../model/payment-dto/payment-type-get-dto'
import { RecruitmentDto } from '../../model/recruitment-dto/recruitment-dto'

export default class ChiefStudentService implements ChiefStudentServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/chief/user`
  }

  setStudentDirectionCompleteByStudentId = (id: number) => {
    const queryOption: any = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/student/direction/complete/${id}`
    return ApiService.request(url, queryOption)
  }

  getAllPaymentTypes = (): Promise<PaymentTypeGetDto[]> => {
    const url = `${serverHost}/api/chief/user/payment_types_dtos`
    const queryOption: any = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }

  getAllRecruitmentByChiefId = (chiefId: number): Promise<RecruitmentDto[]> => {
    const url = `${serverHost}/api/chief/recruitment?chiefId=${chiefId}`
    const queryOption: any = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}
