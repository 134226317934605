import React from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import PreModerCommentsListRow from './premoderation-comments-list-row'
import PreModerCommentsService from '../../../../../services/premoder-comments-service'

class PreModerCommentsList extends React.Component {
  service = new PreModerCommentsService()

  handleSubmit = id => {
    const { onUpdate, catchErrorAlert } = this.props
    this.service
      .submitById(id)
      .then(() => {
        onUpdate()
      })
      .catch(error => catchErrorAlert(error))
  }

  render() {
    const { entities, onDelete } = this.props
    const commentsRender = entities.map(comment => {
      return (
        <PreModerCommentsListRow key={comment.id} onDelete={onDelete} onSubmit={this.handleSubmit} comment={comment} />
      )
    })

    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <td style={{ maxWidth: '60%' }}>
                <b>Текст комментария</b>
              </td>
              <td>
                <b>Автор</b>
              </td>
              <td>
                <b>Модерация</b>
              </td>
            </tr>
          </thead>
          <tbody>{commentsRender}</tbody>
        </table>
      </div>
    )
  }
}

export default withReactAlert(PreModerCommentsList)
