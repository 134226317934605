import React from 'react'
import Modal from 'react-modal'
import '../planning-review-create-modal/planning-review-create-modal.css'
import { zoomAccounts } from '../../../../../../../config'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    overflow: 'hidden',
    padding: 0,
    margin: '44px 0',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const PlanningReviewEditModal = function PlanningReviewEditModal({
  courses,
  modules,
  mentors,
  students,
  selectedDate,
  selectedTime,
  selectedCourseId,
  selectedModuleId,
  selectedMentorId,
  selectedZoomAccount,
  onChangeCourseSelector,
  onChangeModuleSelector,
  onChangeMentorSelector,
  onChangeZoomAccount,
  availableSlots,
  onAvailableSlotsChange,
  modalShowed,
  updateReview,
  setDefaultReviewData,
  onClose,
  showConfirmRemoveReviewModal,
  studentsRegistered,
  zoomLink,
  balancing,
  setBalancing,
}) {
  const coursesNames = courses.map(course => {
    const { id, name } = course

    return (
      <option key={id} value={id} selected={id === selectedCourseId}>
        {name}
      </option>
    )
  })

  const modulesNames = modules.map(module => {
    const { id, name } = module

    return (
      <option key={id} value={id} selected={id === selectedModuleId}>
        {name}
      </option>
    )
  })

  const mentorsNames = mentors.map(({ id, firstName, lastName }) => {
    return (
      <option key={id} value={id} selected={selectedMentorId === id}>
        {firstName} {lastName}
      </option>
    )
  })

  const zoomAccs = zoomAccounts.map(acc => {
    return (
      <option key={`zoom-acc-${acc}`} value={acc} selected={acc === selectedZoomAccount}>
        {acc}
      </option>
    )
  })

  const zoomBlock = zoomLink ? (
    <a href={zoomLink} className="zoom-link" target="_blank" rel="noopener noreferrer">
      Перейти
    </a>
  ) : (
    ` конференция ещё не создана`
  )

  const studRow = students.map(dto => {
    const { reviewStatus } = dto
    let classes = 'stud-row '
    let mdiClasses = 'mdi mdi-16 '
    // eslint-disable-next-line default-case
    switch (reviewStatus) {
      case 'WAITED':
        classes += 'gray'
        break
      case `SUCCESS`:
        classes += 'green'
        mdiClasses += 'mdi-check'
        break
      case `FAILED`:
        classes += 'rad'
        mdiClasses += 'mdi-block-helper'
        break
    }
    return (
      <div key={dto.studentReviewId} className={classes}>
        <span>{dto.studentName}</span>
        <i className={mdiClasses} />
      </div>
    )
  })
  return (
    <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <div className="planning-review-modal">
        <header className="add">
          <h3 id="planning-review-modal-header">Редактирование ревью</h3>
        </header>
        <main>
          <span className="label-box">
            <label>
              Дата редактирования ревью: {selectedDate} в {selectedTime}
            </label>
          </span>
          <span className="selector-box">
            <label>
              <span>Курс:</span>
              <select
                className="course-selector form-control"
                onChange={onChangeCourseSelector}
                disabled={studentsRegistered}
              >
                {coursesNames}
              </select>
            </label>
            <label>
              <span>Модуль:</span>
              <select
                className="module-selector form-control"
                onChange={onChangeModuleSelector}
                disabled={studentsRegistered}
              >
                {modulesNames}
              </select>
            </label>
          </span>
          <span className="selector-box">
            <label htmlFor="mentorName">
              <span>Назначить ментора:</span>
              <select name="mentorName" className="course-select form-control" onChange={onChangeMentorSelector}>
                <option key={0} value={0}>
                  Ментор не назначен
                </option>
                {mentorsNames}
              </select>
            </label>
            <label htmlFor="zoomAcc">
              <span>Назначить Zoom аккаунт:</span>
              <select name="zoomAcc" className="module-select form-control" onChange={onChangeZoomAccount}>
                <option key={0} value={0}>
                  Зум аккаунт не выбран
                </option>
                {zoomAccs}
              </select>
            </label>
          </span>

          <span className="selector-box">
            <label htmlFor="reviewMembersCount">
              <span>Количество человек:</span>
              <input
                name="availableSlots"
                className="form-control"
                type="text"
                inputMode="numeric"
                value={availableSlots}
                onChange={onAvailableSlotsChange}
              />
            </label>

            {balancing !== null && (
              <label>
                <span>Ревью участвует в балансировке:</span>
                <input
                  className="form-control planning-review-checkbox"
                  type="checkbox"
                  checked={balancing}
                  onChange={() => setBalancing(!balancing)}
                />
              </label>
            )}
          </span>

          <label className="students-rows">
            <span>Студенты:</span>
            {studRow}
          </label>
          <label className="zoom-link-label">
            <span>
              Ссылка на zoom конференцию:
              {zoomBlock}
            </span>
          </label>
        </main>
        <footer>
          <span className="planning-review-modal-group-button">
            <span className="wrap-block">
              <button
                type="button"
                className="save-button"
                onClick={() => {
                  updateReview()
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Сохранить
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={() => {
                  onClose()
                  showConfirmRemoveReviewModal()
                }}
              >
                Удалить
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Отменить
              </button>
            </span>
          </span>
        </footer>
      </div>
    </Modal>
  )
}

export default PlanningReviewEditModal
