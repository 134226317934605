import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-modal'
import { Formik, getIn } from 'formik'
import date from 'date-and-time'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { AuthService } from 'src/services/auth-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { DirectionGetDto } from 'src/model/direction-model'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { MentorGetDto } from 'src/model/mentor-model'

import styles from '../recruitment-modal.module.scss'
import { useReactAlert } from '../../../../../../../../hooks/useReactAlert'
import { RecruitmentServiceInterface } from '../../../../../../../../services/interfaces/curator-services/curator-recruitment-service'
import { DirectionCourseDto } from '../../../../../../../../model/courses-dto/direction-course-dto'
import { DeadlineCourseDto } from '../../../../../../../../model/courses-dto/course-deadline-dto'

interface CreateRecruitmentModal {
  currentCourses: DirectionCourseDto[]
  directions: DirectionGetDto[]
  show: boolean
  onHide: () => void
  mentors: MentorGetDto[]
  setCurrentDirectionId: Dispatch<SetStateAction<string>>
  recruitmentService: RecruitmentServiceInterface
}

interface Errors {
  directionId?: string
  commonRecruitmentChannelName?: string
  deadlinesInfo?: string
  name?: string
  channelName?: string
}

export enum ChannelState {
  CREATE_CHANNEL,
  CHANNEL_EXIST,
}

export type InitialForm = {
  name: string
  directionId?: string
  channelName: string | null
  mentorId: string
  commonRecruitmentChannelName: string
  startTraining: string
  deadlinesInfo: DeadlineCourseDto[]
}

function formatDeadlineDates(deadlines: DeadlineCourseDto[]): DeadlineCourseDto[] {
  const isDateData = (dateData: string | Date): dateData is string => {
    return typeof dateData === 'string'
  }
  return deadlines.map(deadline => ({
    ...deadline,
    deadlineDate: isDateData(deadline.deadlineDate)
      ? date.parse(deadline.deadlineDate, 'DD.MM.YYYY', true)
      : date.format(deadline.deadlineDate, 'DD.MM.YYYY'),
  }))
}

const CreateRecruitmentModal = ({
  currentCourses,
  mentors,
  directions,
  setCurrentDirectionId,
  onHide,
  recruitmentService,
  show,
}: CreateRecruitmentModal) => {
  const currentRole = AuthService.currentUserValue()?.role.name
  const recruitmentFormRef = useRef<HTMLFormElement>(null)
  const [showDeadlines, setShowDeadlines] = useState(false)
  const [updateDeadlines, setUpdateDeadlines] = useState(true)
  const [channelStatus, setChannelStatus] = useState(ChannelState.CREATE_CHANNEL)
  const { reactAlert, catchErrorAlert } = useReactAlert()
  const submitRecruitmentForm = () => {
    if (recruitmentFormRef.current) {
      recruitmentFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const validate = ({
    directionId,
    commonRecruitmentChannelName,
    deadlinesInfo,
    name,
    channelName,
  }: {
    name: string
    channelName: string
    directionId: string
    mentorId: string
    startTraining: Date
    commonRecruitmentChannelName: string
    deadlinesInfo: DeadlineCourseDto[]
  }) => {
    const errors: Errors = {}
    if (!directionId && currentRole === RoleEnum.CURATOR) {
      errors.directionId = 'Нужно выбрать направление'
    }
    if (!commonRecruitmentChannelName) {
      errors.commonRecruitmentChannelName = 'Нужно ввести общий для групп потока Slack канал'
    }

    if (!name) {
      errors.name = 'Нужно ввести название'
    }

    if (!channelName && channelStatus === ChannelState.CHANNEL_EXIST) {
      errors.channelName = 'Нужно ввести имя канала'
    }

    deadlinesInfo.forEach(item => {
      if (item.deadlineDate === '') {
        errors.deadlinesInfo = 'Нужно ввести дедлайны для всех курсов'
      }
    })
    return errors
  }

  const handleChangeChannelStatus = (setFieldValue: (filed: string, value: string) => void) => {
    if (channelStatus === ChannelState.CHANNEL_EXIST) {
      setChannelStatus(ChannelState.CREATE_CHANNEL)
      setFieldValue('channelName', '')
    } else {
      setChannelStatus(ChannelState.CHANNEL_EXIST)
    }
  }

  return (
    <Modal isOpen={show} onRequestClose={onHide} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Создание набора</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            initialStatus={{ apiErrors: {} }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              const submitData: InitialForm = {
                ...values,
                channelName: values.channelName ? values.channelName : null,
                startTraining: date.format(values.startTraining, 'DD.MM.YYYY'),
                deadlinesInfo: formatDeadlineDates(values.deadlinesInfo),
              }
              if (currentRole === RoleEnum.CHIEF_MENTOR) {
                delete submitData.directionId
              }

              recruitmentService
                .createRecruitment(submitData)
                .then(() => {
                  reactAlert.success('Набор успешно создан!')
                  onHide()
                })
                .catch(catchErrorAlert)
            }}
            initialValues={{
              name: '',
              directionId: '',
              channelName: '',
              mentorId: '',
              commonRecruitmentChannelName: '',
              startTraining: new Date(),
              deadlinesInfo: formatDeadlineDates([]),
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit, errors, status }) => {
              const err = getIn(status, ['apiErrors', 'error'])

              return (
                <Form onSubmit={handleSubmit} ref={recruitmentFormRef}>
                  <Form.Group controlId="name">
                    <Form.Label>Название группы в EWP</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="name"
                      type="text"
                      placeholder="Введите название"
                      required
                      value={values.name}
                    />
                    {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                  </Form.Group>
                  <Form.Group controlId="channelName">
                    <Form.Label>Имя канала набора</Form.Label>
                    <div>
                      <div className="mb-2">
                        <Form.Check
                          type="radio"
                          name="channelRadioState"
                          label="Создать канал"
                          id={uuidv4()}
                          onChange={() => handleChangeChannelStatus(setFieldValue)}
                          checked={channelStatus === ChannelState.CREATE_CHANNEL}
                          inline
                        />
                        <Form.Check
                          type="radio"
                          name="channelRadioState"
                          label="Канал уже существует"
                          id={uuidv4()}
                          onChange={() => handleChangeChannelStatus(setFieldValue)}
                          checked={channelStatus === ChannelState.CHANNEL_EXIST}
                          inline
                        />
                      </div>
                      <Form.Control
                        onChange={handleChange}
                        name="channelName"
                        type="text"
                        placeholder="Введите название канала"
                        value={values.channelName}
                        disabled={channelStatus === ChannelState.CREATE_CHANNEL}
                      />
                    </div>
                    {errors.channelName && <div style={{ color: '#ff0000' }}>{errors.channelName}</div>}
                  </Form.Group>
                  {currentRole === RoleEnum.CURATOR && (
                    <Form.Group controlId="directionId">
                      <Form.Label>Направление подготовки</Form.Label>
                      <Form.Control
                        onChange={evt => {
                          handleChange(evt)
                          setCurrentDirectionId(evt.target.value)
                          setUpdateDeadlines(true)
                          setShowDeadlines(false)
                        }}
                        as="select"
                        name="directionId"
                        value={values.directionId}
                      >
                        <option value="">Не выбрано</option>
                        {directions.length &&
                          directions.map(direction => {
                            return (
                              <option value={`${direction.id}`} key={direction.id}>
                                {direction.name}
                              </option>
                            )
                          })}
                      </Form.Control>
                      {errors.directionId && <div style={{ color: '#ff0000' }}>{errors.directionId}</div>}
                    </Form.Group>
                  )}
                  <Form.Group controlId="mentorId">
                    <Form.Label>Выбор ментора</Form.Label>
                    <Form.Control onChange={handleChange} as="select" name="mentorId" value={values.mentorId}>
                      <option value="">Не выбран</option>
                      {mentors.length &&
                        mentors.map(mentor => {
                          return (
                            <option value={`${mentor.id}`} key={mentor.id}>
                              {`${mentor.firstName} ${mentor.lastName}`}
                            </option>
                          )
                        })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="commonRecruitmentChannelName">
                    <Form.Label>Общий Slack канал для групп набора</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      type="text"
                      placeholder="Общий Slack канал для групп набора"
                      value={values.commonRecruitmentChannelName}
                      name="commonRecruitmentChannelName"
                    />
                    {errors.commonRecruitmentChannelName && (
                      <div style={{ color: '#ff0000' }}>{errors.commonRecruitmentChannelName}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="startTraining">
                    <Form.Label>Дата начала обучения</Form.Label>
                    <Form.Control
                      onChange={evt => {
                        const inputValue = evt.target.value
                        if (inputValue) {
                          setFieldValue('startTraining', new Date(inputValue))
                        }
                      }}
                      type="date"
                      name="startTraining"
                      value={date.format(values.startTraining, 'YYYY-MM-DD')}
                    />
                  </Form.Group>
                  {currentCourses.length === 0 ? null : (
                    <>
                      <Button
                        onClick={() => {
                          setShowDeadlines(!showDeadlines)
                          if (updateDeadlines) {
                            const newCourses = currentCourses
                              .sort((a, b) => a.position - b.position)
                              .map(({ name, id, position }) => ({
                                courseId: id,
                                courseName: name,
                                coursePosition: position,
                                deadlineDate: '',
                              }))
                            setFieldValue('deadlinesInfo', newCourses)
                            setUpdateDeadlines(false)
                          }
                        }}
                        className={styles['deadlines-button']}
                      >
                        Дедлайны
                      </Button>
                      <div
                        className={classNames(
                          styles.deadlines,
                          showDeadlines ? styles['show-deadlines'] : styles['hide-deadlines']
                        )}
                      >
                        {values.deadlinesInfo.map(deadline => {
                          const dateString =
                            !deadline.deadlineDate || deadline.deadlineDate instanceof String
                              ? ''
                              : date.format(deadline.deadlineDate as Date, 'YYYY-MM-DD')

                          return (
                            <Form.Group controlId={deadline.courseName} key={deadline.courseId}>
                              <Form.Label>Дедлайн Курса: {deadline.courseName}</Form.Label>
                              <Form.Control
                                onChange={evt => {
                                  const inputValue = evt.target.value
                                  if (inputValue) {
                                    setFieldValue(
                                      'deadlinesInfo',
                                      values.deadlinesInfo.map(item => {
                                        return {
                                          ...item,
                                          deadlineDate:
                                            item.courseId === deadline.courseId
                                              ? new Date(inputValue)
                                              : item.deadlineDate,
                                        }
                                      })
                                    )
                                  }
                                }}
                                type="date"
                                name="deadline"
                                value={dateString}
                              />
                            </Form.Group>
                          )
                        })}
                      </div>
                      {errors.deadlinesInfo && <div style={{ color: '#ff0000' }}>{errors.deadlinesInfo}</div>}
                    </>
                  )}
                  {err && <div style={{ color: 'red' }}>{err}</div>}
                </Form>
              )
            }}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton submit action="assign" onClick={submitRecruitmentForm}>
            Отправить
          </ModalButton>
          <ModalButton action="back" onClick={onHide}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  )
}

export { CreateRecruitmentModal }
