import React, { useEffect, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { GenericTask } from '../../../../services/admin-services/tasks/generic-service/generic-task-service'
import { GenericTaskForm } from './generic-task-form'
import { GenericTaskFormConfirmPanel } from './generic-task-from-confirm-panel'
import { formatErrors, FormattedErrors } from '../helper'

export interface GenericTaskFormPageProps<T extends GenericTask> {
  taskId?: number
  callback: (task: GenericTask) => Promise<any>
  getTaskCallback: (taskId: number) => Promise<any>
  pageTitlePart: string
  linkName: string
  FormTaskComponent: any
  presendInterceptor: (val: Partial<T>) => any
  pregetInterceptor: (val: Partial<T>) => any
}

export function GenericTaskFormPage<T extends GenericTask>(props: GenericTaskFormPageProps<T>) {
  const {
    callback,
    linkName,
    pageTitlePart,
    getTaskCallback,
    taskId,
    FormTaskComponent,
    presendInterceptor,
    pregetInterceptor,
  } = props

  const { catchErrorAlert } = useReactAlert()

  const [genericTask, setGenericTask] = useState<GenericTask>(() => {
    return {
      // @ts-ignore
      id: !taskId ? props.match.params.taskId : props.taskId,
      title: '',
      description: '',
      points: 0,
      isAvailable: true,
    }
  })
  const [typedTask, setTypedTask] = useState<Partial<T>>(() => {
    return {}
  })
  const [validationErrorText, setValidationErrorText] = useState<string>('')
  const [validationErrorControls, setValidationErrorControls] = useState<FormattedErrors>({})

  useEffect(() => {
    if (genericTask.id > 0) {
      getTaskCallback(genericTask.id).then((value: T) => {
        const newValue = pregetInterceptor(value)
        setGenericTask({ ...newValue })
        setTypedTask({ ...newValue })
      })
    }
  }, [])

  const onCancel = () => {
    window.location.href = `/admin/tasks/${linkName}`
  }

  const onConfirm = () => {
    let data = {
      ...typedTask,
      title: genericTask.title,
      description: genericTask.description,
      points: genericTask.points,
      id: genericTask.id,
      isAvailable: undefined,
    }
    data = presendInterceptor(data)

    callback(data)
      .then(() => {
        window.location.href = `/admin/tasks/${linkName}`
      })
      .catch(err => {
        catchErrorAlert(err)
        const formattedErrors = formatErrors(err.errors)
        if (formattedErrors) {
          setValidationErrorControls(formattedErrors)
        }
        setValidationErrorText(
          err.code && err.code === 500 ? 'Ошибка сервера. Подробная информация - в консоли' : 'Ошибка валидации'
        )
      })
  }

  return (
    <>
      <GenericTaskForm
        task={genericTask}
        pageTitlePart={pageTitlePart}
        setTask={setGenericTask}
        validationErrorControls={validationErrorControls}
      />
      <FormTaskComponent task={typedTask} setTask={setTypedTask} />
      {validationErrorText && (
        <div className="alert alert-danger mt-5" role="alert">
          {validationErrorText}
        </div>
      )}
      <GenericTaskFormConfirmPanel onClickConfirmButton={onConfirm} onClickCancelButton={onCancel} />
    </>
  )
}
