import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { withPagination } from '../../../hoc/index'
import MentorCheckTaskService from '../../../../services/admin-services/tasks/mentor-check-tasks-service'
import MentorCheckTasksList from './mentor-check-tasks-list'
import MentorCheckTaskForm from './mentor-check-task-form/mentor-check-task-form'

export default class MentorCheckTasksPage extends React.Component {
  componentDidMount() {
    document.title = 'Администратор | Проверяемые ментором задачи'
  }

  render() {
    const service = new MentorCheckTaskService()
    const PaginationMentorCheckTaskList = withReactAlert(
      withPagination(MentorCheckTasksList, service, 'Проверяемые ментором задачи')
    )

    return (
      <div className="admin-content">
        <Switch>
          <Route path="/admin/tasks/check" exact render={props => <PaginationMentorCheckTaskList {...props} />} />
          <Route
            path="/admin/tasks/check/add"
            exact
            render={props => <MentorCheckTaskForm {...props} taskId={0} callback={service.add} />}
          />
          <Route
            path="/admin/tasks/check/edit/:taskId"
            exact
            render={props => <MentorCheckTaskForm {...props} callback={service.update} />}
          />
        </Switch>
      </div>
    )
  }
}
