/* eslint-disable no-shadow */
import React from 'react'
import './schedule-table.scss'

type tableBodyRow = (string | string[])[]

interface ScheduleTableProps {
  tableHeadRow?: string[]
  tableBodyRows: tableBodyRow[]
  tableFootRow?: string[]
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({ tableHeadRow, tableBodyRows, tableFootRow }) => (
  <table className="ScheduleTable">
    {tableHeadRow && (
      <thead className="ScheduleTable__header">
        <tr className="ScheduleTable__row ScheduleTable__row_centered">
          {tableHeadRow.map((tableHeadItem, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <th className="ScheduleTable__data-title" key={idx}>
              {tableHeadItem
                .split('-')
                .reverse()
                .join('.')}
            </th>
          ))}
        </tr>
      </thead>
    )}

    <tbody className="ScheduleTable__body">
      {tableBodyRows.map((tableBodyRow, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="ScheduleTable__row ScheduleTable__row_centered" key={idx}>
          {tableBodyRow.map((tableBodyItem, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <td className="ScheduleTable__data" key={idx}>
              {Array.isArray(tableBodyItem)
                ? tableBodyItem.map((elem, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="ScheduleTable__zoom-account" key={idx}>
                      {elem}
                    </div>
                  ))
                : tableBodyItem}
            </td>
          ))}
        </tr>
      ))}
    </tbody>

    {tableFootRow && (
      <tfoot className="ScheduleTable__footer">
        <tr className="ScheduleTable__row ScheduleTable__row_centered">
          {tableFootRow.map((tableFootItem, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <td className="ScheduleTable__data" key={idx}>
              {tableFootItem}
            </td>
          ))}
        </tr>
      </tfoot>
    )}
  </table>
)

export default ScheduleTable
