import React from 'react'
import { Accordion, Card } from 'react-bootstrap'

const StudentsCsvUploadManual = () => {
  return (
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          Инструкция
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="d-flex flex-column align-items-center">
              <p className="text-justify">
                При подготовке данных в Google Таблицах важно, чтобы в шапке таблицы были следующие колонки:
                &quot;email&quot;, &quot;firstName&quot;, &quot;lastName&quot;, &quot;directionId&quot;. Порядок колонок
                не имеет значения. В таблице между строками студентов не должно быть пустых ячеек, а так же между самими
                строками не должно быть пустых.
              </p>

              <img
                alt="Заполнение CSV"
                src="/images/students-csv-upload-manual/filling.png"
                height={327}
                className="mw-100"
              />

              <p className="text-justify mt-3">
                При заполнении таблицы в качестве directionId нужно использовать значения: 1 (для направления Java) и 11
                (для направления FE). Важно не переполнять таблицу данными. Необходимо использовать не более 25 строк.
                После завершения подготовки таблицы нужно скачать ее в формате CSV и загрузить на платформу.
              </p>

              <img
                alt="Импортирование CSV"
                src="/images/students-csv-upload-manual/importing.png"
                height={460}
                className="mw-100"
              />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default StudentsCsvUploadManual
