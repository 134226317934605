export class LocationUtil {
  /**
   * @description Gets a second level domain from current location
   * Doesn't work with top level domains that contain several parts
   *
   * @example
   * platform.kata.academy --> kata.academy
   * kata.academy --> kata.academy
   * kata.academy:3000 --> kata.academy
   * example.com.ru --> com.ru (not supported)
   */
  public static getSecondLevelDomain() {
    return window.location.hostname
      .split('.')
      .slice(-2) // take the first- and second-level domains
      .join('.')
  }
}
