import React from 'react'
import { Link } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import AdminDirectionService from '../../../../../services/admin-services/admin-direction-service'
import StudentsFreeItem from './students-free-item'
import './direction-students.css'
import { addItem, deleteItemByIndex, getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import DirectionSwapMentorModal from './students-mentor-item/direction-swap-mentor-modal'
import { StudentsMentorList } from './students-mentor-list/students-mentor-list'

class DirectionStudents extends React.Component {
  service = new AdminDirectionService()

  state = {
    studentsMentor: [],
    studentsAvailable: [],
    showModal: false,
    selectStudent: '',
    mentors: [],
    selectMentor: '',
  }

  // eslint-disable-next-line react/sort-comp
  handleClose = () => {
    this.setState({ showModal: false })
  }

  onSelectModal = student => {
    this.setState({ showModal: true })
    this.setState({ selectStudent: student })
  }

  // eslint-disable-next-line react/sort-comp
  handleChangeComboBox = e => {
    if (e.target.value !== undefined && e.target.value !== null) {
      const next = this.state
      next.selectMentor = e.target.value
      this.setState(next)
    }
  }

  loadMentors = () => {
    const {
      match: {
        params: { directionId = 0, chiefId = 0 },
      },
      catchErrorAlert,
    } = this.props

    this.service
      .loadChiefMentors(directionId, chiefId)
      .then(mentors => {
        this.setState({ mentors })
        this.setState({ selectMentor: mentors[0].id })
      })
      .catch(error => catchErrorAlert(error))
  }

  componentDidMount() {
    this.loadStudentsOnDirection()
    this.loadStudentsWitchOutDirection()
    this.loadMentors()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        studentsMentor: [],
        studentsAvailable: [],
      })
      this.loadStudentsOnDirection()
      this.loadStudentsWitchOutDirection()
    }
  }

  onSwapMentor = () => {
    const {
      match: {
        params: { directionId = 0, chiefId = 0, mentorId = 0 },
      },
      catchErrorAlert,
    } = this.props

    const { selectStudent, selectMentor, studentsMentor } = this.state
    if (selectStudent !== undefined) {
      this.service
        .swapMentor(directionId, selectStudent.id, selectMentor)
        .then(() => {
          if (mentorId !== selectMentor) {
            this.setState({
              studentsMentor: deleteItemByIndex(
                studentsMentor,
                getIndexByKeyValue(studentsMentor, 'id', selectStudent.id)
              ),
            })
          }
          this.handleClose()
        })
        .catch(error => catchErrorAlert(error))
    }
  }

  loadStudentsOnDirection = () => {
    const {
      match: {
        params: { directionId = 0, mentorId = 0 },
      },
      catchErrorAlert,
    } = this.props

    if (directionId > 0 && mentorId > 0) {
      this.service
        .getStudentsOnDirectionByMentor(directionId, mentorId)
        .then(studentsMentor => this.setState({ studentsMentor }))
        .catch(error => catchErrorAlert(error))
    }
  }

  loadStudentsWitchOutDirection = () => {
    const { catchErrorAlert } = this.props
    this.service
      .getAllStudentsWithOutDirection()
      .then(studentsAvailable => this.setState({ studentsAvailable }))
      .catch(error => catchErrorAlert(error))
  }

  onAddStudentToMentor = student => {
    const {
      match: {
        params: { directionId, mentorId, chiefId },
      },
    } = this.props

    this.service.addStudentToMentor(directionId, student.id, mentorId, chiefId).then(() =>
      this.setState(({ studentsMentor, studentsAvailable }) => {
        return {
          studentsMentor: addItem(studentsMentor, student),
          studentsAvailable: deleteItemByIndex(
            studentsAvailable,
            getIndexByKeyValue(studentsAvailable, 'id', student.id)
          ),
        }
      })
    )
  }

  onDeleteStudentFromMentor = student => () => {
    const {
      match: {
        params: { directionId, mentorId, chiefId },
      },
    } = this.props

    this.service.deleteStudentFormMentor(directionId, student.id, mentorId, chiefId).then(() =>
      this.setState(({ studentsMentor, studentsAvailable }) => {
        return {
          studentsMentor: deleteItemByIndex(studentsMentor, getIndexByKeyValue(studentsMentor, 'id', student.id)),
          studentsAvailable: addItem(studentsAvailable, student),
        }
      })
    )
  }

  render() {
    const { studentsMentor, studentsAvailable, showModal, selectStudent, mentors } = this.state
    const {
      match: {
        params: { directionId },
      },
    } = this.props

    return (
      <>
        <DirectionSwapMentorModal
          show={showModal}
          onClose={this.handleClose}
          student={selectStudent}
          mentors={mentors}
          handleChange={this.handleChangeComboBox}
          onSwapMentor={this.onSwapMentor}
        />
        <Link to={`/admin/directions/edit/${directionId}`} className="btn btn-info direction-back-button">
          Вернуться назад
        </Link>
        <div className="row">
          <div className="col-sm-6">
            <div className="panel-group students-mentor-block">
              <div className="students-mentor-block-content">
                <div className="header-students-mentor">
                  <p>Студенты ментора</p>
                </div>
                <StudentsMentorList
                  students={studentsMentor}
                  onDeleteStudentFromMentor={this.onDeleteStudentFromMentor}
                  onSelectModal={this.onSelectModal}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="panel-group students-free-block">
              <div className="students-free-block-content">
                <div className="header-free-students-mentor">
                  <p>Студенты без направления</p>
                </div>
                <ul className="list-group" style={{ fontSize: 16 }}>
                  {studentsAvailable.map(student => (
                    <StudentsFreeItem key={student.id} student={student} onAdd={this.onAddStudentToMentor} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withReactAlert(DirectionStudents)
