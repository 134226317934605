type Singular = string
type GenitiveSingular = string
type GenitivePlural = string

class WordUtil {
  // FIXME: скорее всего, названия форм числительных указаны для конкретно случая и не всегда являются правдой
  /**
   *
   * @description
   * Первую форму нужно получать склонением числительного с цифрой "1"
   * Вторую - с цифрой "2"
   * Третью - с цифрой "5"
   */
  public static declineNumeral(number: number, numeralForms: [Singular, GenitiveSingular, GenitivePlural]) {
    const [singular, genitiveSingular, genitivePlural] = numeralForms

    const reminderOfDivisionBy100 = Math.abs(number) % 100

    const reminderOfDivisionBy10 = number % 10

    if (reminderOfDivisionBy100 > 10 && reminderOfDivisionBy100 < 20) {
      return genitivePlural
    }

    if (reminderOfDivisionBy10 > 1 && reminderOfDivisionBy10 < 5) {
      return genitiveSingular
    }

    if (reminderOfDivisionBy10 === 1) {
      return singular
    }

    return genitivePlural
  }
}

export default WordUtil
