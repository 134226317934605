import React, { Component } from 'react'
import { withLastLocation } from 'react-router-last-location'
import { Button, ButtonGroup, Card, FormControl, FormLabel, InputGroup } from 'react-bootstrap'
import { Formik } from 'formik'
import CKEditor from 'ckeditor4-react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import OrderingTaskService from '../../../../../services/admin-services/tasks/ordering-tasks-service'
import { addItem, addItemByIndex, deleteItemByIndex } from '../../../../../utils/ArraysUtils'
import OrderingTaskItem from '../ordering-task-item/ordering-task-item'
import { formatErrors } from '../../helper'

class OrderingTaskForm extends Component {
  service = new OrderingTaskService()

  state = {
    task: {
      taskItems: [],
    },
    error: '',
    validationErrors: {},
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
        validationErrors: {},
      })
      this.onUpdate()
    }
  }

  onUpdate() {
    const {
      match: {
        params: { taskId = 0 },
      },
      catchErrorAlert,
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({ task })
        })
        .catch(error => catchErrorAlert(error))
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        ...oldState,
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  restorePosition = taskItems => {
    return taskItems.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      }
    })
  }

  onDeleteItem = index => () => {
    const { task } = this.state
    this.setState({
      task: {
        ...task,
        taskItems: this.restorePosition(deleteItemByIndex(task.taskItems, index)),
      },
    })
  }

  onAddItem = text => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          taskItems: this.restorePosition(
            addItem(oldState.task.taskItems, {
              itemText: text,
              position: oldState.task.taskItems.length + 1,
            })
          ),
        },
      }
    })
  }

  onUpItem = index => () => {
    if (index === 0) {
      return
    }
    const { task } = this.state
    const item = task.taskItems[index]
    this.setState({
      task: {
        ...task,
        taskItems: this.restorePosition(addItemByIndex(deleteItemByIndex(task.taskItems, index), index - 1, item)),
      },
    })
  }

  onDownItem = index => () => {
    const { task } = this.state
    if (index === task.taskItems.length - 1) {
      return
    }
    const item = task.taskItems[index]
    this.setState({
      task: {
        ...task,
        taskItems: this.restorePosition(addItemByIndex(deleteItemByIndex(task.taskItems, index), index + 1, item)),
      },
    })
  }

  render() {
    const { callback, lastLocation, history } = this.props
    const { task, error, validationErrors } = this.state
    const { id = 0, title = '', points = 0, description = '', taskItems } = task
    const taskItemsRender = taskItems.map((item, index) => {
      return (
        <OrderingTaskItem
          key={item.id}
          text={item.itemText}
          onUp={this.onUpItem(index)}
          onDown={this.onDownItem(index)}
          onDelete={this.onDeleteItem(index)}
        />
      )
    })
    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} задачи на порядок</h1>
        <div className="form-group">
          <label>Заголовок задачи</label>
          <FormControl onChange={this.editTitle} type="text" value={title} />
          {validationErrors?.title && <FormLabel className="text-danger">{validationErrors?.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label>Количество очков за решение задачи</label>
          <FormControl type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {!description !== undefined && (
              <CKEditor name="description" onChange={this.editDescription} data={description} />
            )}
          </div>
          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
        </div>
        <Card>
          <Card.Title>
            <b style={{ paddingLeft: 10 }}>Ответы на задание в нужном порядке</b>
          </Card.Title>
          <Card.Body className="ordering-task-editor">
            <Formik
              initialValues={{ text: '' }}
              onSubmit={(values, { resetForm }) => {
                this.onAddItem(values.text)
                resetForm({ text: '' })
              }}
            >
              {({ values, handleChange, handleSubmit, resetForm }) => (
                <form onSubmit={handleSubmit}>
                  <InputGroup>
                    <FormControl
                      type="text"
                      name="text"
                      placeholder="Новый элемент задачи"
                      onChange={handleChange}
                      value={values.text}
                    />
                    <Button type="submit" variant="outline-secondary" disabled={!values.text}>
                      <span className="mdi mdi-plus" />
                    </Button>
                    <Button variant="outline-secondary" onClick={() => resetForm({ text: '' })}>
                      <span className="mdi mdi-refresh" />
                    </Button>
                  </InputGroup>
                </form>
              )}
            </Formik>

            <ul className="list-group" style={{ marginTop: '15px' }}>
              {taskItemsRender}
            </ul>
          </Card.Body>
        </Card>
        {error !== '' && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="btn-group">
          <div id="msg_box" className="" />
          <button
            className="btn btn-lg btn-success"
            type="button"
            onClick={() => {
              callback(task)
                .then(() => {
                  if (lastLocation) {
                    document.location = lastLocation.pathname
                  } else {
                    document.location = '/admin/tasks/ordering'
                  }
                })
                .catch(err => {
                  const formattedErrors = formatErrors(err.errors)

                  this.setState({
                    error:
                      err.code && err.code === 500
                        ? 'Ошибка сервера. Подробная информация - в консоли'
                        : 'Ошибка валидации',
                    validationErrors: formattedErrors ?? {},
                  })
                })
            }}
          >
            Сохранить
          </button>
          <button
            className="btn btn-lg btn-danger"
            type="button"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              if (lastLocation) {
                history.push(lastLocation.pathname)
              } else {
                history.push('/admin/tasks/ordering')
              }
            }}
          >
            Отменить
          </button>
        </div>
      </>
    )
  }
}

export default withLastLocation(withReactAlert(OrderingTaskForm))
