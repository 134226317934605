import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PageHeader from 'src/components/layout/page-header'
import PageLayout from 'src/components/layout'
import PageSidebar from 'src/components/layout/page-sidebar'
import EditProfilePage from 'src/components/shared/EditProfile/EditProfilePage'
import EditPasswordPage from 'src/components/shared/EditPassword/EditPasswordPage'
import PageMainContent from 'src/components/layout/page-main-content'
import CodeTasksPage from './code-tasks-page'
import LectureTasksPage from './lectures-page'
import ReviewStepTaskPage from './review-step-task-page'
import TheoryTasksPage from './theory-tasks-page'
import WordTasksPage from './word-tasks-page'
import OrderingTasksPage from './ordering-tasks-page'
import PreModerCommentsPage from './premoderation-comments-page/index'
import InvitePage from './invite-page'
import UsersPage from './users-page'
import ChiefsPage from './chiefs-page'
import CoursesPage from './courses-page'
import DirectionsPage from './directions-page'
import MessagesPage from './messages-page'
import ExcerptionsPage from './excerptions-page'
import NotificationsPage from './notifications-page'
import AdminLeftSidebar from './admin-left-sidebar'
import HeaderPanel from '../../shared/header-panel'
import MentorCheckTasksPage from './mentor-check-tasks-page'
import ReviewQuestionsList from './review-questions-page/review-questions-list/review-questions-list'
import Reviews from './reviews'
import { ReviewPage } from './analytics-section/review-page/review-page'
import { CoursesPage as CoursesPageStatystic } from './analytics-section/courses-page/courses-page'
import AssociationTaskPage from './association-task/association-task-page'
import MultiTestTaskPage from './multi-test-task-page/multi-test-task-page'
import MultiAnswerTaskPage from './multi-answer-task-page/multi-answer-task-page'
import GappingTaskPage from './gapping-task-page/gapping-task-page'
import MultiInputTaskPage from './multi-input-task-page/multi-input-task-page'
import AdminReportsPage from './admin-reports-page'
import NotFound from '../../not-found'

import './admin-page.scss'
import StudentsProjectQueuePage from '../../shared/students-project-queue-page'
import RemovedStudentsProjectQueuePage from '../../shared/removed-students-project-queue-page'

const AdminPage = () => {
  return (
    <PageLayout>
      <PageHeader>
        <HeaderPanel role="ADMIN" />
      </PageHeader>
      <PageSidebar>
        <AdminLeftSidebar />
      </PageSidebar>
      <PageMainContent>
        <Switch>
          <Route path="/admin/users/new_student" component={InvitePage} />
          <Route path="/admin/users/all" component={UsersPage} />
          <Route path="/admin/users/chiefs" component={ChiefsPage} />
          <Route path="/admin/users/project-queue" component={StudentsProjectQueuePage} />
          <Route path="/admin/users/removed-project-queue" component={RemovedStudentsProjectQueuePage} />
          <Route path="/admin/statistic/review" component={ReviewPage} />
          <Route path="/admin/statistic/course" component={CoursesPageStatystic} />
          <Route path="/admin/statistic/reports" component={AdminReportsPage} />
          <Route path="/admin/tasks/code" component={CodeTasksPage} />
          <Route path="/admin/tasks/theory" component={TheoryTasksPage} />
          <Route path="/admin/tasks/ordering" component={OrderingTasksPage} />
          <Route path="/admin/tasks/word" component={WordTasksPage} />
          <Route path="/admin/tasks/check" component={MentorCheckTasksPage} />
          <Route path="/admin/tasks/review-step-task" component={ReviewStepTaskPage} />
          <Route path="/admin/tasks/association-task" component={AssociationTaskPage} />
          <Route path="/admin/tasks/gapping-task" component={GappingTaskPage} />
          <Route path="/admin/tasks/multianswer-task" component={MultiAnswerTaskPage} />
          <Route path="/admin/tasks/multitest-task" component={MultiTestTaskPage} />
          <Route path="/admin/tasks/multiinput-task" component={MultiInputTaskPage} />
          <Route path="/admin/tasks/lecture" component={LectureTasksPage} />
          <Route path="/admin/directions/all" component={DirectionsPage} />
          <Route path="/admin/directions/courses" component={CoursesPage} />
          <Route path="/admin/reviews/calendar" component={Reviews} />
          <Route path="/admin/reviews/questions" component={ReviewQuestionsList} />
          <Route path="/admin/others/messages" component={MessagesPage} />
          <Route path="/admin/others/excerptions" component={ExcerptionsPage} />
          <Route path="/admin/others/notifications" component={NotificationsPage} />
          <Route path="/admin/others/comments" component={PreModerCommentsPage} />
          <Route path="/admin/profile/edit" render={() => <EditProfilePage role="Админ" />} />
          <Route path="/admin/edit/password" component={() => <EditPasswordPage role="Админ" />} />

          <Redirect from="/admin/users" to="/admin/users/new_student" />
          <Redirect from="/admin/statistic" to="/admin/statistic/review" />
          <Redirect from="/admin/tasks" to="/admin/tasks/theory" />
          <Redirect from="/admin/directions" to="/admin/directions/all" />
          <Redirect from="/admin/reviews" to="/admin/reviews/calendar" />
          <Redirect from="/admin/others" to="/admin/others/excerptions" />
          <Route path="" component={NotFound} />
        </Switch>
      </PageMainContent>
    </PageLayout>
  )
}

export default AdminPage
