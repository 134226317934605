import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import ReviewOptionsPanel from './components/review-options-panel'
import MentorReviewService from '../../../../../../../services/mentor-services/mentor-review-service'
import Spinner from '../../../../../../spinner'
import AvailableStudentsList from './components/available-students-list'
import SelectedStudentsList from './components/selected-studetns-list'
import './add-review-page.css'

class AddReviewPage extends Component {
  static searchStudents(students, term) {
    if (term.length === 0) {
      return students
    }
    return students.filter(student => {
      const lowerStudentName = student.name.toLowerCase()
      const lowerTerm = term.toLowerCase()
      return lowerStudentName.indexOf(lowerTerm) > -1
    })
  }

  reviewService = new MentorReviewService()

  state = {
    mentors: [],
    mentorsLoaded: true,
    courses: [],
    modules: [],
    selectedMentorId: {},
    selectedCourseId: {},
    selectedModuleId: {},
    studentsInfos: [],
    term: ``,
    selectedStudentsInfos: [],
    selectedDate: new Date(),
    redirect: false,
    isEdit: false,
    review: {},
  }

  componentDidMount() {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const reviewId = params.get('reviewId')
    if (reviewId) {
      this.setState(
        {
          isEdit: true,
        },
        () => {
          this.getReviewById(reviewId)
        }
      )
    } else {
      this.updateMentors()
    }
  }

  onMentorsLoaded = mentors => {
    this.setState(
      {
        mentors,
        mentorsLoaded: false,
      },
      () => {
        this.defaultMentor()
      }
    )
  }

  onCoursesLoaded = courses => {
    this.setState(
      {
        courses,
      },
      () => {
        this.defaultCourse()
      }
    )
  }

  onModulesLoaded = modules => {
    const sortedModules = this.sortModulesByPosition(modules)
    this.setState(
      {
        modules: sortedModules,
      },
      () => {
        this.defaultModule()
      }
    )
  }

  sortModulesByPosition = modules => {
    modules.sort((element1, element2) => (element1.position > element2.position ? 1 : -1))
    return modules
  }

  onStudentsLoaded = students => {
    this.createStudentsInfos(students)
  }

  onSelectedMentorChange = () => {
    const mentorId = document.getElementById(`review-mentor-select`).value
    this.setState({
      selectedMentorId: mentorId,
    })
  }

  onSelectedCourseChange = () => {
    const courseId = document.getElementById(`review-course-select`).value
    this.setState(
      {
        selectedCourseId: courseId,
      },
      () => {
        this.updateModules()
      }
    )
  }

  onSelectedModuleChange = () => {
    const moduleId = document.getElementById(`review-module-select`).value
    this.setState(
      {
        selectedModuleId: moduleId,
      },
      () => {
        this.updateStudents()
      }
    )
  }

  onSearchChange = term => {
    this.setState({
      term,
    })
  }

  onDateChange = date => {
    this.setState({
      selectedDate: date,
    })
  }

  selectStudent = id => {
    const { reactAlert } = this.props
    const { selectedStudentsInfos } = this.state
    if (selectedStudentsInfos.length >= 4) {
      reactAlert.error(`Количество участников превышено!`)
      return
    }
    const { studentsInfos } = this.state
    const { id: studentId, name: studentName, email } = studentsInfos.find(info => info.id === id)
    const selectedStudent = { studentId, studentName, email }
    if (selectedStudentsInfos.indexOf(selectedStudent) !== -1) {
      reactAlert.error(`Этот студент уже добавлен в ревью!`)
      return
    }
    const newArr = [...selectedStudentsInfos, selectedStudent]

    this.setState({
      selectedStudentsInfos: newArr,
    })
  }

  selectStudentsByReview = () => {
    const newArray = []

    const {
      review: { studentReviewsDtos },
    } = this.state
    if (studentReviewsDtos !== 0) {
      studentReviewsDtos.forEach(studentReview => {
        const { studentId, studentName, email } = studentReview
        const selectedStudent = {
          studentId,
          studentName,
          email,
        }
        newArray.push(selectedStudent)
      })
    }

    this.setState(
      {
        selectedStudentsInfos: newArray,
      },
      () => {
        this.setState({
          mentorsLoaded: false,
        })
      }
    )
  }

  unselectStudent = id => {
    this.setState(({ selectedStudentsInfos }) => {
      const index = selectedStudentsInfos.findIndex(info => info.studentId === id)
      const newArray = [...selectedStudentsInfos.slice(0, index), ...selectedStudentsInfos.slice(index + 1)]

      return {
        selectedStudentsInfos: newArray,
      }
    })
  }

  defaultMentor = () => {
    const { mentors } = this.state
    const mentor = mentors.find(searchedMentor => searchedMentor.value === true)
    const mentorInfo = mentor.key
    const { id } = mentorInfo
    this.setState(
      {
        selectedMentorId: id,
      },
      () => {
        this.updateCourses()
      }
    )
  }

  defaultCourse = () => {
    const { courses } = this.state
    if (!courses || courses.length === 0) {
      return
    }
    const firstCourse = courses[0]
    const { id } = firstCourse
    this.setState(
      {
        selectedCourseId: id,
      },
      () => {
        this.updateModules()
      }
    )
  }

  defaultModule = () => {
    const { modules } = this.state
    const firstModule = modules[0]
    const { id } = firstModule
    this.setState(
      {
        selectedModuleId: id,
      },
      () => {
        this.updateStudents()
      }
    )
  }

  createReview = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedMentorId, selectedModuleId, selectedStudentsInfos } = this.state

    const selectedDate = document.getElementById(`review-date`).value
    const studentsId = []
    selectedStudentsInfos.forEach(info => {
      const { id } = info
      studentsId.push(id)
    })

    if (studentsId.length > 4) {
      reactAlert.error(`Количество участников превышено.`)
      return
    }

    this.reviewService
      .addReview(selectedDate, selectedMentorId, selectedModuleId, studentsId)
      .then(() => {
        this.setState({
          redirect: true,
        })
      })
      .catch(err => catchErrorAlert(catchErrorAlert))
  }

  updateReviewById = () => {
    const { catchErrorAlert } = this.props
    const { review, selectedStudentsInfos } = this.state

    const studentsId = []
    selectedStudentsInfos.forEach(info => {
      const { studentId } = info
      studentsId.push(studentId)
    })

    this.reviewService
      .updateReviewStudentsByReviewId(studentsId, review.reviewId)
      .then(() => {
        this.setState({
          redirect: true,
        })
      })
      .catch(err => catchErrorAlert(err))
  }

  getReviewById = id => {
    this.reviewService.getReviewById(id).then(response => {
      this.setState({
        review: response,
      })

      const { mentorId, courseId = 0, moduleId = 0 } = response
      this.setState(
        {
          selectedMentorId: mentorId,
          selectedCourseId: courseId,
          selectedModuleId: moduleId,
        },
        () => {
          this.updateStudents()
        }
      )
    })
  }

  updateMentors = () => {
    this.reviewService.getAllMentors().then(this.onMentorsLoaded)
  }

  updateCourses = () => {
    const { selectedMentorId } = this.state
    this.reviewService.getAllCourse(selectedMentorId).then(this.onCoursesLoaded)
  }

  updateModules = () => {
    const { selectedCourseId } = this.state
    this.reviewService.getModulesByCourseId(selectedCourseId).then(this.onModulesLoaded)
  }

  createStudentsInfos = students => {
    const { isEdit } = this.state
    const studentsInfos = []
    students.forEach(student => {
      if (!student) {
        return
      }
      const { id, firstName, lastName, email } = student
      studentsInfos.push({
        id,
        name: `${firstName} ${lastName}`,
        email,
      })
    })

    this.setState(
      {
        studentsInfos,
      },
      () => {
        if (isEdit) {
          this.selectStudentsByReview()
        }
      }
    )
  }

  updateStudents = () => {
    const { selectedModuleId } = this.state
    this.reviewService.getAvailableToReviewStudents(selectedModuleId).then(this.onStudentsLoaded)
  }

  render() {
    const {
      mentors,
      mentorsLoaded,
      courses,
      modules,
      studentsInfos,
      term,
      selectedStudentsInfos,
      selectedDate,
      redirect,
      isEdit,
      review,
    } = this.state

    if (redirect) {
      return <Redirect to="/mentor/review/table" />
    }

    const visibleStudents = AddReviewPage.searchStudents(studentsInfos, term)

    if (mentorsLoaded) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="mentor-content">
        <div className="mentor-header">
          <h2 id="add-review-header">{isEdit ? `Редактирование ревью. ` : `Добавление ревью`}</h2>
        </div>

        <div className="mentor-section">
          {isEdit && `Модуль: ${review.moduleName}. Дата: ${review.reviewTime}.`}
          <div className="add-review-page-content">
            <div className="review-options">
              <ReviewOptionsPanel
                mentors={mentors}
                courses={courses}
                modules={modules}
                selectedDate={selectedDate}
                onSelectedMentorChange={this.onSelectedMentorChange}
                onSelectedCourseChange={this.onSelectedCourseChange}
                onSelectedModuleChange={this.onSelectedModuleChange}
                onDateChange={this.onDateChange}
                isEdit={isEdit}
              />
            </div>
            <div className="add-review-tables">
              <AvailableStudentsList
                students={visibleStudents}
                onSearchChange={this.onSearchChange}
                selectStudent={this.selectStudent}
              />

              <SelectedStudentsList
                selectedStudentsInfos={selectedStudentsInfos}
                unselectStudent={this.unselectStudent}
                createReview={this.createReview}
                updateReview={this.updateReviewById}
                isEdit={isEdit}
              />
            </div>
          </div>
        </div>

        <div className="mentor-footer" />
      </div>
    )
  }
}

export default withReactAlert(AddReviewPage)
