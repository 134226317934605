export enum SortTypes {
  STUDENT_NAME_ASC = 'STUDENT_NAME_ASC',
  STUDENT_NAME_DESC = 'STUDENT_NAME_DESC',
  REGISTRATION_DATE_ASC = 'REGISTRATION_DATE_ASC',
  REGISTRATION_DATE_DESC = 'REGISTRATION_DATE_DESC',
  LAST_ANSWER_DATE_TIME_ASC = ' LAST_ANSWER_DATE_TIME_ASC',
  LAST_ANSWER_DATE_TIME_DESC = ' LAST_ANSWER_DATE_TIME_DESC',
  DIRECTION_NAME_ASC = 'DIRECTION_NAME_ASC',
  DIRECTION_NAME_DESC = 'DIRECTION_NAME_DESC',
  LAST_NAME_ASC = 'LAST_NAME_ASC',
  LAST_NAME_DESC = 'LAST_NAME_DESC',
  PERCENT_PASSED_ASC = 'PERCENT_PASSED_ASC',
  PERCENT_PASSED_DESC = 'PERCENT_PASSED_DESC',
  END_PREPARATION_DATE_ASC = 'END_PREPARATION_DATE_ASC',
  END_PREPARATION_DATE_DESC = 'END_PREPARATION_DATE_DESC',
  CURRENT_COURSE_ASC = 'CURRENT_COURSE_ASC',
  CURRENT_COURSE_DESC = 'CURRENT_COURSE_DESC',
  CURRENT_COURSE_PROGRESS_ASC = 'CURRENT_COURSE_PROGRESS_ASC',
  CURRENT_COURSE_PROGRESS_DESC = 'CURRENT_COURSE_PROGRESS_DESC',
  LAST_ANSWER_DATE_ASC = 'LAST_ANSWER_DATE_ASC',
  LAST_ANSWER_DATE_DESC = 'LAST_ANSWER_DATE_DESC',
  STUDENT_ACADEM_PERFORMANCE_ASC = 'STUDENT_ACADEM_PERFORMANCE_ASC',
  STUDENT_ACADEM_PERFORMANCE_DESC = 'STUDENT_ACADEM_PERFORMANCE_DESC',
}
