import React, { useState, useEffect } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import FilterPaginationService, { PaginationParamPair, ParameterValue } from 'src/services/filter-pagination-service'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import RecruitmentComponentWrapper from './recruitment-wrapper'
import { deleteItemByIndex, getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import { CuratorMentorService } from '../../../../../services/curator-services/curator-mentor-service'
import ChiefMentorsService from '../../../../../services/chief-services/chief-mentor-service'

import './recruitment-page.scss'
import { AuthService } from '../../../../../services/auth-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'

import { MentorsServiceInterface } from '../../../../../services/interfaces/mentors-service'

const RecruitmentComponent: React.FC = () => {
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [currentDirectionId, setCurrentDirectionId] = useState<number | string | null>(null)

  const { catchErrorAlert } = useReactAlert()

  const directionService = CuratorDirectionsService
  let mentorsService: MentorsServiceInterface

  const currentRole = AuthService.currentUserValue()?.role.name
  let sourceUrlRole
  if (currentRole && currentRole === RoleEnum.CURATOR) {
    sourceUrlRole = '/api/curator/recruitment'
    mentorsService = CuratorMentorService
  } else {
    sourceUrlRole = '/api/chief/recruitment'
    mentorsService = ChiefMentorsService
  }

  useEffect(() => {
    if (currentRole === RoleEnum.CHIEF_MENTOR) {
      mentorsService.getMentors().then(mentors => {
        const mentorsAsParams = mentors.map(mentor => {
          const { id, lastName, firstName } = mentor
          const name = `${lastName} ${firstName}`
          return { id, name } as ParameterValue
        })

        setPaginationParamPairs([
          {
            parameterName: 'mentorId',
            parameterDisplayedName: 'Ментор',
            parameterValues: [{ name: 'Все', id: null }, ...mentorsAsParams],
          },
        ])
      })
      return
    }
    directionService
      .getAll()
      .then(directions => {
        setPaginationParamPairs([
          {
            parameterName: 'directionId',
            parameterDisplayedName: 'Направление',
            parameterValues: [{ name: 'Все', id: null }, ...directions],
          },
          {
            parameterName: 'mentorId',
            parameterDisplayedName: 'Ментор',
            parameterValues: [{ name: 'Все', id: null }],
          },
        ])
      })
      .catch(error => {
        catchErrorAlert(error)
      })
      .then(() => {
        if (currentDirectionId) {
          mentorsService.getMentors(currentDirectionId).then(mentors => {
            const mentorsAsParams = mentors.map(mentor => {
              const { id, lastName, firstName } = mentor
              const name = `${lastName} ${firstName}`
              return { id, name }
            })

            setPaginationParamPairs(params => {
              const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'mentorId'))
              return [
                ...newParams,
                {
                  parameterName: 'mentorId',
                  parameterDisplayedName: 'Ментор',
                  parameterValues: [{ name: 'Все', id: null }, ...mentorsAsParams],
                },
              ]
            })
          })
        }
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [currentDirectionId])

  const handleDirectionSelect = (paramValue: number | string | null) => {
    setCurrentDirectionId(paramValue)
  }

  const recruitmentService = new FilterPaginationService<RecruitmentDto>(sourceUrlRole)
  const CustomPaginationComponent = PaginationComponent<RecruitmentDto>()
  return (
    <div className="mentor-content">
      <CustomPaginationComponent
        Wrapper={RecruitmentComponentWrapper}
        paginationParamPairs={paginationParamPairs}
        service={recruitmentService}
        headerText="Доступные потоки"
        showDateAnalytics
        paginationParamChangeHandlers={{
          directionId: handleDirectionSelect,
        }}
      />
    </div>
  )
}

export default RecruitmentComponent
