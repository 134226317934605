import React, { useMemo } from 'react'
import './mentor-planning-reviews-action-panel.css'
import { Button, FormControl, Form } from 'react-bootstrap'

const MentorPlanningReviewsActionPanel = React.memo(function MentorPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  selectedReviewType,
  setSelectedReviewType,
  onChangeReviewsByDate,
  selectedCourse,
  courses,
  onChangeCourse,
  modules,
  selectedModule,
  onChangeModule,
  self,
  onChangeSelf,
}) {
  const onDateChange = event => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return (
      <option key={`course-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return (
        <option key={`module-id-${id}${selectedCourse?.id}`} value={id}>
          {name}
        </option>
      )
    })
  }, [selectedCourse?.id, modules])

  const defaultOption = <option key="defaultOption">-</option>

  return (
    <Form className="chief-review-action-panel">
      <label>
        Ревью за за:
        <FormControl
          type="date"
          value={selectedDate.toISOString().substring(0, 10)}
          className="review-filter-selector"
          onChange={onDateChange}
        />
      </label>

      <label>
        Тип ревью:
        <FormControl
          as="select"
          onChange={({ target: { value } }) => setSelectedReviewType(value)}
          value={selectedReviewType}
          className="review-filter-selector"
        >
          <option value="GROUP">Групповое</option>
          <option value="PERSONAL_MENTOR">Персональное</option>
        </FormControl>
      </label>

      <label>
        Курс:
        <FormControl
          as="select"
          value={selectedCourse?.id}
          onChange={e => onChangeCourse && onChangeCourse(e)}
          className="review-filter-selector"
        >
          {coursesOptions ? [defaultOption, ...coursesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Модуль:
        <FormControl
          as="select"
          disabled={!selectedCourse || !modules?.length}
          value={selectedModule?.id}
          onChange={e => onChangeModule && onChangeModule(e)}
          className="review-filter-selector"
        >
          {modulesOptions ? [defaultOption, ...modulesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Мои ревью:
        <FormControl
          type="checkbox"
          value={self}
          onChange={e => onChangeSelf(!self)}
          className="review-filter-checkbox-wrapper"
        />
      </label>

      <Button
        variant="primary"
        onClick={onChangeReviewsByDate}
        className="ml-3 btn btn-primary chief-review-action-panel-btn"
      >
        Запросить план
      </Button>
    </Form>
  )
})

export default MentorPlanningReviewsActionPanel
