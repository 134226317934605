import { DirectionDto } from '../../model/direction-model'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { DirectionServiceInterface } from '../interfaces/direction-service'

const Url = {
  ROOT: `${serverHost}/api/curator/direction`,
}

const requestCuratorDirectionsController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorDirectionsService: DirectionServiceInterface = class CuratorDirectionsService {
  static getAll = () => {
    return requestCuratorDirectionsController<DirectionDto[]>('')
  }
}
