import React, { useRef } from 'react'
import { Col, Form as RBForm, FormControl, Row } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import moment from 'moment'
import { format } from 'date-fns'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'

const NotificationEditModal = ({ notification, onClose, onSave, show }) => {
  const notificationFormRef = useRef(null)

  const submitNotificationForm = () => {
    if (notificationFormRef.current) {
      notificationFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const validate = ({ title, message }) => {
    const errors = {}

    if (!title || title === '') {
      errors.title = 'Не заполенено обязательное поле'
    }

    if (!message || message === 'not selected') {
      errors.message = 'Не заполенено обязательное поле'
    }

    return errors
  }
  ReactModal.setAppElement('#root')

  const { id, title, message, important, endTime } = notification
  const notificationDate = id === undefined ? new Date() : new Date(endTime)

  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>{id === undefined ? 'Создание' : 'Редактирование'} уведомления</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              title: title || '',
              message: message || '',
              important: important || false,
              endTime: notificationDate,
            }}
            onSubmit={values => {
              const { endTime: inputTime } = values
              const formattedEndTime = format(inputTime, 'yyyy-MM-dd HH:mm:ss')
              onSave({ ...values, id, endTime: formattedEndTime })
            }}
          >
            {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
              <form onSubmit={handleSubmit} ref={notificationFormRef}>
                <div className="form-group">
                  <label>Заголовок уведомления</label>
                  <FormControl type="text" name="title" onChange={handleChange} value={values.title} />
                  {errors.title && <div style={{ color: '#ff0000' }}>{errors.title}</div>}
                </div>
                <div className="form-group">
                  <label>Текст уведомления</label>
                  <FormControl
                    as="textarea"
                    rows="10"
                    name="message"
                    onChange={handleChange}
                    className="message-text"
                    value={values.message}
                  />
                  {errors.message && <div style={{ color: '#ff0000' }}>{errors.message}</div>}
                </div>
                <RBForm.Group as={Row} className="align-items-center" controlId="important">
                  <RBForm.Label column="md" sm="auto">
                    Уведомление важное?
                  </RBForm.Label>
                  <Col sm="auto">
                    <RBForm.Check
                      value={values.important}
                      checked={values.important}
                      type="checkbox"
                      name="important"
                      onChange={handleChange}
                    />
                  </Col>
                </RBForm.Group>
                <div className="form-group">
                  <label>Время важности уведомления</label>
                  <div>
                    <FormControl
                      type="date"
                      value={moment(values.endTime).format('yyyy-MM-DD')}
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const oldValue = moment(values.endTime)
                        const newValue = moment(event.target.value)
                        oldValue.year(newValue.year())
                        oldValue.month(newValue.month())
                        oldValue.date(newValue.date())
                        setFieldValue('endTime', moment(oldValue).toDate())
                      }}
                    />
                    <FormControl
                      type="time"
                      value={moment(values.endTime).format('HH:mm')}
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const oldValue = moment(values.endTime)
                        const newValue = event.target.value
                        oldValue.hour(parseInt(newValue.substring(0, 2), 10))
                        oldValue.minute(parseInt(newValue.substring(3, 5), 10))
                        setFieldValue('endTime', moment(oldValue).toDate())
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton type="submit" action="assign" onClick={() => submitNotificationForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default NotificationEditModal
