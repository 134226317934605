import React from 'react'
import classnames from 'classnames/bind'
import { Button } from 'react-bootstrap'

type BtnActionType = 'assign' | 'refuse' | 'cancel' | 'back' | 'delete' | 'create'
type TButtonProps = {
  submit?: boolean
  action: BtnActionType
  onClick?: () => void
}
const ModalButton: React.FC<TButtonProps> = ({ submit, action, onClick, children }) => {
  let variant
  switch (action) {
    case 'refuse':
      variant = 'danger'
      break
    case 'assign':
      variant = 'success'
      break
    case 'cancel':
      variant = 'warning'
      break
    case 'back':
      variant = 'warning'
      break
    case 'delete':
      variant = 'danger'
      break
    case 'create':
      variant = 'success'
      break
    default:
      variant = 'primary'
  }
  return (
    <Button variant={variant} className="m-1" onClick={onClick}>
      {children}
    </Button>
  )
}

export default ModalButton
