import React from 'react'
import { format } from 'date-fns'
import HeaderPanel from 'src/components/shared/header-panel'
import ScheduleTable from 'src/components/shared/schedule-table'
import { useGetZoomSchedule } from './useGetZoomSchedule'
import { useTransformScheduleToTable } from './useTransformScheduleToTable'
import './zoom-schedule-page.scss'

export const ZoomSchedulePage: React.FC = () => {
  const { filterDate, setFilterDate, zoomSchedule, updateZoomSchedule } = useGetZoomSchedule()
  const { tableHeadData, tableBodyData, tableFootData } = useTransformScheduleToTable(zoomSchedule)

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value
    if (inputValue) {
      setFilterDate(format(new Date(inputValue), 'yyyy-MM-dd'))
    }
  }

  return (
    <>
      <HeaderPanel role="any" />
      <main className="PageContent">
        <h1 className="PageMainTitle">График занятости аккаунтов Zoom</h1>
        <div className="ScheduleSettings">
          <label htmlFor="week-start" className="ScheduleSettings__label">
            График на неделю с
          </label>
          <input
            type="date"
            id="week-start"
            className="ScheduleSettings__date"
            value={filterDate}
            onChange={handleDateChange}
          />
          <button type="button" className="ScheduleSettings__submit" onClick={updateZoomSchedule}>
            Запросить график
          </button>
        </div>
        <ScheduleTable tableHeadRow={tableHeadData} tableBodyRows={tableBodyData} tableFootRow={tableFootData} />
      </main>
    </>
  )
}
