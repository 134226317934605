import qs from 'query-string'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { CuratorGroupServiceInterface } from '../interfaces/curator-services/curator-group-service'

class CuratorGroupService implements CuratorGroupServiceInterface {
  private readonly sourceURL = `${serverHost}/api/curator/student/postpaid/group`

  public groupSingleStudent = (studentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}?${qs.stringify({
      recruitmentId: studentId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }

  public groupStudents = (studentsIds: number[], recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(studentsIds),
    }
    const url = `${this.sourceURL}?${qs.stringify({
      recruitmentId,
    })}`
    return ApiService.request<void>(url, queryOption)
  }
}

export default CuratorGroupService
