import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchGeneralInfo } from 'src/store/review-analytics/reviewAnalyticsSlice'
import { AppDispatch } from 'src/store/index'
import { AnalyticsServiceSharedParams } from 'src/services/admin-services/analytics/admin-analytics'

export const useGetGeneralStatictics = (params: AnalyticsServiceSharedParams): void => {
  const dispatch = useDispatch<AppDispatch>()
  const { startDate, endDate, directionId, courseId, moduleId } = params
  useEffect(() => {
    if (startDate && endDate) {
      dispatch(fetchGeneralInfo(params))
    }
  }, [startDate, endDate, directionId, courseId, moduleId])
}
