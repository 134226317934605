import qs from 'query-string'
import { serverHost } from '../config'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { PageDto } from '../model/page-dto'
import { PaginationService } from './interfaces/pagination-service'

export default class FilterPaginationService<Entity> implements PaginationService<Entity> {
  public readonly sourceURL

  constructor(sourceURL: string) {
    this.sourceURL = serverHost + sourceURL
  }

  public getPage(
    numberPage: number,
    filterPattern: string,
    sortType: string,
    items: number,
    parameters: ParameterValue[],
    startDate: string,
    endDate: string
  ) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    } as RequestInit

    const parametersObj: ParametersObj = parameters.reduce((newParametersObj, { id, name }) => {
      newParametersObj[name] = id
      return newParametersObj
    }, <ParametersObj>{})

    return ApiService.request<PageDto<Entity>>(
      `${this.sourceURL}/page/${numberPage}?${qs.stringify({
        filterPattern,
        sortType,
        items,
        startDate,
        endDate,
        ...parametersObj,
      })}`,
      queryOption
    )
  }
}

export type PaginationParamPair = {
  parameterName: string
  parameterDisplayedName: string
  parameterValues: ParameterValue[]
}

export type ParameterValue = {
  id: number | null | string
  name: string
}

type ParametersObj = {
  [name: string]: number | null | string
}
