import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const ProtectedRoute = ({ isAllowed, redirectTo = '/', ...routeProps }: Props) => {
  return isAllowed() ? <Route {...routeProps} /> : <Redirect to={redirectTo} />
}

export default ProtectedRoute

type Props = RouteProps & {
  isAllowed: () => boolean
  redirectTo?: string
}
