import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { positions, Provider as AlertProvider, transitions, useAlert } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { confirmAlert } from 'react-confirm-alert'
import './courses-in-direction-row.css'

const CoursesInDirectionRow = ({
  course,
  isLast,
  isFirst,
  onDelete,
  directionId,
  onInputChange,
  onUpdatePercent,
  onMoveUp,
  onMoveDown,
  onMandatoryCourseChange,
}) => {
  const { position, name, percent, fullPassRequired } = course
  const [isMandatoryCourse, setMandatoryCourse] = useState(fullPassRequired)

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '50px',
    transition: transitions.SCALE,
  }

  const confirmDeleteCourseInDirection = (id, pos) => {
    confirmAlert({
      title: `Удаление курса из направления`,
      message: 'Вы уверены что хотите удалить курс?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id, pos),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  const alert = useAlert()

  const moveButtonUp = () => {
    return (
      <>
        <button
          type="button"
          className="course-btn-move-up btn btn-primary btn-xs"
          onClick={() => {
            onMoveUp(directionId, position)
            alert.show(`Позиция курса ${name} обновилась`)
          }}
        >
          <span className="mdi mdi-arrow-up-bold-circle" aria-hidden="true" />
        </button>
      </>
    )
  }

  const moveButtonDown = () => {
    return (
      <>
        <button
          type="button"
          className="course-btn-move-up btn btn-primary btn-xs"
          onClick={() => {
            onMoveDown(directionId, position)
            alert.show(`Позиция курса ${name} обновилась`)
          }}
        >
          <span className="mdi mdi-arrow-down-bold-circle" aria-hidden="true" />
        </button>
      </>
    )
  }

  const renderButton = () => {
    if (isFirst && isLast) {
      return <td className="col-md-1" />
    }

    if (isFirst) {
      return (
        <>
          <td className="col-md-1" />
          <td className="col-md-1">{moveButtonDown()}</td>
        </>
      )
    }

    if (isLast) {
      return (
        <>
          <td className="col-md-1">{moveButtonUp()}</td>
          <td className="col-md-1" />
        </>
      )
    }

    if (!isFirst && !isLast) {
      return (
        <>
          <td className="col-md-1">{moveButtonUp()}</td>
          <td className="col-md-1">{moveButtonDown()}</td>
        </>
      )
    }
    return <td className="col-md-1" />
  }

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <tr>
        <td className="col-md-2">{position}</td>
        <td className="col-md-2">{name}</td>
        <td className="col-md-2">
          <FormControl
            className="mandatory-course-control"
            type="checkbox"
            checked={isMandatoryCourse}
            onChange={() => {
              setMandatoryCourse(!isMandatoryCourse)
              onMandatoryCourseChange(course.id, !isMandatoryCourse)
            }}
          />
        </td>
        <td className="col-md-1">
          <FormControl defaultValue={percent} type="number" placeholder="%" onChange={onInputChange} />
        </td>
        <td className="col-md-1">
          <button
            type="button"
            className="course-btn-save-percent btn btn-success btn-xs"
            onClick={() => {
              onUpdatePercent(directionId, position)
              alert.show('Проценты успешно обновились')
            }}
          >
            <span className="mdi mdi-check-circle" aria-hidden="true" />
          </button>
        </td>
        {renderButton()}
        <td className="col-md-1">
          <button
            type="button"
            className="course-btn-remove btn btn-danger btn-xs"
            onClick={() => confirmDeleteCourseInDirection(directionId, position)}
          >
            <span className="mdi mdi-close-octagon-outline" aria-hidden="true" />
          </button>
        </td>
      </tr>
    </AlertProvider>
  )
}

export default CoursesInDirectionRow
