import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { Route, Switch } from 'react-router-dom'
import { withPagination } from '../../../hoc/index'
import TheoryTaskService from '../../../../services/admin-services/tasks/theory-tasks-service'
import TheoryTaskList from './theory-tasks-list'
import TheoryTaskForm from './theory-task-form'

const TheoryTasksPage = () => {
  useSetDocumentTitle('Администратор | Теоретические задачи')
  const service = new TheoryTaskService()
  const PaginationTheoryTasksList = withReactAlert(
    withPagination(TheoryTaskList, service, 'Теоретические задачи', `admin`)
  )
  return (
    <>
      <Switch>
        <Route path="/admin/tasks/theory/" exact render={props => <PaginationTheoryTasksList {...props} />} />
        <Route
          path="/admin/tasks/theory/add"
          exact
          render={props => <TheoryTaskForm {...props} taskId={0} callback={service.add} />}
        />
        <Route
          path="/admin/tasks/theory/edit/:taskId"
          exact
          render={props => <TheoryTaskForm {...props} callback={service.update} />}
        />
      </Switch>
    </>
  )
}

export default TheoryTasksPage
