import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import UsersList from './users-list'
import { withPagination } from '../../../../../hoc'
import MentorUserService from '../../../../../../services/mentor-services/mentor-user-service'

class UsersPage extends Component {
  render() {
    const service = new MentorUserService()
    const PaginationUsersList = withReactAlert(withPagination(UsersList, service, 'Все студенты', true))
    return (
      <div className="content">
        <div className="mentor-content">
          <Switch>
            <Route path="/mentor/users" exact render={props => <PaginationUsersList {...props} />} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default UsersPage
