import React, { useEffect, useState } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import FilterPaginationService from '../../../../../../services/filter-pagination-service'
import { useReactAlert } from '../../../../../../hooks/useReactAlert'
import MentorUserService from '../../../../../../services/mentor-services/mentor-user-service'
import MentorStatisticsService from '../../../../../../services/mentor-services/mentor-statistics-service'
import StudentAnalyticsReviewModal from '../../../../chief/components/StudentAnalytics/StudentAnalyticsTable/StudentAnalyticsReviewModal/StudentAnalyticsReviewModal'
import StudentAnalyticsTable from './student-analytics-table'
import { AnalyticsStudentEntityDto } from '../../../../../../model/analytics-dto/analytics-student-entity-dto'
import { StudentReviewStatisticDto } from '../../../../../../model/student-model'

const StudentAnalyticsPageWithHoc = () => {
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState(0)
  const [selectedStudentReviewStatistic, setSelectedStudentReviewStatistic] = useState<StudentReviewStatisticDto[]>([])
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const mentorStatisticsPaginationService = new FilterPaginationService<AnalyticsStudentEntityDto>(
    '/api/mentor/statistic'
  )
  const mentorStatisticsService = new MentorStatisticsService()
  const mentorUserService = new MentorUserService()
  const CustomPaginationComponent = PaginationComponent<AnalyticsStudentEntityDto, null>()

  useEffect(() => {
    if (selectedStudentId !== 0) {
      mentorStatisticsService.getReviewStatisticByStudentId(selectedStudentId).then(reviewStatistic => {
        setSelectedStudentReviewStatistic(reviewStatistic)
      })
    }
  }, [selectedStudentId])

  const directionComplete = (id: number, fetchPage: (() => void) | undefined) => {
    mentorUserService
      .setStudentDirectionCompleteByStudentId(id)
      .then(() => {
        reactAlert.success(`Направление студента успешно завершено`)
        if (fetchPage) fetchPage()
      })
      .catch(err => catchErrorAlert(err))
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <CustomPaginationComponent
          Wrapper={StudentAnalyticsTable}
          service={mentorStatisticsPaginationService}
          headerText="Аналитика по студентам"
          WrapperPropsObj={{
            onDirectionComplete: directionComplete,
            onShowReviewStatisticModal: setShowReviewModal,
            onStudentSelect: setSelectedStudentId,
          }}
        />
        <StudentAnalyticsReviewModal
          modalShowed={showReviewModal}
          onClose={() => setShowReviewModal(false)}
          selectedStudentReviewStatistic={selectedStudentReviewStatistic}
        />
      </div>
    </div>
  )
}

export default StudentAnalyticsPageWithHoc
