import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useReactAlert } from '../../../../hooks/useReactAlert'
import TableHeaderSorting from '../../../shared/table-header-sorting'
import { RemovedStudentsProjectQueueRow } from '../removed-students-project-queue-row'
import '../../../pages/mentor/mentor-page/components/users-page/users-list/user-list.css'
import { AuthService } from '../../../../services/auth-service'
import { assertRole } from '../../../../utils/assertRole'
import { RemovedProjectQueueDto } from '../../../../model/project-queue-dto/removed-project-queue-dto'
import RemovedProjectQueueService from '../../../../services/removed-project-queue-service'

enum SortTypes {
  PERSIST_DATE_ASC = 'PERSIST_DATE_ASC',
  PERSIST_DATE_DESC = 'PERSIST_DATE_DESC',
  PROJECT_QUEUE_DATE_ASC = 'PROJECT_QUEUE_DATE_ASC',
  PROJECT_QUEUE_DATE_DESC = 'PROJECT_QUEUE_DATE_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

const RemovedStudentsProjectQueueListWrapper: React.FC<Wrapper<RemovedProjectQueueDto>> = ({
  entitiesArray,
  setSortType,
  sortType,
  fetchPage,
}) => {
  const currentRole = AuthService.currentUserValue()?.role.name
  const { catchErrorAlert } = useReactAlert()
  assertRole(currentRole)

  const removedProjectQueueService = new RemovedProjectQueueService(currentRole)

  const { t } = useTranslation()

  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: `${t('Student')}`,
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('Student')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('Student')} ↓` },
      },
    },
    {
      header: t('Email'),
      sortOptions: {
        asc: { action: SortTypes.EMAIL_ASC, label: `${t('Email')} ↑` },
        desc: { action: SortTypes.EMAIL_DESC, label: `${t('Email')} ↑` },
      },
    },
    {
      header: 'Тип оплаты',
    },
    {
      header: t('EndPreparationDate'),
      sortOptions: {
        asc: { action: SortTypes.PROJECT_QUEUE_DATE_ASC, label: `${t('EndPreparationDate')} ↑` },
        desc: { action: SortTypes.PROJECT_QUEUE_DATE_ASC, label: `${t('EndPreparationDate')} ↓` },
      },
    },
    {
      header: 'Дата удаления из очереди',
      sortOptions: {
        asc: { action: SortTypes.PERSIST_DATE_ASC, label: 'Дата удаления из очереди ↑' },
        desc: { action: SortTypes.PERSIST_DATE_DESC, label: 'Дата удаления из очереди ↓' },
      },
    },
    {
      header: 'Удалил из очереди',
    },
    {
      header: 'Action',
    },
  ]

  const queueContentRows = entitiesArray.map(removedProjectQueueDto => {
    return (
      <RemovedStudentsProjectQueueRow
        key={removedProjectQueueDto.id}
        entity={removedProjectQueueDto}
        onReturnQueue={id => {
          removedProjectQueueService
            .returnToQueueById(id)
            .then(() => {
              if (fetchPage) fetchPage()
            })
            .catch(catchErrorAlert)
        }}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            {tableHeaders.map(header => (
              <th key={`${header.header}`}>
                <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
              </th>
            ))}
            <th />
          </thead>
          <tbody>{queueContentRows}</tbody>
        </table>
      </div>
    </>
  )
}

export { RemovedStudentsProjectQueueListWrapper }
