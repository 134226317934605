import React from 'react'
import SidebarNavigation from 'src/components/shared/sidebar-navigation'
import SidebarNavigationSection from 'src/components/shared/sidebar-navigation/sidebar-navigation-section'

const CuratorLeftSidebar: React.FC<{}> = () => {
  const curatorLinks = [
    {
      path: 'students/all',
      name: 'Все пользователи',
    },
    {
      path: 'students/invite',
      name: 'Приглашение студента',
    },
    {
      path: 'students/recruitment',
      name: 'Наборы',
    },
    {
      path: 'students/new-post-paymenters',
      name: 'Новые постоплатники',
    },
    {
      path: 'students/review-plan',
      name: 'План ревью',
    },
    {
      path: 'students/reports',
      name: 'Отчеты',
    },
  ]

  return (
    <SidebarNavigation>
      <SidebarNavigationSection routePath="/curator" sectionLinks={curatorLinks} />
    </SidebarNavigation>
  )
}

export default CuratorLeftSidebar
