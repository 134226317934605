import React from 'react'
import { Redirect } from 'react-router-dom'
import { AuthService } from '../../../services/auth-service'

const BlankPage = () => {
  const curUser = AuthService.currentUserValue()
  let to = '/login'
  if (curUser) {
    switch (curUser.role.name) {
      case 'ADMIN':
        to = '/admin/users/'
        break
      case 'MENTOR':
        to = '/mentor/review/table'
        break
      case 'PAY_STUDENT':
        to = '/user/courses'
        break
      case 'CHIEF_MENTOR':
        to = '/chief/review/planning'
        break
      case 'MANAGER':
        to = '/manager/students/current'
        break
      case 'STUDENT':
        to = '/enrollee/1'
        break
      case 'CHIEF_MANAGER':
        to = '/chief-manager/students/current'
        break
      case 'CURATOR':
        to = '/curator/students/all'
        break
      default:
        to = '/login'
    }
  }
  return <Redirect to={to} />
}

export default BlankPage
