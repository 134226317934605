import ApiService from '../api-service'
import { IntersectionUserDTO } from '../../components/pages/admin/users-page/user-payment-type-handler/user-payment-type-handler'
import RestPaginationService from '../rest-pagination-service'
import { PageDto } from '../../model/page-dto'
import { InviteTokenDto } from '../../model/invite-dto'
import { CuratorUserServiceInterface } from '../interfaces/curator-services/curator-user-service'
import { PaymentTypeGetDto } from '../../model/payment-dto/payment-type-get-dto'
import { RecruitmentDto } from '../../model/recruitment-dto/recruitment-dto'
import { CuratorDto } from '../../model/curator-dto/curator-dto'
import { UserDto } from '../../model/user-dto/user-dto'
import { RoleDto } from '../../model/role-dto'
import { StudentDto } from '../../model/student-dto/student-dto'
import { PayStudentDto } from '../../model/student-dto/pay-student-dto'
import delay from '../../utils/delay'

const Url = {
  ROOT: `/api/curator/user`,
  GET_PAGE: (numberPage: number, queryString: string, enabled: boolean) =>
    `page/${numberPage}?filterPattern=${queryString}&viewDisabled=${!enabled}`,
  ROLES: 'roles',
  CURATORS: 'curator',
  PAYMENT_TYPES: 'payment_types',
  RECRUITMENTS: 'payStudent/recruitment',
  RECRUITMENTS_WITH_PARAMS: (pageNumber: number, queryString: string, items: number) =>
    `payStudent/recruitment/page/${pageNumber}?filterPattern=${queryString}&items=${items}`,
  GET_STUDENT: (studentId: number) => `student/${studentId}`,
  GET_PAY_STUDENT: (studentId: number) => `payStudent/${studentId}`,
  INVITE_STUDENT: 'invite_student',
  ADD_STUDENT: 'student',
  ADD_PAY_STUDENT: 'payStudent',
  UPDATE_STUDENT: (id: number) => `student/${id}`,
  UPDATE_PAY_STUDENT: (id: number) => `payStudent/${id}`,
  CREATE_PAY_STUDENTS_FROM_FILE: 'createPayStudentWithSendPass/file',
}

export default class CuratorUserService extends RestPaginationService implements CuratorUserServiceInterface {
  constructor() {
    super(Url.ROOT)
  }

  requestCuratorController = ApiService.generateControllerRequestHelper(this.sourceURL)

  // @ts-ignore
  getPage = (numberPage: number, queryString: string, enabled: boolean) => {
    const queryUrl = Url.GET_PAGE(numberPage, queryString, enabled)

    return this.requestCuratorController<PageDto<UserDto>>(queryUrl)
  }

  getAllRoles = () => {
    return this.requestCuratorController<RoleDto>(Url.ROLES)
  }

  getAllCurators = () => {
    return this.requestCuratorController<CuratorDto[]>(Url.CURATORS)
  }

  getAllPaymentTypesDtos = () => {
    return this.requestCuratorController<PaymentTypeGetDto[]>(Url.PAYMENT_TYPES)
  }

  getRecruitments = () => {
    return this.requestCuratorController<RecruitmentDto>(Url.RECRUITMENTS)
  }

  getRecruitmentsWithPages = (pageNumber: number, queryString: string, items: number) => {
    const queryUrl = Url.RECRUITMENTS_WITH_PARAMS(pageNumber, queryString, items)

    return this.requestCuratorController<PageDto<RecruitmentDto>>(queryUrl)
  }

  getStudent = (studentId: number) => {
    return this.requestCuratorController<StudentDto>(Url.GET_STUDENT(studentId))
  }

  getPayStudent = (studentId: number) => {
    return this.requestCuratorController<PayStudentDto>(Url.GET_PAY_STUDENT(studentId))
  }

  addStudent = (entity: IntersectionUserDTO) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.requestCuratorController(Url.ADD_STUDENT, queryOption)
  }

  inviteStudent = (entity: InviteTokenDto) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.requestCuratorController<string>(Url.INVITE_STUDENT, queryOption)
  }

  addPaymentStudent = (entity: IntersectionUserDTO) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.requestCuratorController(Url.ADD_PAY_STUDENT, queryOption)
  }

  updateStudent = (entity: IntersectionUserDTO) => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.requestCuratorController(Url.UPDATE_STUDENT(entity.id), queryOption)
  }

  updatePaymentStudent = (entity: IntersectionUserDTO) => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return this.requestCuratorController(Url.UPDATE_PAY_STUDENT(entity.id), queryOption)
  }

  /**
   * @description При ошибке озвращает маппинг "email юзера: причина ошибки"
   */
  public createStudentsFromFile(file: File) {
    type Email = string
    type ErrorMessage = string

    const queryOption = {
      method: 'POST',
      body: file,
    }

    return this.requestCuratorController<Record<Email, ErrorMessage>>(Url.CREATE_PAY_STUDENTS_FROM_FILE, queryOption)
  }
}
