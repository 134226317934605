import { useAlert, transitions, positions } from 'react-alert'
import { ResponseError } from 'src/model/response-dto/response-error.model'
import { AuthService } from '../services/auth-service'
import { RoleEnum } from '../utils/select-state/RoleEnum'

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

const defaultMessages = {
  warning: 'Что-то пошло не так',
  clientError: 'Клиентаская ошибка. Проверь данные.',
  serverError: 'Ошибка сервера',
}

export type ReactAlert = {
  error: (msg: string) => void
  info: (msg: string) => void
  success: (msg: string) => void
  show: (msg: string) => void
}

export type CatchErrorAlert = (err: Error | ResponseError) => void

export const useReactAlert = () => {
  const alert = useAlert()

  const reactAlert = {
    error: (msg: string) => {
      console.error(msg)
      alert.error(msg, options)
    },
    info: (msg: string) => {
      console.info(msg)
      alert.info(msg, options)
    },
    success: (msg: string) => {
      alert.success(msg, options)
    },
    show: (msg: string) => {
      alert.show(msg, options)
    },
  }

  const catchErrorAlert = (err: Error | ResponseError) => {
    const role = AuthService.currentUserValue()?.role
    if (role && (role.name === RoleEnum.PAY_STUDENT || role.name === RoleEnum.STUDENT)) {
      return
    }
    if (!err) {
      return
    }

    if (err instanceof Error) {
      reactAlert.info(defaultMessages.warning)
      return
    }

    const { code, text } = err

    if ([401, 403].indexOf(code) !== -1) {
      return
    }

    if (code >= 500) {
      reactAlert.error(defaultMessages.serverError)
      return
    }

    // MAX_LENGTH_FOR_ERR_MSG - это количество символов в сообщении, чтобы отбросить длинный стек-трейс, пришедший от сервера
    const MAX_LENGTH_FOR_ERR_MSG = 80
    if (text && text.length < MAX_LENGTH_FOR_ERR_MSG) {
      reactAlert.error(text)
    } else {
      reactAlert.error(defaultMessages.clientError)
    }
  }

  return {
    catchErrorAlert,
    reactAlert,
  }
}
