import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useReactAlert } from '../../../../hooks/useReactAlert'
import TableHeaderSorting from '../../../shared/table-header-sorting'
import { StudentsProjectQueueRow } from '../students-project-queue-row'
import '../../../pages/mentor/mentor-page/components/users-page/users-list/user-list.css'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import ProjectQueueService from '../../../../services/project-queue-service'
import { AuthService } from '../../../../services/auth-service'
import { assertRole } from '../../../../utils/assertRole'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'

enum SortTypes {
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
}

const StudentsProjectQueueListWrapper: React.FC<Wrapper<ProjectQueueDto>> = ({
  entitiesArray,
  setSortType,
  sortType,
  fetchPage,
}) => {
  const currentRole = AuthService.currentUserValue()?.role.name
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  assertRole(currentRole)

  const queueService = new ProjectQueueService(currentRole)

  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: `${t('Student')}`,
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('Student')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('Student')} ↓` },
      },
    },
    {
      header: t('Email'),
      sortOptions: {
        asc: { action: SortTypes.EMAIL_ASC, label: `${t('Email')} ↑` },
        desc: { action: SortTypes.EMAIL_DESC, label: `${t('Email')} ↑` },
      },
    },
    {
      header: t('EndPreparationDate'),
      sortOptions: {
        asc: { action: SortTypes.DATE_ASC, label: `${t('EndPreparationDate')} ↑` },
        desc: { action: SortTypes.DATE_DESC, label: `${t('EndPreparationDate')} ↓` },
      },
    },
    {
      header: t('WhoBooked'),
    },
    {
      header: t('Actions'),
    },
  ]

  const deleteStudentFromQueue = (id: number): void => {
    queueService
      .deleteById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const returnStudentToStudy = (id: number): void => {
    queueService
      .returnEducationById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const bookStudent = (id: number): void => {
    queueService
      .bookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const unbookStudent = (id: number): void => {
    queueService
      .unBookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const queueContentRows = entitiesArray.map((user: ProjectQueueDto) => {
    return (
      <StudentsProjectQueueRow
        key={user.id}
        entity={user}
        onDelete={deleteStudentFromQueue}
        onReturnEducation={returnStudentToStudy}
        onBook={bookStudent}
        onUnBook={unbookStudent}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            {tableHeaders.map(header => (
              <th key={`${header.header}`}>
                <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
              </th>
            ))}
            <th />
          </thead>
          <tbody>{queueContentRows}</tbody>
        </table>
      </div>
    </>
  )
}

export { StudentsProjectQueueListWrapper }
