import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import AssociationTaskService from '../../../../services/admin-services/tasks/association-task-service'
import { withPagination } from '../../../hoc'
import AssociationTaskForm from './association-task-form/association-task-form'
import AssociationTaskList from './association-task-list/association-task-list'

const service = new AssociationTaskService()

const AssociationTaskPage = () => {
  const PaginationAssociationTasksList = withReactAlert(
    withPagination(AssociationTaskList, service, 'Задачи на сопоставление')
  )

  useEffect(() => {
    document.title = 'Администратор | Задачи на сопоставление'
  }, [])

  return (
    <div>
      <Switch>
        <Route
          path="/admin/tasks/association-task/"
          exact
          render={props => <PaginationAssociationTasksList {...props} />}
        />

        <Route
          path="/admin/tasks/association-task/add"
          exact
          render={props => <AssociationTaskForm {...props} taskId={0} callback={service.add} />}
        />

        <Route
          path="/admin/tasks/association-task/edit/:taskId"
          exact
          render={props => <AssociationTaskForm {...props} callback={service.update} />}
        />
      </Switch>
    </div>
  )
}

export default AssociationTaskPage
