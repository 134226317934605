import React from 'react'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useTranslation } from 'react-i18next'
import TableHeaderSorting from '../../../../../shared/table-header-sorting'

export interface RecruitmentWrapperProps {
  openCloseChooseWindow: () => void
  chooseFlow: (ids: number[], recruitmentId: number) => Promise<void>
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
}

export type RecruitmentEntities = {
  id: number
  name: string
  channelName: string
  directionName: string
  startTraining: string
}
enum SortTypes {
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
}
const RecruitmentComponentWrapper: React.FC<Wrapper<RecruitmentEntities, RecruitmentWrapperProps>> = ({
  entitiesArray,
  WrapperPropsObj,
  setSortType,
  sortType,
}) => {
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: 'Название набора',
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('recruitment')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('recruitment')} ↓` },
      },
    },
    {
      header: ' Имя канала',
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('recruitment')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('recruitment')} ↓` },
      },
    },
    {
      header: t('PreparationDirection'),
      sortOptions: {
        asc: { action: SortTypes.DIRECTION_ASC, label: `${t('PreparationDirection')} ↑` },
        desc: { action: SortTypes.DIRECTION_DESC, label: `${t('PreparationDirection')} ↓` },
      },
    },
    {
      header: t('startTraining'),
      sortOptions: {
        asc: {
          action: SortTypes.DATE_ASC,
          label: `${t('startTraining')} ↑`,
        },
        desc: {
          action: SortTypes.DATE_DESC,
          label: `${t('startTraining')} ↓`,
        },
      },
    },
  ]
  return (
    <div className="pagination-wrapped">
      <table className="table table-striped admin-users-table">
        <thead>
          <tr>
            {tableHeaders.map(header => (
              <th key={`${header.header}`}>
                <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <RecruitmentTableRender entitiesArray={entitiesArray} WrapperPropsObj={WrapperPropsObj} />
      </table>
    </div>
  )
}

const RecruitmentTableRender: React.FC<Wrapper<RecruitmentEntities, RecruitmentWrapperProps>> = ({
  entitiesArray,
  WrapperPropsObj,
}) => {
  return (
    <tbody>
      {entitiesArray?.map(recruitmentObj => {
        const { id, directionName, name, channelName, startTraining } = recruitmentObj
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{name}</td>
            <td>{channelName || '-'}</td>
            <td>{directionName}</td>
            <td>{startTraining}</td>
            <td>
              <button
                onClick={() => {
                  if (WrapperPropsObj) {
                    const { openCloseChooseWindow, chooseFlow, selectedIds, setSelectedIds } = WrapperPropsObj
                    chooseFlow(selectedIds, id).then(() => {
                      setSelectedIds([])
                      openCloseChooseWindow()
                    })
                  }
                }}
                type="button"
                className="btn btn-info btn-sm"
              >
                выбрать поток
              </button>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}
export default RecruitmentComponentWrapper
