import React, { useState, useMemo } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import { Collapse } from 'react-collapse'
import { Link } from 'react-router-dom'
import { MULTITEST_LINK_NAME } from 'src/components/pages/admin/multi-test-task-page/multi-test-task-page'
import { GenericTask } from '../../../../services/admin-services/tasks/generic-service/generic-task-service'

interface GenericTaskListRowProps<T extends GenericTask> {
  task: T
  onDelete: (id: number) => void
  onSwitchAvailable: (id: number) => void
  linkPart: string
}

export const GenericTaskListRow = <T extends GenericTask>(props: GenericTaskListRowProps<T>) => {
  const { task, onDelete, onSwitchAvailable, linkPart } = props
  const { id, title, points, description, isAvailable } = task

  const [open, setOpen] = useState(false)

  const attempts = useMemo(() => {
    if (linkPart === MULTITEST_LINK_NAME) {
      // @ts-expect-error: attemptsNumber может быть только у multi-test-task
      return task.attemptsNumber !== undefined ? <td>{task.attemptsNumber || '∞'}</td> : <td>-</td>
    }
    return null
  }, [])

  const confirmDeleteTask = () => {
    confirmAlert({
      title: `Удаление задачи`,
      message: 'Вы уверены что хотите удалить задачу?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      {attempts}
      <td>{points}</td>
      <td className="admin-code-description">
        <div className="panel-group accordion task-desc">
          <Button onClick={() => setOpen(!open)} aria-controls={`task_desc_${id}`} aria-expanded={open}>
            <p className="panel-title panel-max-with">Описание</p>
          </Button>
          <Collapse isOpened={open}>
            <p id={`task_desc_${id}`} className="task-desc-content" dangerouslySetInnerHTML={{ __html: description }} />
          </Collapse>
        </div>
      </td>
      <td className="admin-code-controls">
        <Link to={`/admin/tasks/${linkPart}/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
          {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteTask()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}
