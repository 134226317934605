import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Button } from 'react-bootstrap'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { studentHost } from '../../../../../config'

const UsersListRow = ({
  entity,
  onDelete,
  setShowAdditionalCourses,
  setSelectedStudentId,
  setSelectedStudentName,
  currentUser,
}) => {
  const { id, firstName, lastName, email, birthday, role, enabled } = entity
  let roleRender
  if (role.name === 'PAY_STUDENT') {
    roleRender = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-info btn-sm"
        href={`${studentHost}/fake/user/${id}/statistic`}
      >
        Страница студента
      </a>
    )
  } else {
    roleRender = role.name
  }

  const editLink = `${currentUser === RoleEnum.ADMIN ? '/admin/users' : '/curator/students'}/all/edit/${id}/${
    role.name
  }`

  const confirmDeleteUser = () => {
    confirmAlert({
      title: 'Удаление пользователя',
      message: 'Вы уверены что хотите заблокировать пользователя?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{birthday}</td>
      <td>{roleRender}</td>
      <td>{enabled ? 'активный' : 'заблокированный'}</td>
      <td>
        {role.name === RoleEnum.PAY_STUDENT ? (
          <button
            type="button"
            className="btn-modal-open"
            onClick={() => {
              setShowAdditionalCourses(true)
              setSelectedStudentId(id)
              setSelectedStudentName(`${firstName} ${lastName}`)
            }}
          >
            Открыть
          </button>
        ) : (
          '-'
        )}
      </td>
      <td>
        <Link to={editLink}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => confirmDeleteUser()}>
          {!enabled ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
      </td>
    </tr>
  )
}

export default UsersListRow
