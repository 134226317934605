import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

const NotificationsListRow = ({ notification, onDelete, onSelectForEdit }) => {
  const { id, title, message, important, endTime } = notification
  const confirmDeleteNotification = () => {
    confirmAlert({
      title: 'Удаление уведомления',
      message: 'Вы уверены что хотите удалить уведомление?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td className="admin-notification-message">{message}</td>
      <td>{important ? 'true' : 'false'}</td>
      <td>{endTime}</td>
      <td className="admin-notification-actions">
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            onSelectForEdit(notification)
          }}
        >
          <i className="bi bi-pencil" />
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteNotification()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default NotificationsListRow
