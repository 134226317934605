import React, { Component } from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import PreModerCommentsList from './premoderation-comments-list'
import { withPagination } from '../../../hoc'
import PreModerCommentsService from '../../../../services/premoder-comments-service'

class PreModerCommentsPage extends Component {
  componentDidMount() {
    document.title = 'Администратор | Комментарии для модерации'
  }

  render() {
    const service = new PreModerCommentsService()
    const PaginationPreModerComments = withReactAlert(
      withPagination(PreModerCommentsList, service, 'Комментарии для модерации', `admin`)
    )
    return <PaginationPreModerComments />
  }
}

export default PreModerCommentsPage
