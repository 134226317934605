import React from 'react'
import TableReviewBlock from '../table-review-block'
import './chief-planning-reviews-table-row.css'
import NumberUtil from '../../../../../../../utils/NumberUtil'

const ChiefPlanningReviewsTableRow = function ChiefPlanningReviewsTableRow({
  reviewTime,
  setShowAddReviewModal,
  setShowEditReviewModal,
  showEditModal,
  reviewsInThisTime,
  setSelectedTime,
  setSelectedReviewDate,
  setSelectedReviewId,
  setDefaultReviewData,
  setStudentsRegistered,
}) {
  const rowItem = Object.keys(reviewsInThisTime).map(reviewDate => {
    const blocksReview = reviewsInThisTime[reviewDate].map(review => {
      const {
        reviewId,
        studentsCount,
        availableSlots,
        mentorShortName,
        moduleCode,
        studentsRegistered,
        zoomAcc,
        reviewComplete,
      } = review
      return (
        <TableReviewBlock
          value={NumberUtil.getPercentage(studentsCount, availableSlots)}
          mentorShortName={mentorShortName}
          moduleCode={moduleCode}
          reviewTime={reviewTime}
          showEditModal={showEditModal}
          setSelectedTime={setSelectedTime}
          reviewId={reviewId}
          zoomAcc={zoomAcc}
          setSelectedReviewId={setSelectedReviewId}
          setSelectedReviewDate={() => setSelectedReviewDate(reviewDate)}
          studentsRegistered={studentsRegistered}
          setStudentsRegistered={setStudentsRegistered}
          key={reviewId + mentorShortName + moduleCode}
          reviewComplete={reviewComplete}
          review={review}
          setShowEditReviewModal={setShowEditReviewModal}
        />
      )
    })
    return (
      <td key={`table-row-${reviewDate}`}>
        <span className="chief-review-table-container">
          {blocksReview}
          <div
            className="chief-review-button-container"
            onClick={() => {
              setShowAddReviewModal(true)
              setSelectedTime(reviewTime)
              setSelectedReviewDate(reviewDate)
              setDefaultReviewData()
            }}
          >
            <i className="mdi mdi-plus" />
          </div>
        </span>
      </td>
    )
  })

  return (
    <tr>
      <td className="chief-review-table-time-cell">{`${reviewTime} - ${parseInt(reviewTime.substring(0, 2), 10) +
        1}:${parseInt(reviewTime.substring(3, 4), 10)}${parseInt(reviewTime.substring(4, 5), 10)}`}</td>
      {rowItem}
    </tr>
  )
}

export default ChiefPlanningReviewsTableRow
