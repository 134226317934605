import React from 'react'
import { Button, FormControl } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import CourseService from '../../../../../../services/admin-services/admin-course-service'

class AuthorBlock extends React.Component {
  service = new CourseService()

  state = {
    courseAuthor: {},
    done: false,
  }

  componentDidMount() {
    const { courseId = 0, catchErrorAlert } = this.props

    if (courseId > 0) {
      this.service
        .getCourseAuthor(courseId)
        .then(courseAuthor => this.setState({ courseAuthor }))
        .catch(error => {
          if (catchErrorAlert) catchErrorAlert(error)
        })
    }
  }

  render() {
    const { courseAuthor, done } = this.state
    const { courseId } = this.props
    const { name, email, about, avatarLink } = courseAuthor
    return (
      <Formik
        enableReinitialize
        initialValues={{ name, email, about, avatarLink }}
        onSubmit={values => {
          this.service.updateCourseAuthor(courseId, values).then(() => {
            this.setState({ done: true })
          })
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Имя автора курса: </label>
              <FormControl value={values.name} type="text" name="name" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Email автора курса: </label>
              <FormControl value={values.email} name="email" onChange={handleChange} type="text" />
            </div>
            <div className="form-group">
              <label>Об авторе: </label>
              <FormControl as="textarea" value={values.about} name="about" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Ссылка на аватар: </label>
              <FormControl value={values.avatarLink} name="avatarLink" onChange={handleChange} type="text" />
            </div>
            {done && (
              <div className="alert alert-success" role="alert">
                Информация сохранена
              </div>
            )}
            <div className="form-group">
              <Button type="submit" variant="success">
                Сохранить
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withReactAlert(AuthorBlock)
