import React from 'react'
import { Route, Switch } from 'react-router-dom'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { history } from '../../../../config'
import { CuratorDirectionsService } from '../../../../services/curator-services/curator-direction-service'
import UsersList from './users-list'
import UserPaymentTypeHandler from './user-payment-type-handler'
import { withPagination } from '../../../hoc'
import AdminUserService from '../../../../services/admin-services/admin-user-service'
import { AuthService } from '../../../../services/auth-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import CuratorUserService from '../../../../services/curator-services/curator-user-service'
import AdminDirectionService from '../../../../services/admin-services/admin-direction-service'

const UsersPage = () => {
  const currentUserRole = AuthService.currentUserValue().role.name
  let basePath
  let userService
  let directionService
  let role

  if (currentUserRole === RoleEnum.ADMIN) {
    basePath = 'admin/users'
    userService = new AdminUserService()
    directionService = new AdminDirectionService()
    role = 'Администратор'
  } else {
    basePath = 'curator/students'
    userService = new CuratorUserService()
    directionService = CuratorDirectionsService
    role = 'Куратор'
  }

  useSetDocumentTitle(`${role} | Все пользователи`)

  const PaginationUsersList = withReactAlert(withPagination(UsersList, userService, 'Все пользователи', '', true))
  return (
    <div className="admin-content">
      <Switch>
        <Route path={`/${basePath}/all`} exact render={props => <PaginationUsersList {...props} />} />
        <Route
          path={`/${basePath}/all/add`}
          exact
          render={props => (
            <UserPaymentTypeHandler
              {...props}
              baseLink={basePath}
              userService={userService}
              directionService={directionService}
              currentUserRole={role}
              history={history}
            />
          )}
        />
        <Route
          path={`/${basePath}/all/edit/:userId/:userRole`}
          exact
          render={props => (
            <UserPaymentTypeHandler
              {...props}
              baseLink={basePath}
              userService={userService}
              directionService={directionService}
              currentUserRole={role}
              history={history}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default UsersPage
