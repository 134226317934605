import React, { useEffect, useState } from 'react'
import { formatDateToDDMMYYYY } from 'src/utils/dateUtils'
import { useReactAlert } from 'src/hooks/useReactAlert'
import NumberUtil from 'src/utils/NumberUtil'
import MentorPlanningReviewsActionPanel from './planning-reviews-action-panel'
import MentorPlanningReviewsTable from './planning-reviews-table'

import PlanningReviewModal from './planning-reviews-table/planning-review-modal/planning-review-modal'
import Spinner from '../../../../../spinner'
import MentorPlanningReviewsMentorList from './planning-reviews-mentor-list'
import MentorReviewService from '../../../../../../services/mentor-services/mentor-review-service'
import { AuthService } from '../../../../../../services/auth-service'
import './mentor-planning-reviews.css'
import { REVIEW } from '../../../../../../constants/review'
import { ReviewType } from '../../../../../../model/review/review-type'
import PlanningReviewCreateModal from './planning-reviews-table/planning-review-create-modal'
import PlanningReviewEditModal from './planning-reviews-table/planning-review-edit-modal/planning-review-edit-modal'
import PlanningReviewConfirmRemoveReviewModal from '../../../../chief/components/chief-planning-reviews/chief-planning-reviews-table/planning-review-confirm-remove-review-modal'

const mentorReviewsService = new MentorReviewService()

const MentorPlanningReviews = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const getMonday = date => {
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  const [reviewForWeek, setReviewForWeek] = useState({})
  const [selectedDate, setSelectedDate] = useState(getMonday(new Date()))
  const [selectedReviewType, setSelectedReviewType] = useState(ReviewType.GROUP)
  const [currentReviewType, setCurrentReviewType] = useState(ReviewType.GROUP)
  const [selectedReviewTime, setSelectedReviewTime] = useState(``)
  const [selectedReviewDate, setSelectedReviewDate] = useState(new Date())
  const [selectedCourseId, setSelectedCourseId] = useState(0)
  const [selectedModuleId, setSelectedModuleId] = useState(0)
  const [selectedMentorId, setSelectedMentorId] = useState(0)
  const [selectedMentorName, setSelectedMentorName] = useState('')
  const [selectedReviewId, setSelectedReviewId] = useState(0)
  const [students, setStudentsDto] = useState([])
  const [selectedZoomAccount, setSelectedZoomAccount] = useState('')
  const [selectedAvailableSlots, setSelectedAvailableSlots] = useState(REVIEW.DEFAULT_PERSONAL_MEMBERS_COUNT)
  const [selectedCodeHasReviewed, setSelectedCodeHasReviewed] = useState(false)
  const [selectedTheoryHasReviewed, setSelectedTheoryHasReviewed] = useState(false)
  const [courses, setCourses] = useState([])
  const [modules, setModules] = useState([])
  const [planningMentors, setPlanningMentors] = useState([])
  const [isLoaded, setIsLoaded] = useState(true)
  const [zoomLink, setZoomLink] = useState('')
  const [reviewDateTime, setReviewDateTime] = useState(new Date())
  const [self, setSelf] = useState(false)
  const [selectedReviewWeekCourse, setSelectedReviewWeekCourse] = useState()
  const [reviewWeekModules, setReviewWeekModules] = useState()
  const [selectedReviewWeekModules, setSelectedReviewWeekModules] = useState()

  const curUser = AuthService.currentUserValue()

  const onChangeReviewsByDate = () => {
    const date = formatDateToDDMMYYYY(selectedDate)

    mentorReviewsService
      .getReviewForWeekByType({
        filterDate: date,
        courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
        moduleId: selectedReviewWeekModules?.id,
        reviewType: selectedReviewType,
        self,
      })
      .then(response => {
        setReviewForWeek(response)
        setCurrentReviewType(selectedReviewType)
      })

    mentorReviewsService
      .getMentorsForWeek({
        filterDate: date,
        courseId: selectedReviewWeekModules?.id ? undefined : selectedReviewWeekCourse?.id,
        moduleId: selectedReviewWeekModules?.id,
        reviewType: selectedReviewType,
        self,
      })
      .then(setPlanningMentors)
  }

  const getReviewsDate = () => {
    const weekDate = new Set()

    Object.keys(reviewForWeek).forEach(key => {
      Object.keys(reviewForWeek[key]).forEach(date => {
        weekDate.add(date)
      })
    })
    return [...weekDate]
  }

  const [rangeDate, setRangeDate] = useState([])
  const [showInfoReviewModal, setShowInfoReviewModal] = useState(false)
  const [showEditReviewModal, setShowEditReviewModal] = useState(false)
  const [showAddReviewModal, setShowAddReviewModal] = useState(false)
  const [showConfirmRemoveReviewModal, setShowConfirmRemoveReviewModal] = useState(false)

  useEffect(() => {
    if (!selectedReviewWeekCourse?.id) return

    const fetchCourseModules = async () => {
      try {
        const courseModules = await mentorReviewsService.getModulesByCourseId(selectedReviewWeekCourse.id)
        setReviewWeekModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }

    fetchCourseModules()
  }, [selectedReviewWeekCourse])

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setRangeDate(getReviewsDate())
        const mentorCourses = await mentorReviewsService.getCourses()
        setCourses(mentorCourses)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchCourses()
  }, [])

  useEffect(() => {
    if (!selectedCourseId) return

    const fetchModules = async () => {
      try {
        const courseModules = await mentorReviewsService.getModulesByCourseId(selectedCourseId)
        setModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchModules()
  }, [selectedCourseId])

  useEffect(() => {
    onChangeReviewsByDate()
  }, [])

  useEffect(() => {
    setRangeDate(getReviewsDate())
    setIsLoaded(false)
  }, [reviewForWeek])

  useEffect(() => {
    if (selectedReviewId !== 0) {
      mentorReviewsService.getChiefReviewDtoByReviewId(selectedReviewId).then(response => {
        const {
          mentorId,
          courseId,
          moduleId,
          zoomAcc,
          codeHasReviewed,
          theoryHasReviewed,
          studentReviewForMentorDtos,
          reviewTime,
          mentorName,
          availableSlots,
          zoomLink: responseZoomLink,
        } = response
        setSelectedMentorId(mentorId)
        setSelectedCourseId(courseId)
        setSelectedModuleId(moduleId || 0)
        setSelectedZoomAccount(zoomAcc)
        setSelectedAvailableSlots(availableSlots)
        setSelectedCodeHasReviewed(codeHasReviewed)
        setSelectedTheoryHasReviewed(theoryHasReviewed)
        setStudentsDto(studentReviewForMentorDtos)
        setSelectedMentorName(mentorName)
        setShowInfoReviewModal(true)
        setZoomLink(responseZoomLink)
        const arrDateTime = reviewTime.split(' ')
        const arrDate = arrDateTime[0].split('.')
        const dateTemp = arrDate[2]
          .concat('-')
          .concat(arrDate[1])
          .concat('-')
          .concat(arrDate[0])
          .concat('T')
          .concat(arrDateTime[1])
          .toString()
        setReviewDateTime(new Date(dateTemp))
      })
    }
  }, [selectedReviewId])

  const onAvailableSlotsChange = e => {
    const { value } = e.target

    if (!value || NumberUtil.isNonNegativeNumber(value)) {
      setSelectedAvailableSlots(Number(value))
    }
  }

  const setDefaultReviewData = () => {
    setSelectedMentorId(0)
    setSelectedCourseId(0)
    setSelectedModuleId(0)
    setSelectedReviewId(0)
    setSelectedZoomAccount('')
    setSelectedAvailableSlots(REVIEW.DEFAULT_PERSONAL_MEMBERS_COUNT)
    setZoomLink('')
  }

  const createReview = () => {
    if (selectedModuleId * selectedCourseId === 0 && currentReviewType !== ReviewType.PERSONAL_MENTOR) {
      reactAlert.error(`Невозможно создать ревью. Курс/Модуль не выбран`)
      return
    }

    const date = formatDateToDDMMYYYY(selectedReviewDate)

    const dto = {
      reviewTime: `${date} ${selectedReviewTime}:00`,
      moduleId: selectedModuleId,
      zoomAcc: selectedZoomAccount,
      availableSlots: selectedAvailableSlots,
    }

    mentorReviewsService
      .createReview(dto)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно создано`)
      })
      .catch(catchErrorAlert)
  }

  const updateReview = () => {
    if (selectedModuleId * selectedCourseId === 0 && currentReviewType !== ReviewType.PERSONAL_MENTOR) {
      reactAlert.error(`Невозможно создать ревью. Курс/Модуль не выбран`)
      return
    }

    const date = formatDateToDDMMYYYY(selectedReviewDate)

    const dto = {
      reviewTime: `${date} ${selectedReviewTime}:00`,
      moduleId: selectedModuleId,
      zoomAcc: selectedZoomAccount,
      availableSlots: selectedAvailableSlots,
    }

    mentorReviewsService
      .updateReviewByReviewId(dto, selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно обновлено`)
      })
      .catch(catchErrorAlert)
  }

  const removeReview = () => {
    mentorReviewsService
      .deleteReviewById(selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        reactAlert.success(`Ревью успешно удалено`)
      })
      .catch(catchErrorAlert)
  }

  const onChangeCourseSelector = e => {
    const courseId = +e.target.value

    if (!courseId) {
      setModules([])
      return
    }

    setSelectedCourseId(courseId)
    mentorReviewsService
      .getModulesByCourseId(courseId)
      .then(courseModules => {
        if (!courseModules[0]) return courseModules
        setSelectedModuleId(courseModules[0].id)
        return courseModules
      })
      .then(setModules)
      .catch(catchErrorAlert)
  }

  const onChangeCourseReviewFilterSelector = e => {
    const courseId = +e.target.value
    if (!courseId) {
      setSelectedReviewWeekCourse(undefined)
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekCourse(courses.find(course => course.id === courseId))
  }

  const onChangeModuleReviewFilterSelector = e => {
    const moduleId = +e.target.value
    if (!moduleId) {
      setSelectedReviewWeekModules(undefined)
      return
    }
    setSelectedReviewWeekModules(reviewWeekModules?.find(module => module.id === moduleId))
  }

  const onChangeModuleSelector = e => {
    setSelectedModuleId(Number(e.target.value))
  }

  const onChangeZoomAccount = e => {
    setSelectedZoomAccount(e.target.value)
  }

  const handleShowReviewEditModal = () => {
    setShowInfoReviewModal(false)
    setShowEditReviewModal(true)
  }

  const onAssignReview = () => {
    mentorReviewsService
      .assignReview(selectedReviewId)
      .then(() => {
        setSelectedMentorId(curUser.id)
        onChangeReviewsByDate()
      })
      .catch(err => {
        onChangeReviewsByDate()

        catchErrorAlert(err)
      })
  }

  const onRefuseReview = () => {
    mentorReviewsService
      .refuseReview(selectedReviewId)
      .then(() => {
        setSelectedMentorId(0)
        onChangeReviewsByDate()
      })
      .catch(err => {
        onChangeReviewsByDate()
        catchErrorAlert(err)
      })
  }

  if (isLoaded) {
    return <Spinner />
  }
  const reviewDate = new Date(selectedReviewDate).toLocaleString('ru', {
    weekday: `long`,
    year: `numeric`,
    month: `numeric`,
    day: `numeric`,
  })

  return (
    <div className="chief-page">
      <header className="chief-header">
        <h1 id="planning-review-header">Ревью</h1>
      </header>
      <section className="chief-section">
        <MentorPlanningReviewsActionPanel
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedReviewType={selectedReviewType}
          setSelectedReviewType={setSelectedReviewType}
          onChangeReviewsByDate={() => onChangeReviewsByDate()}
          courses={courses}
          selectedCourse={selectedReviewWeekCourse}
          onChangeCourse={onChangeCourseReviewFilterSelector}
          modules={reviewWeekModules}
          selectedModule={selectedReviewWeekModules}
          onChangeModule={onChangeModuleReviewFilterSelector}
          self={self}
          onChangeSelf={setSelf}
        />
        <MentorPlanningReviewsTable
          selectedDates={rangeDate}
          setSelectedTime={setSelectedReviewTime}
          setSelectedReviewDate={setSelectedReviewDate}
          reviewForWeek={reviewForWeek}
          setSelectedReviewId={setSelectedReviewId}
          curUser={curUser}
          currentReviewType={currentReviewType}
          setShowEditReviewModal={setShowInfoReviewModal}
          setShowAddReviewModal={setShowAddReviewModal}
          setDefaultReviewData={setDefaultReviewData}
        />
        <MentorPlanningReviewsMentorList planningMentors={planningMentors} />
      </section>
      <footer className="chief-footer" />
      <PlanningReviewCreateModal
        courses={courses}
        modules={modules}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        createReview={createReview}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        setDefaultReviewData={setDefaultReviewData}
        modalShowed={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
      />
      <PlanningReviewModal
        availableSlots={selectedAvailableSlots}
        courses={courses}
        modules={modules}
        students={students}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        selectedCourseId={selectedCourseId}
        selectedModuleId={selectedModuleId}
        selectedMentorId={selectedMentorId}
        selectedMentorName={selectedMentorName}
        selectedZoomAccount={selectedZoomAccount}
        modalShowed={showInfoReviewModal}
        setDefaultReviewData={setDefaultReviewData}
        onClose={() => setShowInfoReviewModal(false)}
        curUser={curUser}
        onAssignReview={onAssignReview}
        onRefuseReview={onRefuseReview}
        selectedCodeHasReviewed={selectedCodeHasReviewed}
        selectedReviewId={selectedReviewId}
        selectedTheoryHasReviewed={selectedTheoryHasReviewed}
        reviewDateTime={reviewDateTime}
        reviewType={currentReviewType}
        onShowEditReviewModal={handleShowReviewEditModal}
      />
      <PlanningReviewEditModal
        courses={courses}
        modules={modules}
        students={students}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        availableSlots={selectedAvailableSlots}
        onAvailableSlotsChange={onAvailableSlotsChange}
        selectedCourseId={selectedCourseId}
        selectedModuleId={selectedModuleId}
        selectedMentorName={selectedMentorName}
        selectedZoomAccount={selectedZoomAccount}
        onChangeCourseSelector={onChangeCourseSelector}
        onChangeModuleSelector={onChangeModuleSelector}
        onChangeZoomAccount={onChangeZoomAccount}
        modalShowed={showEditReviewModal}
        updateReview={updateReview}
        setDefaultReviewData={setDefaultReviewData}
        onClose={() => setShowEditReviewModal(false)}
        showConfirmRemoveReviewModal={() => setShowConfirmRemoveReviewModal(true)}
        studentsRegistered={false}
        zoomLink={zoomLink}
      />
      <PlanningReviewConfirmRemoveReviewModal
        modalShowed={showConfirmRemoveReviewModal}
        onCloseConfirmModal={() => setShowConfirmRemoveReviewModal(false)}
        removeReview={removeReview}
        openEditModal={() => setShowEditReviewModal(true)}
        selectedDate={reviewDate}
        selectedTime={selectedReviewTime}
        setDefaultReviewData={setDefaultReviewData}
      />
    </div>
  )
}

export default MentorPlanningReviews
