import React from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import AdminChiefService from '../../../../services/admin-services/admin-chief-service'
import ChiefRow from './chief-row'
import ChiefRowModal from './chief-row-modal'

class ChiefsPage extends React.Component {
  chiefsService = new AdminChiefService()

  state = {
    chiefsDto: [],
    modalState: [],
    changeList: [],
  }

  componentDidMount() {
    const { catchErrorAlert } = this.props
    document.title = 'Администратор | Все главные менторы'

    this.chiefsService
      .getInfoByChiefs()
      .then(chiefsDto => {
        const modalState = chiefsDto.map(chief => {
          return { id: chief.chiefMentor.id, hidden: true }
        })
        this.setState({
          chiefsDto,
          modalState,
        })
      })
      .catch(err => {
        if (catchErrorAlert) catchErrorAlert(err)
      })
  }

  modalShow = key => {
    const { modalState } = this.state
    let newState = [...modalState]
    newState = newState.map(state => {
      return { ...state, hidden: state.id !== key }
    })
    this.setState({
      modalState: newState,
    })
  }

  modalHidden = () => {
    const { modalState } = this.state
    let newState = [...modalState]
    newState = newState.map(state => {
      return { ...state, hidden: true }
    })
    this.setState({
      modalState: newState,
      changeList: [],
    })
  }

  onActive = (keyModal, dirId) => {
    const { chiefsDto, changeList } = this.state
    const newState = [...chiefsDto]
    let changeDir = [...changeList]
    const dto = newState.filter(x => x.chiefMentor.id === keyModal)[0]
    const dirrs = dto.dirrs.map(dir => {
      if (dir.id === dirId) {
        dir.active = !dir.active
      } else {
        dir.active = false
      }
      return dir
    })
    const dir = dirrs.filter(direction => direction.id === dirId)[0]
    const idx = changeDir.findIndex(direct => direct.id === dir.id)
    if (idx === -1) {
      changeDir = [dir]
    } else {
      changeDir = [...changeDir.slice(0, idx), ...changeDir.slice(idx + 1)]
    }
    this.setState({
      chiefsDto: newState,
      changeList: changeDir,
    })
  }

  onReplace = keyModal => {
    const { chiefsDto, changeList } = this.state
    const newState = [...chiefsDto]
    const newChangeList = [...changeList]
    const dto = newState.filter(x => x.chiefMentor.id === keyModal)[0]
    dto.dirrs.forEach(dir => {
      if (dir.active) {
        // eslint-disable-next-line no-param-reassign
        dir.available = false
        // eslint-disable-next-line no-param-reassign
        dir.active = false
        return
      }
      dir.available = true
    })
    const dirId = newChangeList.map(change => {
      return change.id
    })
    this.chiefsService.replaceChiefsDir(keyModal, dirId)
    this.setState({
      chiefsDto: newState,
      changeList: [],
    })
  }

  render() {
    const { chiefsDto, changeList, modalState } = this.state
    const mentorsRows = chiefsDto.map(chief => {
      return <ChiefRow onShow={() => this.modalShow(chief.chiefMentor.id)} key={chief.chiefMentor.id} {...chief} />
    })
    const chiefRowModals = modalState
      .filter(state => !state.hidden)
      .map(state => {
        const chiefData = chiefsDto.filter(x => x.chiefMentor.id === state.id)[0]
        return (
          <ChiefRowModal
            onActive={(modalKey, dirId) => this.onActive(modalKey, dirId)}
            onReplace={modalKey => this.onReplace(modalKey)}
            key={state.id}
            modalId={state.id}
            hidden={state.hidden}
            changeList={changeList}
            {...chiefData}
            onHidden={this.modalHidden}
          />
        )
      })
    return (
      <div>
        <h1 className="page-header">Все главные менторы</h1>
        <table className="table table-striped">
          <thead>
            <tr>
              <td>Id</td>
              <td>Email</td>
              <td>Направления</td>
            </tr>
          </thead>
          <tbody>{mentorsRows}</tbody>
        </table>
        {chiefRowModals}
      </div>
    )
  }
}

export default withReactAlert(ChiefsPage)
