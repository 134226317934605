import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-modal'
import { Formik, getIn } from 'formik'
import date from 'date-and-time'
import { v4 as uuidv4 } from 'uuid'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import classNames from 'classnames'
import { AuthService } from 'src/services/auth-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { DeadlineInfoDTO } from 'src/model/DTO/DeadlineDTO'
import { DirectionGetDto } from 'src/model/direction-model'
import { useReactAlert } from 'src/hooks/useReactAlert'
import {
  customStyles,
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from 'src/components/shared/modal-view'

import { MentorGetDto } from 'src/model/mentor-model'
import { RecruitmentServiceInterface } from '../../../../../../../../services/interfaces/curator-services/curator-recruitment-service'
import styles from '../recruitment-modal.module.scss'
import { DirectionCourseDto } from '../../../../../../../../model/courses-dto/direction-course-dto'
import { DeadlineCourseDto } from '../../../../../../../../model/courses-dto/course-deadline-dto'
import { ChannelState, InitialForm } from '../create-recruitment-modal/create-recruitment-modal'

interface EditRecruitmentModal {
  currentCourses: DirectionCourseDto[]
  entity: RecruitmentDto | null
  directions: DirectionGetDto[]
  show: boolean
  onHide: () => void
  mentors: MentorGetDto[]
  setCurrentDirectionId: Dispatch<SetStateAction<string>>
  recruitmentService: RecruitmentServiceInterface
}

interface Errors {
  directionId?: string
  commonRecruitmentChannelName?: string
  deadlinesInfo?: string
  name?: string
  channelName?: string
}

type Deadline = DeadlineInfoDTO & { deadlineDate: Date }

type EditInitialForm = InitialForm & {
  id: number
}

function formatDeadlineDates(deadlines: DeadlineInfoDTO[]): Deadline[]
function formatDeadlineDates(deadlines: any[]): DeadlineInfoDTO[] | Deadline[] {
  const isDateData = (dateData: string | Date): dateData is string => {
    return typeof dateData === 'string'
  }

  return deadlines.map(deadline => ({
    ...deadline,
    deadlineDate: isDateData(deadline.deadlineDate)
      ? date.parse(deadline.deadlineDate, 'DD.MM.YYYY', true)
      : date.format(deadline.deadlineDate, 'DD.MM.YYYY'),
  }))
}

const EditRecruitmentModal = ({
  currentCourses,
  entity,
  mentors,
  directions,
  setCurrentDirectionId,
  onHide,
  recruitmentService,
  show,
}: EditRecruitmentModal) => {
  const { reactAlert, catchErrorAlert } = useReactAlert()
  const currentRole = AuthService.currentUserValue()?.role.name
  const [updateDeadlines, setUpdateDeadlines] = useState(false)
  const [channelStatus, setChannelStatus] = useState(ChannelState.CREATE_CHANNEL)
  const recruitmentFormRef = useRef<HTMLFormElement>(null)
  const [showDeadlines, setShowDeadlines] = useState(false)
  const isChief = currentRole === RoleEnum.CHIEF_MENTOR

  useEffect(() => {
    setChannelStatus(entity?.channelName ? ChannelState.CHANNEL_EXIST : ChannelState.CREATE_CHANNEL)
  }, [entity])

  const submitRecruitmentForm = () => {
    if (recruitmentFormRef.current) {
      recruitmentFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const validate = ({
    directionId,
    commonRecruitmentChannelName,
    deadlinesInfo,
    name,
    channelName,
  }: {
    name: string
    directionId: string
    channelName: string
    mentorId: string
    commonRecruitmentChannelName: string
    deadlinesInfo: DeadlineCourseDto[]
  }) => {
    const errors: Errors = {}
    if (!directionId && currentRole === RoleEnum.CURATOR) {
      errors.directionId = 'Нужно выбрать направление'
    }
    if (!commonRecruitmentChannelName) {
      errors.commonRecruitmentChannelName = 'Нужно ввести общий для групп потока Slack канал'
    }
    deadlinesInfo.forEach(item => {
      if (item.deadlineDate === '') {
        errors.deadlinesInfo = 'Нужно ввести дедлайны для всех курсов'
      }
    })

    if (!name) {
      errors.name = 'Нужно ввести название'
    }

    if (!channelName && channelStatus === ChannelState.CHANNEL_EXIST) {
      errors.channelName = 'Нужно ввести имя канала'
    }

    return errors
  }

  const handleChangeChannelStatus = (setFieldValue: (filed: string, value: string) => void) => {
    if (channelStatus === ChannelState.CHANNEL_EXIST) {
      setChannelStatus(ChannelState.CREATE_CHANNEL)
      setFieldValue('channelName', '')
    } else {
      setChannelStatus(ChannelState.CHANNEL_EXIST)
      setFieldValue('channelName', entity?.channelName ?? '')
    }
  }

  if (!entity) return null
  const {
    id,
    name,
    directionId,
    mentorId,
    commonRecruitmentChannelName,
    startTraining,
    deadlinesInfo,
    channelName,
  } = entity
  return (
    <Modal isOpen={show} onRequestClose={onHide} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Редактирование набора</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            initialStatus={{ apiErrors: {} }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              const submitDataToEdit: EditInitialForm = {
                ...values,
                channelName: values.channelName || null,
                startTraining: date.format(values.startTraining, 'DD.MM.YYYY'),
                deadlinesInfo: formatDeadlineDates(values.deadlinesInfo),
                id,
              }
              if (isChief) {
                delete submitDataToEdit.directionId
              }

              recruitmentService
                .editRecruitment(id, submitDataToEdit)
                .then(() => {
                  reactAlert.success('Набор успешно изменен!')
                  onHide()
                })
                .catch(catchErrorAlert)
            }}
            initialValues={{
              name,
              directionId: String(directionId),
              mentorId: String(mentorId),
              commonRecruitmentChannelName,
              startTraining: date.parse(String(startTraining), 'DD.MM.YYYY', true),
              deadlinesInfo: formatDeadlineDates(deadlinesInfo),
              channelName: channelName ?? '',
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit, errors, status }) => {
              const err = getIn(status, ['apiErrors', 'error'])
              return (
                <Form onSubmit={handleSubmit} ref={recruitmentFormRef}>
                  <Form.Group controlId="name">
                    <Form.Label>Название группы в EWP</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="name"
                      type="text"
                      placeholder="Введите название"
                      required
                      value={values.name}
                    />
                    {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                  </Form.Group>
                  <Form.Group controlId="channelName">
                    <Form.Label>Имя канала набора</Form.Label>
                    <div>
                      <div className="mb-2">
                        <Form.Check
                          type="radio"
                          name="channelRadioState"
                          label="Создать канал"
                          id={uuidv4()}
                          onChange={() => handleChangeChannelStatus(setFieldValue)}
                          checked={channelStatus === ChannelState.CREATE_CHANNEL}
                          inline
                        />
                        <Form.Check
                          type="radio"
                          name="channelRadioState"
                          label="Канал уже существует"
                          id={uuidv4()}
                          onChange={() => handleChangeChannelStatus(setFieldValue)}
                          checked={channelStatus === ChannelState.CHANNEL_EXIST}
                          inline
                        />
                      </div>
                      <Form.Control
                        onChange={handleChange}
                        name="channelName"
                        type="text"
                        placeholder="Введите название канала"
                        value={values.channelName}
                        disabled={channelStatus === ChannelState.CREATE_CHANNEL}
                      />
                    </div>
                    {errors.channelName && <div style={{ color: '#ff0000' }}>{errors.channelName}</div>}
                  </Form.Group>
                  {!isChief && (
                    <Form.Group controlId="directionId">
                      <Form.Label>Направление подготовки</Form.Label>
                      <Form.Control
                        onChange={evt => {
                          handleChange(evt)
                          setCurrentDirectionId(evt.target.value)
                          setUpdateDeadlines(true)
                          setShowDeadlines(false)
                        }}
                        as="select"
                        name="directionId"
                        value={values.directionId}
                      >
                        <option value="">Не выбрано</option>
                        {directions.length &&
                          directions.map(direction => {
                            return (
                              <option value={`${direction.id}`} key={direction.id}>
                                {direction.name}
                              </option>
                            )
                          })}
                      </Form.Control>
                      {errors.directionId && <div style={{ color: '#ff0000' }}>{errors.directionId}</div>}
                    </Form.Group>
                  )}

                  <Form.Group controlId="mentorId">
                    <Form.Label>Выбор ментора</Form.Label>
                    <Form.Control onChange={handleChange} as="select" name="mentorId" value={values.mentorId}>
                      <option value="">Не выбран</option>
                      {mentors.length &&
                        mentors.map(mentor => {
                          return (
                            <option value={`${mentor.id}`} key={mentor.id}>
                              {`${mentor.firstName} ${mentor.lastName}`}
                            </option>
                          )
                        })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="commonRecruitmentChannelName">
                    <Form.Label>Общий Slack канал для групп набора</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      type="text"
                      placeholder="Общий Slack канал для групп набора"
                      value={values.commonRecruitmentChannelName}
                      name="commonRecruitmentChannelName"
                    />
                    {errors.commonRecruitmentChannelName && (
                      <div style={{ color: '#ff0000' }}>{errors.commonRecruitmentChannelName}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="startTraining">
                    <Form.Label>Дата начала обучения</Form.Label>
                    <Form.Control
                      onChange={evt => {
                        const inputValue = evt.target.value
                        if (inputValue) {
                          setFieldValue('startTraining', new Date(inputValue))
                        }
                      }}
                      type="date"
                      name="startTraining"
                      value={date.format(values.startTraining, 'YYYY-MM-DD')}
                    />
                  </Form.Group>
                  <>
                    <Button
                      onClick={() => {
                        setShowDeadlines(!showDeadlines)
                        if (updateDeadlines) {
                          const newCourses = currentCourses
                            .sort((a, b) => a.position - b.position)
                            .map(course => ({
                              courseId: course.id,
                              courseName: course.name,
                              coursePosition: course.position,
                              deadlineDate: '',
                            }))
                          setFieldValue('deadlinesInfo', newCourses)
                          setUpdateDeadlines(false)
                        }
                      }}
                      className={styles['deadlines-button']}
                    >
                      Дедлайны
                    </Button>
                    <div
                      className={classNames(
                        styles.deadlines,
                        showDeadlines ? styles['show-deadlines'] : styles['hide-deadlines']
                      )}
                    >
                      {values.deadlinesInfo.map((deadline: DeadlineCourseDto) => {
                        const dateString =
                          !deadline.deadlineDate || deadline.deadlineDate instanceof String
                            ? ''
                            : date.format(deadline.deadlineDate as Date, 'YYYY-MM-DD')

                        return (
                          <Form.Group controlId={deadline.courseName} key={deadline.courseId}>
                            <Form.Label>Дедлайн Курса: {deadline.courseName}</Form.Label>
                            <Form.Control
                              onChange={evt => {
                                const inputValue = evt.target.value
                                if (inputValue) {
                                  setFieldValue(
                                    'deadlinesInfo',
                                    values.deadlinesInfo.map(item => {
                                      return {
                                        ...item,
                                        deadlineDate:
                                          item.courseId === deadline.courseId
                                            ? new Date(inputValue)
                                            : item.deadlineDate,
                                      }
                                    })
                                  )
                                }
                              }}
                              type="date"
                              name="deadline"
                              value={dateString}
                            />
                          </Form.Group>
                        )
                      })}
                    </div>
                    {errors.deadlinesInfo && <div style={{ color: '#ff0000' }}>{errors.deadlinesInfo}</div>}
                  </>
                  {err && <div style={{ color: 'red' }}>{err}</div>}
                </Form>
              )
            }}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton submit action="assign" onClick={submitRecruitmentForm}>
            Отправить
          </ModalButton>
          <ModalButton action="back" onClick={onHide}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  )
}

export { EditRecruitmentModal }
