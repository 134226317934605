import { useState, useEffect } from 'react'

function formatTimeToHourInterval(time: string): string {
  const [hoursStart = '0', minutesStart] = time.split(':')
  let hoursEnd = hoursStart !== '23' ? String(+hoursStart + 1) : '00'
  if (hoursEnd.length < 2) {
    hoursEnd = `0${hoursEnd}`
  }
  const minutesEnd = minutesStart

  return `${time} - ${hoursEnd}:${minutesEnd}`
}

export const useTransformScheduleToTable = (zoomSchedule: any) => {
  const [tableHeadData, setTableHeadData] = useState<string[]>(Array(8).fill(''))
  const [tableBodyData, setTableBodyData] = useState<(string | string[])[][]>(Array(7).fill(Array(8).fill('')))
  const [tableFootData] = useState<string[]>(Array(8).fill(''))

  useEffect(() => {
    if (Object.keys(zoomSchedule).length) {
      let dateList: string[] | undefined
      const tableMainData = [] as (string | string[])[][]

      const timeList = Object.getOwnPropertyNames(zoomSchedule)

      timeList.forEach((time, rowIdx) => {
        tableMainData[rowIdx] = [formatTimeToHourInterval(time)]
        if (!dateList) {
          dateList = Object.getOwnPropertyNames(zoomSchedule[time])
        }
        dateList.forEach(date => {
          const tableMainDataRow = tableMainData[rowIdx]

          if (tableMainDataRow) {
            tableMainDataRow.push(zoomSchedule[time][date])
          }
        })
      })

      if (dateList) {
        setTableHeadData(['', ...dateList])
      }
      if (tableMainData) {
        setTableBodyData(tableMainData)
      }
    }
  }, [zoomSchedule])

  return { tableHeadData, tableBodyData, tableFootData }
}
