import { AuthService } from '../auth-service'
import RestPaginationService from '../rest-pagination-service'
import ApiService from '../api-service'

class AdminDirectionService extends RestPaginationService {
  constructor() {
    super('/api/admin/direction')
  }

  getMentorsByDirectionId = async dirId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/mentors`
    return ApiService.request(url, queryOption)
  }

  getCoursesByDirectionId = async dirId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/courses`
    return ApiService.request(url, queryOption)
  }

  removeCourseInDirectionByIdAndPos = async (dirId, pos) => {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/course-remove/${pos}`
    return ApiService.request(url, queryOption)
  }

  updatePercent = async (dirId, percent, pos) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/course-set-percent/${pos}/${percent}`
    return ApiService.request(url, queryOption)
  }

  moveCoursePositionUpInDirection = async (dirId, pos) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/course-up/${pos}`
    return ApiService.request(url, queryOption)
  }

  moveCoursePositionDownInDirection = async (dirId, pos) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/course-down/${pos}`
    return ApiService.request(url, queryOption)
  }

  getAvailableCourses = async () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/available-courses`
    return ApiService.request(url, queryOption)
  }

  addCourseInDirection = async (dirId, courId) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/course/${courId}`
    return ApiService.request(url, queryOption)
  }

  loadFreeMentors = async () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/mentors/available`
    return ApiService.request(url, queryOption)
  }

  loadChiefs = async dirId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/chiefs`
    return ApiService.request(url, queryOption)
  }

  loadChiefMentors = async (dirId, chiefId) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/chief/${chiefId}/mentors`
    return ApiService.request(url, queryOption)
  }

  addMentorToChief = async (dirId, mentorId, chiefId) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/mentors/${mentorId}/chief/${chiefId}`
    return ApiService.request(url, queryOption)
  }

  deleteMentorFromChief = async (dirId, mentorId, chiefId) => {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/mentors/${mentorId}/chief/${chiefId}`
    return ApiService.request(url, queryOption)
  }

  getStudentsOnDirectionByMentor = async (dirId, mentorId) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/mentor/${mentorId}/students`
    return ApiService.request(url, queryOption)
  }

  getAllStudentsWithOutDirection = async () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/students/not-in-any-direction`
    return ApiService.request(url, queryOption)
  }

  deleteStudentFormMentor = async (dirId, studentId, mentorId, chiefId) => {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/pay-student/${studentId}/chief/${chiefId}?mentorId=${mentorId}`
    return ApiService.request(url, queryOption)
  }

  addStudentToMentor = async (dirId, studentId, mentorId, chiefId) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/students/${studentId}/chief/${chiefId}/mentor/${mentorId}`
    return ApiService.request(url, queryOption)
  }

  swapMentor = async (dirId, studentId = '', mentorIdNew = '') => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/students/replace?studentId=${studentId}&newMentorId=${mentorIdNew}`
    return ApiService.request(url, queryOption)
  }

  setMinCourseProgressForNextCourseByDirectionId = async (dirId, progress) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${dirId}/minProgress?minPrevCourseProgressForNextCourse=${progress}`
    return ApiService.request(url, queryOption)
  }

  changeFullPassRequiredByCourseId = async (courseId, fullPassRequired) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/course/${courseId}/changeFullPassRequired?fullPassRequired=${fullPassRequired}`
    return ApiService.request(url, queryOption)
  }
}

export default AdminDirectionService
