import React, { useEffect, useState } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import FilterPaginationService, { PaginationParamPair } from 'src/services/filter-pagination-service'
import { RemovedStudentsProjectQueueListWrapper } from './removed-students-project-queue-list/removed-students-project-queue-list-wrapper'
import { AuthService } from '../../../services/auth-service'
import { assertRole } from '../../../utils/assertRole'
import { RoleEnum } from '../../../utils/select-state/RoleEnum'
import { RemovedProjectQueueDto } from '../../../model/project-queue-dto/removed-project-queue-dto'
import { AdminDirectionsService } from '../../../services/admin-services/admin-directions-service'
import { useReactAlert } from '../../../hooks/useReactAlert'
import { PaymentType } from '../../../model/payment-type-enum'

const RemovedStudentsProjectQueuePage: React.FC = () => {
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [currentDirectionId, setCurrentDirectionId] = useState<number | string | null>(null)
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null)

  const { catchErrorAlert } = useReactAlert()
  const currentRole = AuthService.currentUserValue()?.role.name
  assertRole(currentRole)

  const BaseUrlData = {
    [RoleEnum.ADMIN]: `/api/admin/user/student/project/queue/removed`,
    [RoleEnum.MENTOR]: `/api/mentor/user/student/project/queue/removed`,
    [RoleEnum.CHIEF_MENTOR]: `/api/chief/user/student/project/queue/removed`,
  }

  const ProjectQueueService = new FilterPaginationService<RemovedProjectQueueDto>(BaseUrlData[currentRole])

  enum Filters {
    'Direction' = 'Direction',
    'PaymentType' = 'PaymentType',
  }

  const filterPermissions: Record<Filters, RoleEnum[]> = {
    [Filters.Direction]: [RoleEnum.ADMIN],
    [Filters.PaymentType]: [RoleEnum.ADMIN, RoleEnum.MENTOR, RoleEnum.CHIEF],
  }

  const isFilteringPermitted = (filter: Filters, role: RoleEnum) => {
    return filterPermissions[filter].includes(role)
  }

  const paymentTypes = [
    { id: PaymentType.UNTIL_PAID, name: 'Постоплата' },
    { id: PaymentType.REGULAR, name: 'Предоплата' },
    { id: PaymentType.TRIAL, name: 'Пробный период' },
  ]

  useEffect(() => {
    if (isFilteringPermitted(Filters.Direction, currentRole)) {
      AdminDirectionsService.getAll()
        .then(directions => {
          setPaginationParamPairs([
            {
              parameterName: 'directionId',
              parameterDisplayedName: 'Направление',
              parameterValues: [{ name: 'Все', id: null }, ...directions],
            },
            {
              parameterName: 'paymentType',
              parameterDisplayedName: 'Тип оплаты',
              parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
            },
          ])
        })
        .catch(catchErrorAlert)
    } else {
      setPaginationParamPairs([
        {
          parameterName: 'paymentType',
          parameterDisplayedName: 'Тип оплаты',
          parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
        },
      ])
    }
  }, [])

  const isPaymentType = (maybePaymentType: unknown): maybePaymentType is PaymentType | null => {
    return Boolean(maybePaymentType && Object.values<unknown>(PaymentType).includes(maybePaymentType))
  }

  const handleDirectionChange = (paramValue: number | string | null) => {
    setCurrentDirectionId(paramValue)
  }

  const handlePaymentTypeChange = (paramValue: number | string | null) => {
    if (!isPaymentType(paramValue)) {
      return
    }

    setPaymentType(paramValue)
  }

  const CustomPaginationComponent = PaginationComponent<RemovedProjectQueueDto>()
  return (
    <div className="mentor-content">
      <CustomPaginationComponent
        Wrapper={RemovedStudentsProjectQueueListWrapper}
        service={ProjectQueueService}
        paginationParamPairs={paginationParamPairs}
        headerText="Студенты, удаленные из очереди на проект"
        showDateAnalytics
        paginationParamChangeHandlers={{
          directionId: handleDirectionChange,
          paymentType: handlePaymentTypeChange,
        }}
      />
    </div>
  )
}

export default RemovedStudentsProjectQueuePage
