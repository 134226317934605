import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { useReactAlert } from 'src/hooks/useReactAlert'
import Table, { TableCell, TableRow } from '../../../../../table/table'
import { MentorUserCourseService } from '../../../../../../services/mentor-services/mentor-user-course-service'
import Spinner from '../../../../../spinner'
import { TaskNotificationDto } from '../../../../../../model/notification-dto/task-notification-dto'
import { studentHost } from '../../../../../../config'
import styles from './task-notifications-page.module.scss'

enum TableHeaderCell {
  STUDENT,
  SOLUTION_DATE,
  TASK_POSITION,
  TASK_LINK,
}

const headerConfig: TableCell<TableHeaderCell>[] = [
  {
    id: TableHeaderCell.STUDENT,
    content: 'Студент',
  },
  {
    id: TableHeaderCell.SOLUTION_DATE,
    content: 'Дата решения задачи',
  },
  {
    id: TableHeaderCell.TASK_POSITION,
    content: 'Расположение задачи',
  },
  {
    id: TableHeaderCell.TASK_LINK,
    content: 'Ссылка на задачу',
  },
]

const dataConfig: Record<string, Function> = {
  studentFullName: (taskNotification: TaskNotificationDto) => taskNotification.studentFullName,
  answerDate: (taskNotification: TaskNotificationDto) => taskNotification.answerDate,
  courseTaskPosition: (taskNotification: TaskNotificationDto) => {
    return `${taskNotification.courseName} / ${taskNotification.moduleName} / ${taskNotification.chapterName}`
  },
  link: ({ chapterPosition, modulePosition, coursePosition, courseTaskPosition, studentId }: TaskNotificationDto) => (
    <a
      href={`${studentHost}/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}`}
      target="_blank nooffer"
    >
      перейти к проверке
    </a>
  ),
}

const pageContentClass = cn('mentor-section', styles['page-content'])

const mapTaskNotificationDtosToTableRows = (taskNotifications: TaskNotificationDto[]): TableRow[] => {
  return taskNotifications.map(taskNotification => {
    return {
      id: taskNotification.studentId,
      cells: Object.entries(dataConfig).map(([key, getValue]) => ({
        id: key,
        content: getValue(taskNotification),
      })),
    }
  })
}

const TaskNotificationDtosPage = () => {
  const { reactAlert } = useReactAlert()
  const [taskNotifications, setTaskNotificationDtos] = useState<TaskNotificationDto[]>([])
  const [loading, setLoading] = useState<Loading>({} as Loading)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading({
          ...loading,
          taskNotifications: true,
        })

        const currentTaskNotificationDtos = await MentorUserCourseService.getTasksNotification()
        setTaskNotificationDtos(currentTaskNotificationDtos)
      } catch (e) {
        reactAlert.error(e.message)
      } finally {
        setLoading({
          ...loading,
          taskNotifications: false,
        })
      }
    })()
  }, [])

  const tableData = useMemo(() => {
    return mapTaskNotificationDtosToTableRows(taskNotifications)
  }, [taskNotifications])

  const isLoading = useMemo(() => {
    return Object.values(loading).some(status => status)
  }, [loading])

  if (isLoading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <div className="mentor-header">
          <h2>Все уведомления</h2>
        </div>
      </div>

      <div className={pageContentClass}>
        <div className="my-students-content">
          <Table header={headerConfig} data={tableData} />
        </div>
      </div>
    </div>
  )
}

type Loading = {
  taskNotifications: boolean
}

export default TaskNotificationDtosPage
