export class CookieUtil {
  public static get(name: string) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`)
    )
    return matches?.[1] ? decodeURIComponent(matches[1]) : undefined
  }

  public static set(name: string, value: string | null, options: CookieOptions) {
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString()
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value ?? '')}`

    updatedCookie = Object.entries(options).reduce(
      (acc, [optionKey, optionValue]) => `${acc}; ${optionKey}=${optionValue}`,
      updatedCookie
    )

    document.cookie = updatedCookie
  }
}

type CookieOptions = Partial<{
  expires: Date | string
  domain: string
  path: string
  sameSite: 'Strict' | 'None' | 'Lax'
}>
