import React, { useEffect, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import CompletedStudentsList from './completed-students-list'
import { ChiefManagerStudentService } from '../../../../../../services/chief-manager-services/chief-manager-student-service'
import { ChiefManagerService } from '../../../../../../services/chief-manager-services/chief-manager-service'
import { DirectionDto } from '../../../../../../model/direction-model'
import { ManagerDto } from '../../../../../../model/manager-dto'
import { EnrolleeDto } from '../../../../../../model/enrollee-dto/enrollee-dto'
import '../current-students-page/current-students-page.css'
import FilterPaginationService from '../../../../../../services/filter-pagination-service'
import PaginationComponent from '../../../../../hoc/pagination-component'

const CurrentStudentsPage: React.FC = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const [directions, setDirections] = useState<DirectionDto[]>([])
  const [managers, setManagers] = useState<ManagerDto[]>([])

  useEffect(() => {
    ChiefManagerService.getDirections().then((res: DirectionDto[]) => setDirections(res))
    ChiefManagerService.getManagersList().then((res: ManagerDto[]) => setManagers(res))
  }, [])

  const changeEndPreparationDate = (studentId: number, endDate: string): void => {
    ChiefManagerStudentService.changeEndDate({ studentId, endDate })
      .then(() => {
        reactAlert.success('Дата завершения успешно изменена')
      })
      .catch(err => catchErrorAlert(err))
  }
  const userService = new FilterPaginationService<EnrolleeDto>('/api/chiefManager/enroll/passed')
  const CustomPaginationComponent = PaginationComponent<EnrolleeDto, null>()

  return (
    <div className="content">
      <CustomPaginationComponent
        headerText="Студенты завершившие подготовку"
        service={userService}
        Wrapper={CompletedStudentsList}
        WrapperPropsObj={{ changeEndPreparationDate }}
        showDateAnalytics
        paginationParamPairs={[
          {
            parameterName: 'directionId',
            parameterDisplayedName: 'Направление',
            parameterValues: directions,
          },
          {
            parameterName: 'manager',
            parameterDisplayedName: 'Все менеджеры',
            parameterValues: managers.map(item => {
              return { id: item.id, name: item.managerName }
            }),
          },
        ]}
      />
    </div>
  )
}

export default CurrentStudentsPage
