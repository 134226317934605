import { useState, useEffect } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { StudentAnalyticsService } from '../../../../../services/StudentAnalyticsService'
import ChiefUserService from '../../../../../services/chief-services/chief-student-service'
import { AuthService } from '../../../../../services/auth-service'
import { PaymentTypeGetDto } from '../../../../../model/payment-dto/payment-type-get-dto'
import { AnalyticsStudentEntityDto } from '../../../../../model/analytics-dto/analytics-student-entity-dto'
import { AnalyticsMentorsLaconicDto } from '../../../../../model/analytics-dto/analytics-mentors-laconic-dto'
import { RecruitmentDto } from '../../../../../model/recruitment-dto/recruitment-dto'

export const useGetStudentsByMentor = () => {
  const service = new ChiefUserService()
  const [studentsByMentor, handleStudentsByMentor] = useState<AnalyticsStudentEntityDto[]>([])
  const [mentors, handleMentors] = useState([])
  const [countOnPage, handleCountOnPage] = useState<number>(1)
  const [mentorId, handleMentorId] = useState<EntityId>(-1)
  const [page, handlePage] = useState<number>(1)
  const [isLoaded, handleLoad] = useState<boolean>(false)

  const [paymentTypes, setPaymentTypes] = useState<PaymentTypeGetDto[]>([])
  const [currentPaymentType, setCurrentPaymentType] = useState<string>()

  const [recruitments, setRecruitments] = useState<RecruitmentDto[]>([])
  const [currentRecruitmentId, setCurrentRecruitmentId] = useState<number>()

  const [principalId] = useState<number | undefined>(AuthService.currentUserValue()?.id)

  const [sortType, setSortType] = useState<string>()

  useEffect(() => {
    service.getAllPaymentTypes().then(data => {
      setPaymentTypes(data)
    })
  }, [])

  useEffect(() => {
    if (!principalId) {
      return
    }

    service.getAllRecruitmentByChiefId(principalId).then(data => {
      setRecruitments(data)
    })
  }, [])

  function fillStateByData(data: any) {
    const { entities, count } = data
    handleStudentsByMentor(entities)
    handleCountOnPage(count)
    handleLoad(false)
  }

  function handleMentor(id: EntityId | string) {
    handlePage(1)
    handleMentorId(id)
  }

  function handlePaymentType(val: string) {
    handlePage(1)
    setCurrentPaymentType(val)
  }

  function handleRecruitmentId(val: number) {
    handlePage(1)
    setCurrentRecruitmentId(val)
  }

  useEffect(() => {
    handleLoad(true)
    StudentAnalyticsService.getStudentAnalytics(mentorId, currentRecruitmentId, currentPaymentType, 1, sortType).then(
      data => {
        fillStateByData(data)
      }
    )
  }, [sortType])

  useEffect(() => {
    StudentAnalyticsService.getMentors().then((res: any) => {
      const payload = res.map((item: AnalyticsMentorsLaconicDto) => {
        return { id: item.id, firstName: item.firstName, lastName: item.lastName }
      })
      handleMentors(payload)
    })
  }, [])

  const updatePage = () => {
    handleLoad(true)
    StudentAnalyticsService.getStudentAnalytics(mentorId, currentRecruitmentId, currentPaymentType, page).then(data => {
      fillStateByData(data)
    })
  }

  useEffect(() => {
    updatePage()
  }, [currentRecruitmentId, currentPaymentType, mentorId, page])

  return {
    studentsByMentor,
    countOnPage,
    mentors,
    mentorId,
    page,
    isLoaded,
    handleMentor,
    handlePage,
    updatePage,
    paymentTypes,
    currentPaymentType,
    setCurrentPaymentType: handlePaymentType,
    recruitments,
    currentRecruitmentId,
    setCurrentRecruitmentId: handleRecruitmentId,
    sortType,
    setSortType,
  } as const
}
