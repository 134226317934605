import React from 'react'
import { Button, ButtonGroup, FormControl, InputGroup } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { deleteItemByIndex, getIndexByKeyValue } from '../../../../../../../utils/ArraysUtils'
import CourseService from '../../../../../../../services/admin-services/admin-course-service'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'
import './add-task-modal.css'

//TODO: to TS
class AddTaskModal extends React.Component {
  service = new CourseService()

  state = {
    availableTasks: [],
    taskTypeFilter: '',
    taskTitleFilter: '',
    taskTypes: [],
  }

  componentDidMount() {
    this.service.getTaskTypes().then(taskTypes => {
      this.setState({
        taskTypes,
        taskTypeFilter: taskTypes[0],
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { taskTypeFilter, taskTitleFilter } = this.state
    const { catchErrorAlert } = this.props

    if (taskTypeFilter !== prevState.taskTypeFilter || taskTitleFilter !== prevState.taskTitleFilter) {
      this.service
        .getAllAvailableTask(taskTitleFilter, taskTypeFilter)
        .then(tasks =>
          this.setState({
            availableTasks: tasks,
          })
        )
        .catch(error => catchErrorAlert(error))
    }
  }

  onEditFilter = ({ target: { value } }) => {
    this.setState({
      taskTitleFilter: value,
    })
  }

  onSelectTypeFilter = ({ target: { value } }) => {
    this.setState({
      taskTypeFilter: value,
    })
  }

  render() {
    ReactModal.setAppElement('#root')
    const { availableTasks, taskTitleFilter, taskTypeFilter, taskTypes } = this.state
    const { show, onClose, onAddTask, setModalStatus } = this.props
    const availableTasksRender = availableTasks.map(({ id, title, type, points }) => (
      <li className="list-group-item" key={id}>
        <div className="input-group">
          <div className="col-sm-6 text-left">
            <p>{title}</p>
            <span className="label label-info">
              type: {type}; points: {points}
            </span>
          </div>
          <div className="col-sm-6 text-right">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  onAddTask(id)
                  this.setState({
                    availableTasks: deleteItemByIndex(availableTasks, getIndexByKeyValue(availableTasks, 'id', id)),
                  })
                }}
              >
                <span className="bi bi-plus" />
              </Button>
              <a href={`/admin/tasks/${type}/edit/${id}`} className="btn btn-outline-secondary">
                <span className="bi bi-pencil" />
              </a>
            </ButtonGroup>
          </div>
        </div>
      </li>
    ))
    return (
      <ReactModal
        isOpen={show}
        style={customStyles}
        // if we receive setModalStatus props then we set it to EDIT and also close the modal
        onRequestClose={() => (setModalStatus ? setModalStatus('EDIT') : onClose())}
      >
        <ModalWrapper>
          <ModalHeader>Выберите задачу для добавления</ModalHeader>
          <ModalContent>
            <div className="col">
              <div className="row" style={{ margin: '0' }}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="bi bi-search" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="mr-2"
                    value={taskTitleFilter}
                    type="text"
                    onChange={this.onEditFilter}
                    placeholder="Search"
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="bi bi-card-list" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl as="select" onChange={this.onSelectTypeFilter} value={taskTypeFilter}>
                    {taskTypes.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </FormControl>
                </InputGroup>
              </div>
              <ul className="list-group tasks-list">{availableTasksRender}</ul>
            </div>
          </ModalContent>
          <ModalFooter>
            {setModalStatus ? (
              <ModalButton action="back" onClick={() => setModalStatus('EDIT')}>
                Назад
              </ModalButton>
            ) : (
              <ModalButton action="cancel" onClick={onClose}>
                Закрыть
              </ModalButton>
            )}
          </ModalFooter>
        </ModalWrapper>
      </ReactModal>
    )
  }
}

export default withReactAlert(AddTaskModal)
