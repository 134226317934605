import React from 'react'
import './mentor-planning-reviews-table.css'
import MentorPlanningReviewsTableRow from './planning-reviews-table-row'

const MentorPlanningReviewsTable = React.memo(function MentorPlanningReviewsTable({
  selectedDates,
  setSelectedTime,
  setSelectedReviewDate,
  reviewForWeek,
  setSelectedReviewId,
  setStudentsRegistered,
  curUser,
  setShowEditReviewModal,
  currentReviewType,
  setShowAddReviewModal,
  setDefaultReviewData,
}) {
  const headerDates = selectedDates.map(date => {
    return <th key={date}>{new Date(date).toLocaleDateString()}</th>
  })

  const rows = Object.keys(reviewForWeek)
    .sort()
    .map(reviewTime => {
      const keys = Object.keys(reviewForWeek[reviewTime])
      return (
        <MentorPlanningReviewsTableRow
          reviewTime={reviewTime}
          reviewsInThisTime={reviewForWeek[reviewTime]}
          setSelectedTime={setSelectedTime}
          setSelectedReviewDate={setSelectedReviewDate}
          setSelectedReviewId={setSelectedReviewId}
          setStudentsRegistered={setStudentsRegistered}
          key={keys[0] + reviewTime}
          curUser={curUser}
          setShowEditReviewModal={setShowEditReviewModal}
          currentReviewType={currentReviewType}
          setShowAddReviewModal={setShowAddReviewModal}
          setDefaultReviewData={setDefaultReviewData}
        />
      )
    })

  return (
    <span id="chief-review-table-container">
      <table id="chief-review-table">
        <thead>
          <tr className="chief-review-table-header">
            <th />
            {headerDates}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </span>
  )
})

export default MentorPlanningReviewsTable
