import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthService } from '../../services/auth-service'

const PrivateRoute = ({ component: Component, authRole, ...rest }) => {
  const principal = AuthService.currentUserValue()

  const isAuth = roles => {
    let auth = false
    roles.forEach(current => {
      if (principal && principal.role.name === current) {
        auth = true
      }
    })
    return auth
  }

  return (
    <Route
      {...rest}
      render={props => (isAuth(authRole) ? <Component {...props} /> : <Redirect to="/login?param=forbidden" />)}
    />
  )
}

export default PrivateRoute
