import React from 'react'
import Select from 'react-select'
import './user-form-select.scss'

const UserFormSelect = ({ label, onChange, value, name, options, error, placeholder }) => {
  return (
    <div className="form-group row">
      <div className="col col-centred">
        <label>{label}</label>
        <Select
          name={name}
          value={value}
          className="react-select--editable"
          classNamePrefix="react-select"
          options={options.map(({ id, name: labelName }) => ({ value: id, label: labelName }))}
          onChange={onChange}
          placeholder={placeholder}
        />
        {error && <div className="user-form-select__error-message">{error}</div>}
      </div>
    </div>
  )
}
export default UserFormSelect
