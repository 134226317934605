import React from 'react'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useTranslation } from 'react-i18next'

import { addItem, deleteItemByIndex } from 'src/utils/ArraysUtils'
import { Button } from 'react-bootstrap'
import TableHeaderSorting from '../../../../../shared/table-header-sorting'

enum SortTypes {
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  END_PREPARATION_DATE_ASC = 'END_PREPARATION_DATE_ASC',
  END_PREPARATION_DATE_DESC = 'END_PREPARATION_DATE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}
const tableHeaders = [
  {
    header: 'ID',
  },
  {
    header: 'Студент',
    sortOptions: {
      asc: { action: SortTypes.NAME_ASC, label: 'Имя ↑' },
      desc: { action: SortTypes.NAME_DESC, label: 'Имя ↓' },
    },
  },
  {
    header: 'Почта',
  },
  {
    header: 'Направление подготовки',
    sortOptions: {
      asc: { action: SortTypes.DIRECTION_ASC, label: 'Направление ↑' },
      desc: { action: SortTypes.DIRECTION_DESC, label: 'Направление ↓' },
    },
  },
  {
    header: 'Дата завершения подготовки',
    sortOptions: {
      asc: {
        action: SortTypes.END_PREPARATION_DATE_ASC,
        label: 'Дата окончания подготовки ↑',
      },
      desc: {
        action: SortTypes.END_PREPARATION_DATE_DESC,
        label: 'Дата окончания подготовки ↓',
      },
    },
  },
  {
    header: 'Зарегистрирован в дискорде',
  },
]
const UsersRender: React.FC<Wrapper<UserInfoDto, PayStudentsWrapperProps>> = ({ entitiesArray, WrapperPropsObj }) => {
  return (
    <tbody>
      {entitiesArray?.map(userObj => {
        const { id, fullName, email, preparationDirectionName, endPreparationDate, hasBotRegistration } = userObj
        return (
          <tr key={id}>
            <td>
              <input
                type="checkbox"
                checked={
                  WrapperPropsObj && WrapperPropsObj.selectHandlerObj.selectedIds
                    ? WrapperPropsObj.selectHandlerObj.selectedIds.includes(id)
                    : false
                }
                onChange={e => {
                  if (WrapperPropsObj) {
                    const { selectedIds, setSelectedIds } = WrapperPropsObj.selectHandlerObj
                    if (e.target.checked) {
                      setSelectedIds(addItem(selectedIds, id))
                    } else {
                      setSelectedIds(deleteItemByIndex(selectedIds, selectedIds.indexOf(id)))
                    }
                  }
                }}
              />
            </td>
            <td>{id}</td>
            <td>{fullName}</td>
            <td>{email}</td>
            <td>{preparationDirectionName}</td>
            <td>{endPreparationDate}</td>
            <td>{hasBotRegistration ? 'Да' : 'Нет'}</td>
            <td>
              <button
                onClick={() => {
                  if (WrapperPropsObj) {
                    const { selectedIds, setSelectedIds } = WrapperPropsObj.selectHandlerObj
                    setSelectedIds(addItem(selectedIds, id))
                    WrapperPropsObj.openCloseChooseWindow()
                  }
                }}
                type="button"
                className="btn btn-info btn-sm"
              >
                выбрать поток
              </button>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export interface PayStudentsWrapperProps {
  isRecruitmentOpen: boolean
  openCloseChooseWindow: () => void
  selectHandlerObj: {
    setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
    selectedIds: number[]
  }
  onAddStudents: () => void
}

export type UserInfoDto = {
  id: number
  name: string
  fullName: string
  email: string
  endPreparationDate: string
  preparationDirectionName: string
  hasBotRegistration: boolean
}

const NewPayStudentsWrapper: React.FC<Wrapper<UserInfoDto, PayStudentsWrapperProps>> = ({
  entitiesArray,
  WrapperPropsObj,
  setSortType,
  sortType,
}) => {
  const { t } = useTranslation()
  return (
    <div className="pagination-wrapped">
      <div className="d-flex justify-content-end">
        <Button onClick={WrapperPropsObj?.onAddStudents} variant="outline-primary btn-light">
          <i className="bi bi-plus" /> Добавить студентов
        </Button>
      </div>

      <table className="table table-striped admin-users-table">
        <thead>
          <tr>
            <th />
            {tableHeaders.map(header => (
              <th key={`${header.header}`}>
                <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
              </th>
            ))}
            <th>
              <button
                onClick={() => {
                  if (WrapperPropsObj) {
                    WrapperPropsObj.openCloseChooseWindow()
                  }
                }}
                type="button"
                className="btn btn-info btn-sm"
              >
                выбрать поток
              </button>
            </th>
          </tr>
        </thead>
        <UsersRender entitiesArray={entitiesArray} WrapperPropsObj={WrapperPropsObj} />
      </table>
    </div>
  )
}

export default NewPayStudentsWrapper
