import React, { useRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Modal from 'react-modal'
import { Formik, getIn } from 'formik'
import date from 'date-and-time'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { lightFormat } from 'date-fns'
import ChiefStatisticsService from '../../../../../../../services/chief-services/chief-statistics-service'
import { ModalHeader, ModalContent, ModalButton, customStyles } from '../../../../../../shared/modal-view'
import { StudentToEditDeadline } from '../../../../../../../model/student-dto/student-deadline-dto'

interface StudentDeadlineModal {
  studentToEdit: StudentToEditDeadline | null
  show: boolean
  onHide: () => void
}

const EditDeadlineModal = ({ studentToEdit, onHide, show }: StudentDeadlineModal) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const studentId = studentToEdit ? studentToEdit.studentId : 0
  const studentName = studentToEdit ? studentToEdit.studentName : ''
  const courses = studentToEdit ? studentToEdit.courses.filter(i => !i.personal) : []
  const recruitmentFormRef = useRef<HTMLFormElement>(null)

  const submitRecruitmentForm = () => {
    if (recruitmentFormRef.current) {
      recruitmentFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const deadlinesInfo = courses.map(item => {
    const course = studentToEdit ? studentToEdit.courses.filter(i => i.courseId === item.courseId && i.personal) : []
    if (!course.length) return item
    if (course[0]) return course[0]
    return item
  })

  return (
    <Modal isOpen={show} onRequestClose={onHide} style={customStyles}>
      <ModalHeader>{studentName}</ModalHeader>
      <ModalContent>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            ChiefStatisticsService.changeStudentDeadlines(
              studentId,
              values.deadlinesInfo.filter(i => i.personal)
            )
              .then(() => {
                onHide()
                reactAlert.success('Дедлайны успешно сохранены!')
              })
              .catch(catchErrorAlert)
          }}
          initialValues={{
            studentId,
            studentName,
            deadlinesInfo,
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, errors, status }) => {
            const err = getIn(status, ['apiErrors', 'error'])
            return (
              <Form onSubmit={handleSubmit} ref={recruitmentFormRef}>
                {values.deadlinesInfo.map(
                  ({ courseName, id, deadlineDate, personal, courseId, ...deadlineInfo }, idx) => {
                    const formatDate = () => {
                      const parsedDate = date.parse(deadlineDate, 'DD.MM.YYYY', true)
                      return date.format(parsedDate, 'YYYY-MM-DD')
                    }

                    return (
                      <Form.Group as={Row} className="align-items-center mr-0" controlId={courseName} key={id}>
                        <Form.Label column="lg" sm={5}>
                          Курс: {courseName}
                        </Form.Label>
                        <Col sm={5}>
                          <Form.Control
                            onChange={evt => {
                              const newDeadlineData = [
                                ...values.deadlinesInfo.slice(0, idx),
                                {
                                  ...deadlineInfo,
                                  courseName,
                                  courseId,
                                  personal: true,
                                  deadlineDate: lightFormat(new Date(evt.target.value), 'dd.MM.yyyy'),
                                },
                                ...values.deadlinesInfo.slice(idx + 1),
                              ]
                              setFieldValue('deadlinesInfo', newDeadlineData)
                            }}
                            type="date"
                            name={`deadline-${courseName}`}
                            value={formatDate()}
                          />
                        </Col>
                        {personal ? (
                          <Col sm={2}>
                            <ModalButton
                              action="delete"
                              onClick={() => {
                                const newDeadlineData = values.deadlinesInfo.map(item => {
                                  if (item.id === id) {
                                    return courses.find(i => i.courseId === courseId)
                                  }
                                  return item
                                })

                                setFieldValue('deadlinesInfo', newDeadlineData)
                              }}
                            >
                              <i className="mdi mdi-close" />
                            </ModalButton>
                          </Col>
                        ) : (
                          <Col sm={2} className="ml-5" />
                        )}
                      </Form.Group>
                    )
                  }
                )}
                {err && <div style={{ color: 'red' }}>{err}</div>}
                <Form.Group>
                  <ModalButton action="assign" submit onClick={submitRecruitmentForm}>
                    Отправить
                  </ModalButton>{' '}
                  <ModalButton action="delete" onClick={onHide}>
                    Отменить
                  </ModalButton>
                </Form.Group>
              </Form>
            )
          }}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export { EditDeadlineModal }
