import React from 'react'
import SidebarNavigation from 'src/components/shared/sidebar-navigation'
import SidebarNavigationSection from 'src/components/shared/sidebar-navigation/sidebar-navigation-section'
import styles from '../../../mentor/mentor-page/components/mentor-left-sidebar/sidebar.module.scss'
import { useGetProjectQueueLength } from '../../../../../hooks/useGetProjectQueueLength'

const ChiefLeftSidebar = () => {
  const studentProjectQueueLength = useGetProjectQueueLength()

  const chiefLinks = [
    {
      path: 'review/planning', //'planning/reviews'
      name: 'Планирование ревью',
    },
    {
      path: 'review/questions', //'review/questions'
      name: 'Вопросы к ревью',
    },
    {
      path: 'review/analytics', //'review/analytics'
      name: 'Аналитика по студентам',
    },
    {
      path: 'review/recruitment', //'review/recruitment'
      name: 'Наборы',
    },
    {
      path: 'project-queue', //'project-queue'
      name: (
        <>
          Очередь на проект
          {!!studentProjectQueueLength && <span className={styles.count}>{studentProjectQueueLength}</span>}
        </>
      ),
    },
    {
      path: 'removed-project-queue',
      name: 'Завершенная очередь на проект',
    },
    {
      path: 'reports', //'review/reports'
      name: 'Отчёты',
    },
  ]

  return (
    <SidebarNavigation>
      <SidebarNavigationSection routePath="/chief" sectionLinks={chiefLinks} />
    </SidebarNavigation>
  )
}

export default ChiefLeftSidebar
