import { v4 as uuidv4 } from 'uuid'
import { GenericTask } from './generic-service/generic-task-service'
import RestPaginationService from '../../rest-pagination-service'

export interface MultiInputTaskWithItemsDto extends GenericTask {
  items?: MultiInputTaskItemDto[]
}

export interface MultiInputTaskDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean
}

export interface MultiInputTaskItemDto {
  id?: number
  key?: string
  answerText?: string
  questionText?: string
}

export const multiInputTaskPreGetDataInterceptor = (
  val: Partial<MultiInputTaskWithItemsDto>
): Partial<MultiInputTaskWithItemsDto> => {
  return {
    ...val,
    items: val.items?.map(item => {
      return { ...item, key: uuidv4() }
    }),
  }
}

export const multiInputTaskPreSendDataInterceptor = (
  val: Partial<MultiInputTaskWithItemsDto>
): Partial<MultiInputTaskWithItemsDto> => {
  return {
    ...val,
    items: val.items?.map(item => {
      return { ...item, key: undefined }
    }),
  }
}

class MultiInputTaskService extends RestPaginationService {
  constructor() {
    super('/api/admin/task/multiInput')
  }
}

export default MultiInputTaskService
