import React, { useEffect, useRef, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import PaginationService, { PaginationParamPair, ParameterValue } from '../../../services/filter-pagination-service'
import PaginationComponent from '../../hoc/pagination-component'
import ReportsWrapper, { ReportGetDto } from './report-wraper/report-wraper'
import { deleteItemByIndex, getIndexByKeyValue } from '../../../utils/ArraysUtils'
import { RecruitmentServiceInterface } from '../../../services/interfaces/recruitment-service'
import { RecruitmentRoles } from '../../../model/recruitment-dto/recruitment-roles'

export type Props = {
  apiRole: RecruitmentRoles
  service: RecruitmentServiceInterface
}
const ReportsPage: React.FC<Props> = ({ apiRole, service }) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [currentRecruitmentId, setCurrentRecruitmentId] = useState<number | string | null>(null)
  const { current: reportService } = useRef(new PaginationService<ReportGetDto>(`/api/${apiRole.toLowerCase()}/report`))

  useEffect(() => {
    service
      .getRecruitments()
      .then(recruitments => {
        setPaginationParamPairs([
          {
            parameterName: 'recruitmentId',
            parameterDisplayedName: 'Набор',
            parameterValues: [{ name: 'Все', id: null }, ...recruitments],
          },
          {
            parameterName: 'payStudentId',
            parameterDisplayedName: 'Студент',
            parameterValues: [{ name: 'Все', id: null }],
          },
          {
            parameterName: 'prePaid',
            parameterDisplayedName: 'Только предоплата',
            parameterValues: [
              { name: 'Да', id: 'true' },
              { name: 'Нет', id: 'false' },
            ],
          },
        ])
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    if (!currentRecruitmentId) {
      return
    }
    service
      .getRecruitmentStudents(currentRecruitmentId)
      .then(students => {
        const studentsAsParams = students.map(student => {
          const { id, lastName, firstName } = student
          const name = `${lastName} ${firstName}`
          return { id, name } as ParameterValue
        })

        setPaginationParamPairs(params => {
          const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'payStudentId'))
          return [
            ...newParams,
            {
              parameterName: 'payStudentId',
              parameterDisplayedName: 'Студент',
              parameterValues: [{ name: 'Все', id: null }, ...studentsAsParams],
            },
          ]
        })
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [currentRecruitmentId])

  const handleRecruitmentSelect = (paramValue: number | string | null) => {
    if (paramValue !== null) {
      setCurrentRecruitmentId(paramValue)
      return
    }
    setPaginationParamPairs(params => {
      const newParams = deleteItemByIndex(params, getIndexByKeyValue(params, 'parameterName', 'payStudentId'))
      return [
        ...newParams,
        {
          parameterName: 'payStudentId',
          parameterDisplayedName: 'Студент',
          parameterValues: [{ name: 'Все', id: null }],
        },
      ]
    })
  }

  const CustomPaginationComponent = PaginationComponent<ReportGetDto>()

  return (
    <div className="content">
      <CustomPaginationComponent
        Wrapper={ReportsWrapper}
        service={reportService}
        headerText="Все отчеты"
        paginationParamPairs={paginationParamPairs}
        paginationParamChangeHandlers={{
          recruitmentId: handleRecruitmentSelect,
        }}
        showDateAnalytics
      />
    </div>
  )
}
const ReportsPagePaginationComponent = (apiRole: RecruitmentRoles, service: RecruitmentServiceInterface) => () => (
  <ReportsPage apiRole={apiRole} service={service} />
)
export default ReportsPagePaginationComponent
