import React from 'react'
import './planning-review-confirm-remove-review-modal.css'
import Modal from 'react-modal'

const customStyles = {
  content: {
    left: '50%',
    top: '30%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    margin: '44px 0',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    msOverflowX: 'hidden',
    zIndex: 10,
  },
}

const PlanningReviewConfirmRemoveReviewModal = function PlanningReviewConfirmRemoveReviewModal({
  modalShowed,
  onCloseConfirmModal,
  removeReview,
  openEditModal,
  selectedDate,
  selectedTime,
  setDefaultReviewData,
}) {
  return (
    <Modal isOpen={modalShowed} onRequestClose={onCloseConfirmModal} style={customStyles}>
      <div className="planning-review-modal confirm-remove-review-modal">
        <header className="add">
          <h3 id="planning-review-modal-header">Удаление ревью</h3>
        </header>
        <main>
          <label>
            Удаление ревью на дату: {selectedDate} в {selectedTime}
          </label>
          <label>
            Ты уверен, что хочешь удалить данное ревью? Все студенты, которые уже записались, будут выписаны из него
          </label>
        </main>
        <footer>
          <span className="planning-review-modal-group-button">
            <span className="wrap-block">
              <button
                type="button"
                className="delete-button"
                onClick={() => {
                  removeReview()
                  onCloseConfirmModal()
                  setDefaultReviewData()
                }}
              >
                Подтверждаю
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  onCloseConfirmModal()
                  openEditModal()
                }}
              >
                Отменить
              </button>
            </span>
          </span>
        </footer>
      </div>
    </Modal>
  )
}

export default PlanningReviewConfirmRemoveReviewModal
