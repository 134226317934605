import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import MessagesList from './messages-list'
import AdminMessagesService from '../../../../services/admin-services/admin-messages-service'
import { withPagination } from '../../../hoc'

const MessagesPage = () => {
  useSetDocumentTitle('Администратор | Сообщения студентам')
  const service = new AdminMessagesService()
  const PaginationMessages = withReactAlert(
    withPagination(MessagesList, service, 'Сообщения об написаннии комментария', `admin`)
  )
  return <PaginationMessages />
}

export default MessagesPage
