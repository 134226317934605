import React, { useMemo } from 'react'
import { FormControl } from 'react-bootstrap'
import { ReviewType } from 'src/model/review/review-type'
import { DirectionDto } from '../../../../../model/direction-model'
import { CourseDto } from '../../../../../model/courses-dto/course-dto'
import { ModuleDto } from '../../../../../model/module-dto/module-dto'
import { UserInfoDto } from '../../../../../model/user-info-dto'
import { MentorGetDto } from '../../../../../model/mentor-model'

interface IProps {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  onChangeReviewsByDate: () => void

  directions: DirectionDto[]
  selectedDirection: number
  onChangeDirection: (e: React.ChangeEvent<HTMLSelectElement>) => void

  selectedReviewType?: string
  onSelectReviewType?: React.Dispatch<React.SetStateAction<string | undefined>>

  courses?: CourseDto[]
  selectedCourse?: CourseDto
  onChangeCourse?: (e: React.ChangeEvent<HTMLSelectElement>) => void

  modules?: ModuleDto[]
  selectedModule?: ModuleDto
  onChangeModule?: (e: React.ChangeEvent<HTMLSelectElement>) => void

  mentors?: UserInfoDto[] | MentorGetDto[]
  selectedMentor?: UserInfoDto | MentorGetDto
  onChangeMentor?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const AdminPlanningReviewsActionPanel = React.memo<IProps>(function AdminPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  onChangeReviewsByDate,
  directions,
  selectedDirection,
  onChangeDirection,
  selectedReviewType,
  onSelectReviewType,
  selectedCourse,
  courses,
  onChangeCourse,
  modules,
  selectedModule,
  onChangeModule,
  mentors,
  selectedMentor,
  onChangeMentor,
}) {
  const directionsOptions = directions.map(direction => {
    const { id, name } = direction
    return (
      <option key={`direction-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  const reviewTypesOptions = useMemo(() => {
    return Object.values(ReviewType).map(reviewType => {
      const reviewTypeLabel = {
        PERSONAL_MENTOR: 'Персональное',
        GROUP: 'Групповое',
      }

      return (
        <option key={`course-id-${reviewType}${selectedReviewType}`} value={reviewType}>
          {reviewTypeLabel[reviewType]}
        </option>
      )
    })
  }, [selectedReviewType])

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return (
      <option key={`course-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return (
        <option key={`module-id-${id}${selectedCourse?.id}`} value={id}>
          {name}
        </option>
      )
    })
  }, [selectedCourse?.id, modules])

  const mentorsOptions = mentors?.map(mentor => {
    const { id, firstName, lastName } = mentor
    return (
      <option key={`module-id-${id}`} value={id}>
        {`${firstName} ${lastName}`}
      </option>
    )
  })

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  const defaultOption = <option key="defaultOption">-</option>

  return (
    <span className="chief-review-action-panel">
      <label>
        Направление:
        <FormControl
          as="select"
          value={selectedDirection}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeDirection(e)}
          className="review-filter-selector"
        >
          {directionsOptions}
        </FormControl>
      </label>

      <label>
        Групповые ревью за:
        <FormControl
          type="date"
          value={selectedDate.toISOString().substring(0, 10)}
          onChange={onDateChange}
          className="review-filter-selector"
        />
      </label>

      <label>
        Тип ревью:
        <FormControl
          as="select"
          value={selectedReviewType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const newReviewType = e.target.value
            if (onSelectReviewType) {
              onSelectReviewType(
                Object.values(ReviewType).some(reviewType => reviewType === newReviewType) ? newReviewType : undefined
              )
            }
          }}
          className="review-filter-selector"
        >
          {reviewTypesOptions ? [defaultOption, ...reviewTypesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Курс:
        <FormControl
          as="select"
          value={selectedCourse?.id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeCourse && onChangeCourse(e)}
          className="review-filter-selector"
        >
          {coursesOptions ? [defaultOption, ...coursesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Модуль:
        <FormControl
          as="select"
          disabled={!selectedCourse || !modules?.length}
          value={selectedModule?.id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeModule && onChangeModule(e)}
          className="review-filter-selector"
        >
          {modulesOptions ? [defaultOption, ...modulesOptions] : defaultOption}
        </FormControl>
      </label>

      <label>
        Ментор:
        <FormControl
          as="select"
          value={selectedMentor?.id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeMentor && onChangeMentor(e)}
          className="review-filter-selector"
        >
          {mentorsOptions ? [defaultOption, ...mentorsOptions] : defaultOption}
        </FormControl>
      </label>

      <button
        type="button"
        className="btn btn-primary chief-review-action-panel-btn"
        id="chief-review-action-button"
        onClick={onChangeReviewsByDate}
      >
        Запросить план
      </button>
    </span>
  )
})

export default AdminPlanningReviewsActionPanel
