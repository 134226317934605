import React from 'react'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import classNames from 'classnames/bind'
import ReportList from '../report-list/report-list'

import styles from '../reports-page.module.scss'

const cn = classNames.bind(styles)

export type ReportGetDto = {
  id: number
  studentId: number
  text: string
  countHours: number
  firstName: string
  lastName: string
  reportDate: string
}

const ReportsWrapper = ({ entitiesArray }: Wrapper<ReportGetDto>) => {
  return (
    <>
      <div className={cn('report-wrap')}>
        <div className="container">
          <div className={cn('report-list')}>
            <ReportList reports={entitiesArray} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportsWrapper
