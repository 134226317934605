import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import Spinner from 'src/components/spinner'
import { store } from 'react-notifications-component'
import { useGetGeneralStatictics } from './useGetGeneralStatictics'
import CommonTable from '../../../../../../shared/common-table'
import { ReviewCommonStatisticDto } from '../../../../../../../model/review-statistic/review-common-statistic-dto'

interface GeneralInfoPageProps {
  startDate: string | null
  endDate: string | null
  isLoading: boolean
  errorMessage: string | null
  statisticsData: ReviewCommonStatisticDto | null
  directionId: number | null
  courseId: number | null
  moduleId: number | null
  paymentType: string | null
}

const GeneralInfoPage: React.FC<GeneralInfoPageProps> = ({
  startDate,
  endDate,
  isLoading,
  errorMessage,
  statisticsData,
  directionId,
  courseId,
  moduleId,
  paymentType,
}) => {
  useGetGeneralStatictics({ startDate, endDate, directionId, courseId, moduleId, paymentType })
  useEffect(() => {
    if (errorMessage) {
      store.addNotification({
        id: 'generalInfoError',
        message: errorMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
  }, [errorMessage])

  const generalTableBody = statisticsData
    ? [
        ['Количество проведенных ревью', statisticsData.reviewsCount],
        ['Количество менторов проводивших ревью', statisticsData.mentorsCount],
        ['Количество студентов принявших участие в ревью', statisticsData.studentsCount],
        ['Средняя "заполненность" ревью', `${statisticsData.averageOccupancyReview}%`],
        ['Среднее количество попыток на ревью', statisticsData.averageAttemptsCountPerReview],
        ['Количество несостоявшихся ревью', statisticsData.unfinishedReviewsCount],
      ]
    : null

  if (isLoading) {
    return (
      <div className="spinner-wrapper spinner-wrapper_center">
        <Spinner />
      </div>
    )
  }

  return <CommonTable tableBody={generalTableBody} fullWidth />
}

const mapStateToProps = (state: RootState) => ({
  startDate: state.reviewAnalytics.startDate,
  endDate: state.reviewAnalytics.endDate,
  isLoading: state.reviewAnalytics.generalInfo.isLoading,
  errorMessage: state.reviewAnalytics.generalInfo.errorMessage,
  statisticsData: state.reviewAnalytics.generalInfo.data,
  directionId: state.reviewAnalytics.currentDirectionId,
  courseId: state.reviewAnalytics.currentCourseId,
  moduleId: state.reviewAnalytics.currentModuleId,
  paymentType: state.reviewAnalytics.currentPaymentType,
})

export default connect(mapStateToProps)(GeneralInfoPage)
