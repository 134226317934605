import React, { useEffect, useState } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import FilterPaginationService from '../../../../../services/filter-pagination-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { useReactAlert } from '../../../../../hooks/useReactAlert'
import ChiefUserService from '../../../../../services/chief-services/chief-student-service'
import { EditDeadlineModal } from '../StudentAnalytics/StudentAnalyticsTable/EditDeadlineModal'
import { StudentReviewStatisticDto } from '../../../../../model/student-model'
import ChiefStatisticsService from '../../../../../services/chief-services/chief-statistics-service'
import { useGetStudentsByMentor } from '../StudentAnalytics/useGetStudentsByMentor'
import { MentorFullDto } from '../../../../../model/mentor-model'
import StudentAnalyticsReviewModal from '../StudentAnalytics/StudentAnalyticsTable/StudentAnalyticsReviewModal/StudentAnalyticsReviewModal'
import AdditionalCoursesModal from '../StudentAnalytics/StudentAnalyticsTable/AdditionalCoursesModal'
import ChiefAnalyticsTable from './chief-analytics-table'
import { StudentToEditDeadline } from '../../../../../model/student-dto/student-deadline-dto'
import { AnalyticsStudentEntityDto } from '../../../../../model/analytics-dto/analytics-student-entity-dto'

const ChiefAnalyticsPageWithHoc = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const [chosenStudentToEditDeadline, setChosenStudentToEditDeadline] = useState<StudentToEditDeadline | null>(null)
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false)
  const [showAdditionalCourses, setShowAdditionalCourses] = useState<boolean>(false)
  const [selectedStudentId, setSelectedStudentId] = useState<number>(0)
  const [selectedStudentName, setSelectedStudentName] = useState<string>('')
  const [selectedStudentReviewStatistic, setSelectedStudentReviewStatistic] = useState<StudentReviewStatisticDto[]>()

  const ChiefStatisticsPaginationService = new FilterPaginationService<AnalyticsStudentEntityDto>(
    '/api/chief/statistic/student'
  )
  const CustomPaginationComponent = PaginationComponent<AnalyticsStudentEntityDto, null>()
  const service = new ChiefUserService()

  useEffect(() => {
    if (selectedStudentId !== 0) {
      ChiefStatisticsService.getReviewStatisticByStudentId(selectedStudentId).then(reviewStatistic => {
        setSelectedStudentReviewStatistic(reviewStatistic)
      })
    }
  }, [selectedStudentId])

  const getStudentDeadlines = (studentId: number, studentName: string) => {
    ChiefStatisticsService.getStudentDeadlines(studentId).then(courses => {
      setChosenStudentToEditDeadline({
        studentId,
        studentName,
        courses,
      })
    })
  }

  const studentsByMentorData = useGetStudentsByMentor()
  const { mentors, updatePage, paymentTypes, recruitments } = studentsByMentorData

  const formatedMentorsForSort: Array<{ id: number; name: string }> = mentors.map((mentor: MentorFullDto) => ({
    id: mentor.id,
    name: `${mentor.firstName} ${mentor.lastName}`,
  }))

  const directionComplete = (id: number) => {
    service
      .setStudentDirectionCompleteByStudentId(id)
      .then(() => {
        reactAlert.success(`Направление студента успешно завершено`)
        updatePage()
      })
      .catch(err => catchErrorAlert(err))
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <CustomPaginationComponent
          Wrapper={ChiefAnalyticsTable}
          service={ChiefStatisticsPaginationService}
          headerText="Аналитика по студентам"
          WrapperPropsObj={{
            onDirectionComplete: directionComplete,
            onStudentSelect: setSelectedStudentId,
            onShowReviewStatisticModal: setShowReviewModal,
            setShowAdditionalCourses,
            setSelectedStudentName,
            setChosenStudentToEditDeadline,
            getStudentDeadlines,
          }}
          paginationParamPairs={[
            {
              parameterName: 'recruitmentId',
              parameterDisplayedName: 'Набор',
              parameterValues: [{ name: 'Все', id: null }, ...recruitments],
            },
            {
              parameterName: 'paymentType',
              parameterDisplayedName: 'Тип оплаты',
              parameterValues: [{ name: 'Все', id: null }, ...paymentTypes],
            },
            {
              parameterName: 'mentorId',
              parameterDisplayedName: 'Ментор',
              parameterValues: [{ name: 'Все', id: null }, ...formatedMentorsForSort],
            },
          ]}
        />
        <StudentAnalyticsReviewModal
          modalShowed={showReviewModal}
          onClose={() => setShowReviewModal(false)}
          selectedStudentReviewStatistic={selectedStudentReviewStatistic}
        />
        {showAdditionalCourses && (
          <AdditionalCoursesModal
            studentId={selectedStudentId}
            studentName={selectedStudentName}
            showAdditionalCourses={showAdditionalCourses}
            setShowAdditionalCourses={setShowAdditionalCourses}
            role={RoleEnum.CHIEF_MENTOR}
          />
        )}
        <EditDeadlineModal
          show={!!chosenStudentToEditDeadline}
          onHide={() => {
            setChosenStudentToEditDeadline(null)
          }}
          studentToEdit={chosenStudentToEditDeadline}
        />
      </div>
    </div>
  )
}

export default ChiefAnalyticsPageWithHoc
