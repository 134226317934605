import React, { FC } from 'react'
import { Col, FormLabel, Row } from 'react-bootstrap'
import { Field, FormikProps } from 'formik'
import { FormValues } from '../invite-enrollee-schema-component/invite-enrollee-schema-component'
import { ManagerDto } from '../../../../../model/manager-dto'
import { DirectionDto } from '../../../../../model/direction-model'
import { InviteStudentEnrolleeDto } from '../../../../../model/student-model'
import './invite-enrollee-form.scss'

export interface MainProps {
  email: string
  direction: string
  manager: string
  directions: DirectionDto[]
  managers: ManagerDto[]
  onSubmitInvite: (inviteData: InviteStudentEnrolleeDto) => void
}

const inviteEnrolleeForm: FC<MainProps & FormikProps<FormValues>> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  directions,
  managers,
}): JSX.Element => {
  return (
    <div className="invite-page-content">
      <h1 className="page-header">Приглашение нового абитуриента</h1>
      <div className="invite-student-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Row className="row-common">
              <Col className="column col-common">
                <label>Email студента:</label>
                <Field type="text" value={values.email} name="email" className="form-control" />
                {errors.email && <div className="error-field">{errors.email}</div>}
              </Col>
              <Col className="column col-common">
                <FormLabel>Направления:</FormLabel>
                <select name="direction" value={values.direction} className="form-control" onChange={handleChange}>
                  <option value="" disabled>
                    Выбери Направление
                  </option>
                  {directions.map(({ id, name }: DirectionDto) => (
                    <option key={`manager${id}`} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
                {errors.direction && <div className="error-field">{errors.direction}</div>}
              </Col>
              <Col className="column col-common">
                <FormLabel>Менеджер:</FormLabel>
                <select name="manager" value={values.manager} className="form-control" onChange={handleChange}>
                  <option value="" disabled>
                    Выбери Менеджера
                  </option>
                  {managers.map(({ id, managerName }: ManagerDto) => (
                    <option key={`manager${id}`} value={id}>
                      {managerName}
                    </option>
                  ))}
                </select>
                {errors.manager && <div className="error-field">{errors.manager}</div>}
              </Col>
            </Row>
          </div>
          <div className="form-group">
            <button className="btn btn-success" type="submit" disabled={isSubmitting}>
              Пригласить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default inviteEnrolleeForm
