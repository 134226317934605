import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from './TableHeaderSorting.module.scss'

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export type TTableHeader = {
  header: string
  sortOptions?: TSortOptions
}
export type TSortOptions = {
  asc: TSortOption
  desc: TSortOption
}
type TSortOption = {
  action: string
  label: string
}
type TableHeaderSortingProps = {
  header: TTableHeader
  sortType: string | undefined
  setSortType?: (sortType: string) => void
}
const TableHeaderSorting: React.FC<TableHeaderSortingProps> = ({ header, setSortType, sortType }) => {
  const [isSortableHeader, setIsSortableHeader] = useState<boolean>(true)

  useEffect(() => {
    if (header.sortOptions && sortType) {
      setIsSortableHeader([header.sortOptions.asc.action, header.sortOptions.desc.action].includes(sortType))
    }
  }, [sortType, header])

  const sortIconClass = classNames({
    mdi: true,
    'mdi-arrow-up-bold': sortType && sortType.endsWith(SortOrder.ASC) && isSortableHeader,
    'mdi-arrow-down-bold': sortType && sortType.endsWith(SortOrder.DESC) && isSortableHeader,
    'mdi-sort': !sortType || !isSortableHeader,
  })

  const getNextAccDescType = (): string => {
    if (sortType && sortType.endsWith(SortOrder.DESC) && isSortableHeader) {
      return SortOrder.ASC
    }
    if (sortType && sortType.endsWith(SortOrder.ASC) && isSortableHeader) {
      return ''
    }
    return SortOrder.DESC
  }

  const getNextSortTypeTitle = (sortOptions: TSortOptions): string => {
    if (getNextAccDescType() === SortOrder.ASC) return sortOptions.asc.label
    if (getNextAccDescType() === SortOrder.DESC) return sortOptions.desc.label
    return 'Сбросить сортировку'
  }
  const onClickHandler = () => {
    if (header.sortOptions && setSortType) {
      const {
        asc: { action: ascAction },
        desc: { action: descAction },
      } = header.sortOptions
      const nextSortType = getNextAccDescType()
      if (nextSortType) {
        const orderType = nextSortType === SortOrder.ASC ? ascAction : descAction
        setSortType(orderType)
      } else {
        setSortType(nextSortType)
      }
    }
  }

  return (
    <>
      {header.sortOptions ? (
        <>
          <button
            className={styles['header-sort-btn']}
            type="button"
            onClick={onClickHandler}
            title={getNextSortTypeTitle(header.sortOptions)}
          >
            {header.header}
            <i className={sortIconClass} />
          </button>
        </>
      ) : (
        header.header
      )}
    </>
  )
}

export default TableHeaderSorting
