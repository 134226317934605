import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import './review-questions-list.css'
import ReactModal from 'react-modal'
import { SortableContainer } from 'react-sortable-hoc'
import ReviewQuestionsListItem from './review-questions-list-item'
import AdminQuestionsService from '../../../../../services/admin-services/admin-question-service'
import ReviewQuestionsHeader from '../review-questions-header'
import Spinner from '../../../../spinner'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'

ReactModal.setAppElement('#root')

class ReviewQuestionsList extends Component {
  questionsService = new AdminQuestionsService()

  state = {
    questions: [],
    modules: [],
    courses: [],
    directions: [],
    selectedModule: {},
    questionsLoaded: true,
    modulesLoaded: true,
    directionsLoaded: true,
    coursesLoaded: true,
    isModalOpen: false,
    isCreate: true,
    isNotFoundCourse: false,
    isNotFoundModule: false,
    question: '',
    answer: '',
    id: null,
  }

  questionIdFieldRef = React.createRef()

  componentDidMount() {
    document.title = 'Администратор | Вопросы к ревью'
    this.updateDirection()
  }

  onChangeSelector = e => {
    const moduleId = e.target.value
    this.setState({ selectedModule: moduleId })
    this.updateQuestionsList(moduleId)
  }

  onChangeSelectorCourse = e => {
    const id = e.target.value
    this.updateModules(id)
  }

  onChangeSelectorDirection = e => {
    const id = e.target.value
    this.updateCourse(id)
  }

  onQuestionLoaded = questions => {
    this.setState({
      questions,
      questionsLoaded: false,
    })
  }

  onModulesLoaded = modules => {
    if (modules.length === 0) {
      this.setState({ modulesLoaded: false, isNotFoundModule: true })
      return
    }
    const { id } = modules[0]
    this.setState(
      {
        modules,
        selectedModule: id,
        modulesLoaded: false,
        isNotFoundModule: false,
      },
      () => {
        this.updateQuestionsList(id)
      }
    )
  }

  onCoursesLoaded = courses => {
    if (courses.length === 0) {
      this.setState({ coursesLoaded: false, isNotFoundCourse: true, isNotFoundModule: true })
      return
    }
    const { id } = courses[0]
    this.setState(
      {
        courses,
        coursesLoaded: false,
        isNotFoundCourse: false,
      },
      () => {
        this.updateModules(id)
      }
    )
  }

  onDirectionLoaded = directions => {
    const { id } = directions[0]
    this.setState(
      {
        directions,
        directionsLoaded: false,
      },
      () => this.updateCourse(id)
    )
  }

  openModal = async isCreate => {
    this.setState({ isModalOpen: true, isCreate })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  removeQuestion = id => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedModule } = this.state
    this.questionsService
      .removeQuestionById(id)
      .then(() => {
        reactAlert.success(`Вопрос успешно удален`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  updateQuestion = () => {
    const { catchErrorAlert, reactAlert } = this.props
    const { selectedModule, question: value, id, answer } = this.state
    const question = {
      id,
      value,
      answer,
    }
    this.questionsService
      .updateQuestion(question)
      .then(() => {
        reactAlert.success(`Вопрос успешно обновлен`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  replaceQuestions = ({ oldIndex, newIndex }) => {
    const { catchErrorAlert } = this.props
    const { questions } = this.state
    const first = questions[oldIndex]
    this.questionsService
      .replaceQuestionsPositions(first.id, newIndex + 1)
      .then(newQuestions => {
        this.setState({ questions: newQuestions })
      })
      .catch(err => catchErrorAlert(err))
  }

  createQuestion = () => {
    const { reactAlert, catchErrorAlert } = this.props
    const { selectedModule, answer, question: value } = this.state

    const { questions } = this.state
    const position = questions.length + 1
    const question = {
      value,
      answer,
      position,
    }

    this.questionsService
      .createQuestionByModuleId(question, selectedModule)
      .then(() => {
        reactAlert.success(`Вопрос успешно создан`)
        this.updateQuestionsList(selectedModule)
      })
      .catch(err => catchErrorAlert(err))
  }

  updateQuestionsList = id => {
    this.questionsService.getQuestionsListByModuleId(id).then(this.onQuestionLoaded)
  }

  updateModules = id => {
    this.questionsService.getModulesByCourseId(id).then(this.onModulesLoaded)
  }

  updateCourse = id => {
    this.questionsService.getCoursesByDirectionId(id).then(this.onCoursesLoaded)
  }

  updateDirection = () => {
    this.questionsService.getDirections().then(this.onDirectionLoaded)
  }

  setValueToModal = (id, answer, question) => {
    this.openModal(false).then(() => {
      this.setState({ question })
      this.setState({ answer })
      this.setState({ id })
    })
  }

  render() {
    const {
      questions,
      modules,
      directions,
      directionsLoaded,
      questionsLoaded,
      modulesLoaded,
      isModalOpen,
      isCreate,
      coursesLoaded,
      courses,
      isNotFoundCourse,
      isNotFoundModule,
    } = this.state
    if (questionsLoaded || modulesLoaded || coursesLoaded || directionsLoaded) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    }
    const SortableList = SortableContainer(({ quest }) => {
      return (
        <ol className="list-group list-question mentor-review-questions" style={{ marginBottom: 0 }}>
          {quest.map((question, index) => (
            <ReviewQuestionsListItem
              key={`question-${question.position}`}
              index={index}
              question={question}
              removeQuestion={this.removeQuestion}
              openModal={this.openModal}
              setValueToModal={this.setValueToModal}
            />
          ))}
        </ol>
      )
    })

    const modalHeader = isCreate ? `Добавление теоретического вопроса` : `Изменение теоретического вопроса`
    const modalFunc = isCreate ? this.createQuestion : this.updateQuestion
    return (
      <div className="mentor-content">
        <div className="mentor-header">
          <ReviewQuestionsHeader
            modules={modules}
            courses={courses}
            directions={directions}
            onChangeSelectorCourse={this.onChangeSelectorCourse}
            onChangeSelectorModule={this.onChangeSelector}
            onChangeSelectorDirection={this.onChangeSelectorDirection}
            isNotFoundCourse={isNotFoundCourse}
            isNotFoundModule={isNotFoundModule}
            openModal={this.openModal}
          />
        </div>

        <div className="mentor-section">
          {isNotFoundCourse || isNotFoundModule ? (
            <ol className="mentor-review-questions" />
          ) : (
            <SortableList quest={questions} onSortEnd={this.replaceQuestions} distance={5} />
          )}
          <ReactModal
            isOpen={isModalOpen}
            contentLabel="Добавление вопроса"
            onRequestClose={() => this.closeModal()}
            ariaHideApp
            shouldFocusAfterRender
            shouldCloseOnEsc
            style={customStyles}
          >
            <ModalWrapper>
              <ModalHeader>
                {modalHeader}
                <span id="id-question" ref={this.questionIdFieldRef}>
                  {' '}
                </span>
              </ModalHeader>
              <ModalContent>
                <div className="modal-section">
                  <div className="question-text-header">Текст вопроса</div>
                  <FormControl
                    as="textarea"
                    id="question-area"
                    value={this.state.question}
                    onChange={e => {
                      this.setState({ question: e.target.value })
                    }}
                  />
                  <div className="question-text-header">Текст ответа</div>
                  <FormControl
                    as="textarea"
                    id="question-area"
                    value={this.state.answer}
                    onChange={e => {
                      this.setState({ answer: e.target.value })
                    }}
                  />
                </div>
              </ModalContent>
              <ModalFooter>
                <ModalButton
                  action="assign"
                  onClick={() => {
                    modalFunc()
                    this.closeModal()
                  }}
                >
                  Сохранить
                </ModalButton>
                <ModalButton action="back" onClick={() => this.closeModal()} className="btn btn-danger">
                  Закрыть
                </ModalButton>
              </ModalFooter>
            </ModalWrapper>
          </ReactModal>
        </div>
      </div>
    )
  }
}

export default withReactAlert(ReviewQuestionsList)
