import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import ApiService from '../api-service'
import { serverHost } from '../../config'
import { RecruitmentStudentEntities } from '../../model/recruitment-dto/recruitment-student-entities'
import { RecruitmentServiceDto } from '../../model/recruitment-dto/recruitment-service-dto'
import { AuthService } from '../auth-service'
import { HTTPMethods } from '../http-enums-methods'
import { RecruitmentServiceInterface } from '../interfaces/curator-services/curator-recruitment-service'

const Url = {
  ROOT: `${serverHost}/api/chief/recruitment`,
  GET_RECRUITMENT_STUDENTS: (recruitmentId: number) => `${recruitmentId}/student`,
  EDIT_RECRUITMENT: (recruitmentId: number) => `${recruitmentId}`,
}

const requestRecruitmentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefRecruitmentService: RecruitmentServiceInterface = class ChiefRecruitmentService {
  static createSlackChat = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${Url.ROOT}/${recruitmentId}/slack`
    return ApiService.request<void>(url, queryOption)
  }

  static getRecruitmentStudents = (recruitmentId: number) => {
    return requestRecruitmentController<RecruitmentStudentEntities[]>(Url.GET_RECRUITMENT_STUDENTS(recruitmentId))
  }

  static createRecruitment = (recruitmentData: RecruitmentServiceDto) => {
    const init = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(recruitmentData),
    }
    return requestRecruitmentController('', init)
  }

  static editRecruitment = (recruitmentId: number, recruitmentData: RecruitmentServiceDto) => {
    const init = {
      method: HTTPMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(recruitmentData),
    }
    return requestRecruitmentController(Url.EDIT_RECRUITMENT(recruitmentId), init)
  }

  static getRecruitmentById = (recruitmentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${Url.ROOT}/${recruitmentId}`
    return ApiService.request<RecruitmentDto>(url, queryOption)
  }
}
