import React from 'react'
import { useAlert } from 'react-alert'
import { Button, Card } from 'react-bootstrap'

const DirectionMentorList = ({ mentors, selectChief, onAddMentorToChief }) => {
  const alert = useAlert()

  const mentorsRender = mentors.map(mentor => {
    return (
      <Card key={mentor.id} className="panel panel-info list-content-item">
        <Card.Header className="panel-heading chapter-item">
          <div className="row">
            <div className="col-sm-10">
              <h4>
                {mentor.firstName} {mentor.lastName}
              </h4>
              <p>{mentor.email}</p>
            </div>
            {selectChief && (
              <div className="col-sm-2">
                <Button
                  variant="outline-secondary btn-light"
                  onClick={() => {
                    onAddMentorToChief(mentor)
                    alert.success('Ментор успешно добавлен')
                  }}
                >
                  <span className="mdi mdi-check" />
                </Button>
              </div>
            )}
          </div>
        </Card.Header>
      </Card>
    )
  })
  return <>{mentorsRender}</>
}

export default DirectionMentorList
