import React, { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import AdminUserService from '../../../../../services/admin-services/admin-user-service'
import { PageDto } from '../../../../../model/page-dto'
import { SelectNumberOption } from '../user-payment-type-handler/update-pay-student/UpdatePayStudent'
import { AuthService } from '../../../../../services/auth-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import CuratorUserService from '../../../../../services/curator-services/curator-user-service'
import { RecruitmentDto } from '../../../../../model/recruitment-dto/recruitment-dto'
import '../user-form-select/user-form-select.scss'

type Props = {
  onChange: () => void
  value: SelectNumberOption
  error: string | undefined
  label: string
}
type SelectOptions = {
  options: SelectNumberOption[]
  hasMore: boolean
}

const RecruitmentsInfinitySelect: React.FC<Props> = ({ onChange, value, error, label }) => {
  const currentUserRole = AuthService.currentUserValue()?.role.name
  const service = currentUserRole === RoleEnum.ADMIN ? new AdminUserService() : new CuratorUserService()
  const [page, setPage] = useState<number>(1)

  const loadOptions = async (search: string) => {
    const response = (await service.getRecruitmentsWithPages(search ? 1 : page, `${search}`, 10)) as PageDto<
      RecruitmentDto
    >

    const { count, entities } = response

    const hasMore = page < Math.ceil(count / 10)

    setPage(prevPage => prevPage + 1)

    const selectOptions: SelectOptions = {
      options: entities.length
        ? entities.map(entity => ({ value: entity.id, label: entity.name }))
        : [{ value: 1, label: 'Набор 1' }],
      hasMore,
    }
    return selectOptions
  }
  return (
    <div className="form-group row">
      <div className="col col-centred">
        <label>{label}</label>
        <AsyncPaginate
          className="react-select--editable"
          classNamePrefix="react-select"
          value={value}
          loadOptions={loadOptions}
          onChange={onChange}
          placeholder="Выберете поток"
        />
        {error && <div style={{ color: '#ff0000' }}>{error}</div>}
      </div>
    </div>
  )
}
export default RecruitmentsInfinitySelect
