import React, { useEffect, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'

import PaginationService, { PaginationParamPair, ParameterValue } from 'src/services/filter-pagination-service'
import PaginationComponent from 'src/components/hoc/pagination-component'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import CuratorGroupService from 'src/services/curator-services/curator-group-service'
import NewPayStudentsWrapper, {
  PayStudentsWrapperProps,
  UserInfoDto,
} from './new-paystudent-wrapper/new-paystudent-wrapper'
import RecruitmentComponent from './recruitment-component'
import { DirectionGetDto } from '../../../../../model/direction-model'
import FileUploadModal from '../../../../file-upload-modal'
import StudentsCsvUploadManual from '../../../../students-csv-upload-manual'
import CuratorUserService from '../../../../../services/curator-services/curator-user-service'
import WordUtil from '../../../../../utils/WordUtil'
import UsersFileUploadErrors, { UsersFileUploadErrorEntry } from './users-file-upload-errors'

const curatorUserService = new CuratorUserService()

const NewPayStudentsPage: React.FC<React.ReactElement> = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()

  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>([])
  const [isRecruitmentOpen, setIsRecruitmentOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [isStudentsFileUploadModalShown, setIsStudentsFileUploadModalShown] = useState(false)
  const [isStudentsFileUploading, setIsStudentsFileUploading] = useState(false)
  const [studentsFileUploadErrorEntries, setStudentsFileUploadErrorEntries] = useState<UsersFileUploadErrorEntry[]>([])

  useEffect(() => {
    CuratorDirectionsService.getAll().then((directions: DirectionGetDto[]) => {
      const dirAsParams = directions.map(direction => direction as ParameterValue)
      setPaginationParamPairs([
        {
          parameterName: 'directionId',
          parameterDisplayedName: 'Направление',
          parameterValues: [{ name: 'Любое', id: null }, ...dirAsParams],
        },
      ])
    })
  }, [])
  const groupService = new CuratorGroupService()
  const userService = new PaginationService<UserInfoDto>('/api/curator/student/postpaid')
  const CustomPaginationComponent = PaginationComponent<UserInfoDto, PayStudentsWrapperProps>()

  const openCloseChooseWindow = () => {
    setIsRecruitmentOpen(!isRecruitmentOpen)
  }
  const chooseFlow = (ids: number[], recruitmentId: number) => {
    return groupService
      .groupStudents(ids, recruitmentId)
      .then(() => {
        reactAlert.success('Поток успешно выбран')
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const showStudentsFileUploadModal = () => {
    setIsStudentsFileUploadModalShown(true)
  }

  const hideStudentsFileUploadModal = () => {
    setIsStudentsFileUploadModalShown(false)
  }

  const resetFileUploadModal = () => {
    setStudentsFileUploadErrorEntries([])
  }

  const showStudentsFileUploadingError = (errorsNumber: number) => {
    const STUDENT_TEXT = WordUtil.declineNumeral(errorsNumber, ['студент', 'студента', 'студентов'])

    const NOT_ADDED_TEXT = WordUtil.declineNumeral(errorsNumber, [
      'не был добавлен',
      'не были добавлены',
      'не были добавлены',
    ])

    reactAlert.error(`${errorsNumber} ${STUDENT_TEXT} ${NOT_ADDED_TEXT}`)
  }

  const createStudentsFromFile = async (file: File) => {
    setIsStudentsFileUploading(true)

    try {
      const errors = await curatorUserService.createStudentsFromFile(file)
      const errorsEntries = Object.entries(errors)

      if (errorsEntries.length) {
        showStudentsFileUploadingError(errorsEntries.length)
        setStudentsFileUploadErrorEntries(errorsEntries)
        return
      }

      hideStudentsFileUploadModal()
      reactAlert.success('Студенты успешно добавлены')
    } catch (e) {
      catchErrorAlert(e)
    } finally {
      setIsStudentsFileUploading(false)
    }
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <CustomPaginationComponent
          Wrapper={NewPayStudentsWrapper}
          service={userService}
          headerText="Мои студенты"
          paginationParamPairs={paginationParamPairs}
          WrapperPropsObj={{
            isRecruitmentOpen,
            openCloseChooseWindow,
            selectHandlerObj: { selectedIds, setSelectedIds },
            onAddStudents: showStudentsFileUploadModal,
          }}
          showDateAnalytics
        />
      </div>
      <RecruitmentComponent
        WrapperPropsObj={{ openCloseChooseWindow, chooseFlow, selectedIds, setSelectedIds }}
        isRecruitmentOpen={isRecruitmentOpen}
      />

      <FileUploadModal
        show={isStudentsFileUploadModalShown}
        title="Добавление пользователей"
        description={
          <div>
            <UsersFileUploadErrors errorEntries={studentsFileUploadErrorEntries} />
            <StudentsCsvUploadManual />
          </div>
        }
        isLoading={isStudentsFileUploading}
        confirmButtonText="Добавить"
        confirmButtonLoadingText="Добавляем..."
        onClose={hideStudentsFileUploadModal}
        onConfirm={createStudentsFromFile}
        onExited={resetFileUploadModal}
        onFileChange={resetFileUploadModal}
        acceptedMimetypes={['text/csv']}
      />
    </div>
  )
}

export default NewPayStudentsPage
