import React from 'react'

const UsersFileUploadErrors = ({ errorEntries }: Props) => {
  return errorEntries.length !== 0 ? (
    <div>
      {errorEntries.map(([email, errorMessage]) => (
        <>
          <span>
            {email} - {errorMessage}
          </span>
          <br />
        </>
      ))}
      <br />
    </div>
  ) : null
}

export default UsersFileUploadErrors

type Email = string
type ErrorMessage = string

export type UsersFileUploadErrorEntry = [Email, ErrorMessage]

type Props = {
  errorEntries: UsersFileUploadErrorEntry[]
}
