import RestPaginationService from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class AdminUserService extends RestPaginationService {
  constructor() {
    super('/api/admin/user')
  }

  getPage = async (numberPage, queryString, enabled) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/page/${numberPage}?filterPattern=${queryString}&viewDisabled=${!enabled}`,
      queryOption
    )
  }

  getAllRoles = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/roles`, queryOption)
  }

  //TODO remove duplication method
  getAllPaymentTypesDtos = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/payment_types_dtos`, queryOption)
  }

  getRecruitments = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/payStudent/recruitment`, queryOption)
  }

  getRecruitmentsWithPages = (pageNumber, queryString, items) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/payStudent/recruitment/page/${pageNumber}?filterPattern=${queryString}&items=${items}
    `,
      queryOption
    )
  }

  getStudent = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/student/${studentId}
    `,
      queryOption
    )
  }

  getPayStudent = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/payStudent/${studentId}
    `,
      queryOption
    )
  }

  addStudent = entity => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/student`, queryOption)
  }

  addPaymentStudent = entity => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/payStudent`, queryOption)
  }

  updateStudent = entity => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/student/${entity.id}`, queryOption)
  }

  updatePaymentStudent = entity => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/payStudent/${entity.id}`, queryOption)
  }
}

export default AdminUserService
