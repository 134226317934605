import React, { useEffect, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { FormControl, Accordion, Card, Button } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import ListCoursesInDirection from './list-courses-in-direction'
import AdminDirectionService from '../../../../../services/admin-services/admin-direction-service'
import AdminDirectionTaskService from '../../../../../services/admin-services/admin-direction-task-service'
import ComboBoxCourses from './combo-box-courses'
import DirectionMentorList from './direction-mentor-item-free'
import DirectionChiefItem from './direction-chief-list'
import { addItem, addItemByIndex, deleteItemByIndex, getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import DirectionTasks from './direction-tasks'
import { DirectionService } from '../../../../../services/direction-service'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import './direction-form.css'

const directionPicBlockStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
}

const DirectionForm = () => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const location = useLocation()
  const { directionId = 0 } = useParams()
  const [direction, setDirection] = useState({})
  const [courses, setCourses] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [availableCourses, setAvailableCourses] = useState([])
  const [optionValue, setOptionValue] = useState('')
  const [mentorsAvailable, setMentorsAvailable] = useState([])
  const [chiefs, setChiefs] = useState([])
  const [chiefMentors, setChiefMentors] = useState([])
  const [openedChiefId, setOpenedChiefId] = useState(-1)
  const [selectedChief, setSelectedChief] = useState(-1)
  const [directionTasksAmount, setDirectionTasksAmount] = useState(0)
  const [taskList, setTaskList] = useState([])
  const [isTaskListLoading, setIsTaskListLoading] = useState(false)
  const [selectedLogo, setSelectedLogo] = useState()
  const [minCourseProgress, setMinCourseProgress] = useState(0)

  const service = new AdminDirectionService()

  const onInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleChangeComboBox = e => {
    if (e.target.value !== undefined && e.target.value !== null) {
      setOptionValue(e.target.value)
    }
  }

  const handleMinCourseProgressChange = e => {
    if (e.target.value !== undefined && e.target.value !== null) {
      setMinCourseProgress(e.target.value)
    }
  }

  const amountOfDirTask = () => {
    AdminDirectionTaskService.getAmountOfDirectionTasksByDirectionId(Number(directionId))
      .then(setDirectionTasksAmount)
      .catch(error => catchErrorAlert(error))
  }

  const directionTasksList = () => {
    setIsTaskListLoading(true)
    AdminDirectionTaskService.getDirectionTasksByDirectionId(Number(directionId))
      .then(response => {
        setTaskList(response)
        setIsTaskListLoading(false)
      })
      .catch(error => {
        catchErrorAlert(error)
        setIsTaskListLoading(false)
      })
  }

  const removeTaskByDirId = id => {
    AdminDirectionTaskService.deleteDirectionTaskByDirectionId(Number(id))
      .then(() => {
        reactAlert.success(`Задача успешно удалена`)
        const filteredTasksList = taskList.filter(task => task.directionTaskId !== id)
        setTaskList(filteredTasksList)
      })
      .then(() => amountOfDirTask())
      .catch(err => catchErrorAlert(err))
  }

  const addTaskByDirId = taskId => {
    AdminDirectionTaskService.addDirectionTaskByDirectionId(directionId, taskId)
      .then(() => {
        reactAlert.success('Задача успешно добавлена')
        directionTasksList()
      })
      .then(() => amountOfDirTask())
      .catch(err => catchErrorAlert(err))
  }

  const restorePosition = items => {
    // eslint-disable-next-line no-return-assign
    return items.map((item, index) => ({
      ...item,
      ...(item.directionTaskPosition = index + 1),
    }))
  }

  const onChangeTaskPosition = ({ oldIndex, newIndex }) => {
    const { directionTaskId } = taskList[oldIndex]
    const selectedTask = taskList[oldIndex]
    const newTaskPosition = taskList[newIndex].directionTaskPosition
    if (newIndex !== undefined) {
      AdminDirectionTaskService.changeDirectionTaskPositionById(directionTaskId, newTaskPosition)
        .then(() => {
          const list = restorePosition(addItemByIndex(deleteItemByIndex(taskList, oldIndex), newIndex, selectedTask))
          setTaskList(list)
        })
        .catch(err => catchErrorAlert(err))
    }
  }

  const listMentorsFree = () => {
    service
      .loadFreeMentors()
      .then(setMentorsAvailable)
      .catch(error => catchErrorAlert(error))
  }

  const onUpdate = () => {
    if (directionId > 0) {
      service.getCoursesByDirectionId(directionId).then(setCourses)

      service
        .getById(directionId)
        .then(dir => {
          setDirection(dir)
          setMinCourseProgress(dir.minPrevCourseProgressForNextCourse)
        })
        .catch(error => catchErrorAlert(error))
    }

    service
      .getAvailableCourses()
      .then(response => {
        setAvailableCourses(response)
        setOptionValue(response[0].id)
      })
      .catch(error => catchErrorAlert(error))
  }

  const onUpdatePercent = (dirId, pos) => {
    if (inputValue > 0) {
      service
        .updatePercent(dirId, inputValue, pos)
        .then(() => onUpdate())
        .catch(error => catchErrorAlert(error))
    }
  }

  const listChiefs = () => {
    service
      .loadChiefs(directionId)
      .then(setChiefs)
      .catch(error => catchErrorAlert(error))
  }

  const loadChiefMentors = id => {
    service
      .loadChiefMentors(directionId, id)
      .then(setChiefMentors)
      .catch(error => catchErrorAlert(error))
  }

  const onMoveUp = (dirId, pos) => {
    service
      .moveCoursePositionUpInDirection(dirId, pos)
      .then(() => onUpdate())
      .catch(error => catchErrorAlert(error))
  }

  const onChooseChief = (idx, chiefId) => () => {
    setOpenedChiefId(chiefId)
    setSelectedChief(selectedChief === idx ? -1 : idx)
    setChiefMentors([])
  }

  const onMoveDown = (dirId, pos) => {
    service
      .moveCoursePositionDownInDirection(dirId, pos)
      .then(() => onUpdate())
      .catch(error => catchErrorAlert(error))
  }

  const handlePrint = () => {
    service
      .addCourseInDirection(direction.id, optionValue)
      .then(() => onUpdate())
      .catch(error => catchErrorAlert(error))
  }

  const onDelete = (id, pos) => {
    service
      .removeCourseInDirectionByIdAndPos(id, pos)
      .then(() => onUpdate())
      .catch(error => catchErrorAlert(error))
  }

  const onSetMinCourseProgress = () => {
    service
      .setMinCourseProgressForNextCourseByDirectionId(direction.id, minCourseProgress)
      .then(() => onUpdate())
      .catch(error => catchErrorAlert(error))
  }

  const onAddMentorToChief = mentor => {
    service
      .addMentorToChief(directionId, mentor.id, openedChiefId)
      .then(() => {
        const editedMentorsAvailable = deleteItemByIndex(
          mentorsAvailable,
          getIndexByKeyValue(mentorsAvailable, 'id', mentor.id)
        )
        setMentorsAvailable(editedMentorsAvailable)
        setChiefMentors(addItem(chiefMentors, mentor))
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const onDeleteMentorFromChief = mentor => {
    service.deleteMentorFromChief(directionId, mentor.id, openedChiefId).then(() => {
      const editedChiefMentors = deleteItemByIndex(chiefMentors, getIndexByKeyValue(chiefMentors, 'id', mentor.id))
      setChiefMentors(editedChiefMentors)
      setMentorsAvailable(addItem(mentorsAvailable, mentor))
    })
  }

  const confirmDeleteMentorFromChief = mentor => {
    const fullName = `${mentor.firstName} ${mentor.lastName}`
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите удалить ментора <strong>{fullName}</strong> с направления? Это приведет у тому, что
          все студент ментора потеряют доступ к контенту направления!
        </p>
      </div>
    )
    confirmAlert({
      title: 'Удалить ментора из направления?',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onDeleteMentorFromChief(mentor),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  const onChangeLogo = e => {
    setSelectedLogo(e.target.files[0])
  }

  const onSubmitDirectionLogo = () => {
    const formData = new FormData()
    formData.append('file', selectedLogo)
    // eslint-disable-next-line no-restricted-syntax
    DirectionService.postDirectionsPic(directionId, formData)
      .then(onUpdate)
      .catch(err => catchErrorAlert(err))
  }

  useEffect(() => {
    onUpdate()
    listMentorsFree()
    listChiefs()
    amountOfDirTask()
  }, [])

  useEffect(() => {
    setDirection({})
    onUpdate()
  }, [location])

  useEffect(() => {
    if (openedChiefId !== -1) {
      loadChiefMentors(openedChiefId)
    }
  }, [openedChiefId])

  const { id, name, directionPic, minPrevCourseProgressForNextCourse } = direction

  return (
    <>
      <h1 className="page-header"> Редактирование направления {name}</h1>
      <ListCoursesInDirection
        courses={courses}
        onDelete={onDelete}
        direction={direction}
        onInputChange={onInputChange}
        onUpdatePercent={onUpdatePercent}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        onMandatoryCourseChange={service.changeFullPassRequiredByCourseId}
      />
      <div className="row">
        <div className="col-sm-6">
          <div className="container-outer--white" style={{ marginBottom: '20px' }}>
            <div className="container-inner">
              <div className="form-group form-inline" style={{ marginBottom: 0, ...directionPicBlockStyle }}>
                <img src={convertResponseImageUrl(directionPic)} alt="Direction Logo" width="100" />
                <FormControl type="file" name="logo" onChange={onChangeLogo} />
                <button type="button" className="btn btn-info" onClick={onSubmitDirectionLogo}>
                  Сохранить логотип
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="container-outer--white" style={{ marginBottom: '20px' }}>
            <div className="container-inner">
              <ComboBoxCourses
                availableCourses={availableCourses}
                handleChange={handleChangeComboBox}
                onAddCourseInDirection={handlePrint}
                minPrevCourseProgressForNextCourse={minCourseProgress}
                handleMinCourseProgressChange={handleMinCourseProgressChange}
                onSetMinCourseProgress={onSetMinCourseProgress}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="container-outer--white">
            <DirectionTasks
              taskList={taskList}
              isTaskListLoading={isTaskListLoading}
              directionTasksAmount={directionTasksAmount}
              removeTaskByDirId={removeTaskByDirId}
              addTaskByDirId={addTaskByDirId}
              onChangeTaskPosition={onChangeTaskPosition}
              getDirectionTasksList={directionTasksList}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Accordion>
            <Card>
              <Card.Header className="panel-heading chief-collapse-btn bg-primary">
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <h3>Главные менторы</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0" style={{ visibility: 'visible' }}>
                <Card.Body className="panel-body list-content">
                  <Accordion className="list-group list-content-item">
                    {chiefs.map((chief, chiefIdx) => (
                      <DirectionChiefItem
                        key={chief.id}
                        chief={chief}
                        onChooseChief={onChooseChief(chiefIdx, chief.id)}
                        chiefMentors={selectedChief === chiefIdx ? chiefMentors : []}
                        selectedChief={selectedChief === chiefIdx}
                        onDeleteMentorFromChief={confirmDeleteMentorFromChief}
                        dirId={direction.id}
                        chiefId={chief.id}
                      />
                    ))}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        <div className="col-sm-6">
          <Accordion>
            <Card>
              <Card.Header className="panel-heading chief-collapse-btn bg-primary">
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <h3>Менторы</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0" style={{ visibility: 'visible' }}>
                <Card.Body className="panel-body list-content">
                  <Accordion className="list-group list-content-item">
                    <DirectionMentorList
                      mentors={mentorsAvailable}
                      selectChief={selectedChief !== -1}
                      onAddMentorToChief={onAddMentorToChief}
                    />
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default DirectionForm
