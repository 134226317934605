import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import ExcerptionsList from './excerptions-list'
import { withPagination } from '../../../hoc'
import AdminExcerptionService from '../../../../services/admin-services/admin-excerption-service'

const ExcerptionsPage = () => {
  useSetDocumentTitle('Администратор | Цитаты')
  const service = new AdminExcerptionService()
  const PaginationMessages = withReactAlert(withPagination(ExcerptionsList, service, 'Цитаты', `admin`))
  return <PaginationMessages />
}

export default ExcerptionsPage
