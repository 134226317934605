import React, { Component } from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { Alert } from 'react-bootstrap'
import MentorReviewService from '../../../../../../../services/mentor-services/mentor-review-service'
import Spinner from '../../../../../../spinner'
import CodeReviewHeader from './components/code-review-header/code-review-header'
import StudentTaskBlock from './components/student-task-block'
import './code-review-page.css'

class CodeReviewPage extends Component {
  reviewService = new MentorReviewService()

  state = {
    review: {},
    reviewLoaded: true,
    studentsInfo: [],
  }

  componentDidMount() {
    this.updateReview()
  }

  onReviewLoaded = review => {
    this.setState(
      {
        review,
        reviewLoaded: false,
      },
      () => {
        this.getStudentsInfoByReview()
      }
    )
  }

  getStudentsInfoByReview = () => {
    const {
      review: { studentReviewsDtos },
    } = this.state
    const studentsInfo = []
    studentReviewsDtos.forEach(studentReview => {
      const { studentId, studentName } = studentReview
      studentsInfo.push({
        studentId,
        studentName,
      })
    })

    this.setState({
      studentsInfo,
    })
  }

  finishCodeReview = () => {
    const { history, catchErrorAlert } = this.props
    const {
      review: { reviewId },
    } = this.state
    this.reviewService
      .finishCodeReview(reviewId)
      .then(() => history.push(`/mentor/review/table`))
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  updateReview = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.reviewService.getReviewDtoForCodeReviewById(id).then(this.onReviewLoaded)
  }

  render() {
    const {
      review: { codeHasReviewed },
      review,
      reviewLoaded,
      studentsInfo,
    } = this.state
    const {
      match: {
        params: { id },
      },
    } = this.props

    if (reviewLoaded) {
      return <Spinner />
    }

    const { moduleName } = review

    const studentReviewIdsToStudentIds = review.studentReviewsDtos.map(sr => {
      return {
        id: sr.studentReviewId,
        studentId: sr.studentId,
      }
    })

    const isReviewDataValid = this.state.review.studentReviewsDtos.length

    return (
      <div className="mentor-content">
        <div className="code-review-page-header">
          <h3>Проверка кодовых задач. Модуль: {moduleName}</h3>
          <CodeReviewHeader finishCodeReview={this.finishCodeReview} codeHasReviewed={codeHasReviewed} />
        </div>
        {isReviewDataValid ? (
          <StudentTaskBlock
            reviewId={id}
            studentReviewIdsToStudentIds={studentReviewIdsToStudentIds}
            studentsInfo={studentsInfo}
            submitCommentReview={this.submitCommentReview}
          />
        ) : (
          <div className="code-review-info">
            <Alert variant="warning">Задачи для проверки выбраного вами ревью отсутствуют</Alert>
          </div>
        )}
      </div>
    )
  }
}

export default withReactAlert(CodeReviewPage)
