import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { AuthService } from 'src/services/auth-service'
import ZoomService from 'src/services/zoom-service'
import { useReactAlert } from 'src/hooks/useReactAlert'

const zoomService = new ZoomService()

export const useGetZoomSchedule = () => {
  const { catchErrorAlert } = useReactAlert()
  const [filterDate, setFilterDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [zoomSchedule, setZoomSchedule] = useState({})
  const user = AuthService.currentUserValue()

  const formatDate = (date: string): string =>
    date
      .split('-')
      .reverse()
      .join('.')

  const updateZoomSchedule = (): void => {
    zoomService
      .getBusyAccount(formatDate(filterDate))
      .then(res => setZoomSchedule(res))
      .catch(err => catchErrorAlert(err))
  }

  useEffect(() => {
    updateZoomSchedule()
  }, [user])

  return { filterDate, setFilterDate, zoomSchedule, updateZoomSchedule }
}
