import { EntityId } from '@reduxjs/toolkit'
import { MentorFullDto } from '../model/mentor-model'
import ApiService from './api-service'
import { AuthService } from './auth-service'
import { serverHost } from '../config'
import { getQueryString } from '../utils/getQueryString'
import { StudentAnalyticsServiceInterface } from './interfaces/student-analytics-service'
import { AnalyticsStudentsDto } from '../model/analytics-dto/analytics-students-dto'

const requestStudentAnalyticsController = <T>(url: string, init: RequestInit = {}): Promise<T> => {
  const options: RequestInit = {
    ...init,
    headers: {
      ...init.headers,
      ...AuthService.authHeaders(),
    } as Headers,
  }

  return ApiService.request(url, options)
}

export const StudentAnalyticsService: StudentAnalyticsServiceInterface = class {
  static getStudentAnalytics(
    mentorId: EntityId,
    recruitmentId: number | undefined,
    paymentType: string | undefined,
    page: number,
    sortType: string | null = null
  ) {
    const queryString = getQueryString({
      mentorId: mentorId === -1 ? '' : mentorId,
      recruitmentId: recruitmentId && recruitmentId !== -1 ? recruitmentId : '',
      paymentType,
      sortType,
    })

    return requestStudentAnalyticsController<AnalyticsStudentsDto>(
      `${serverHost}/api/chief/statistic/student/page/${page}?${queryString}`
    )
  }

  static getMentors() {
    return requestStudentAnalyticsController<MentorFullDto>(`${serverHost}/api/chief/mentor`)
  }
}
