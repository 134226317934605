import React from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../shared/modal-view'
import { RecruitmentStudentEntities } from '../../../../../../model/recruitment-dto/recruitment-student-entities'

interface RecruitmentStudentsModalProps {
  studentsRecruitmentId: number | null
  entities: RecruitmentStudentEntities[]
  onHide: () => void
  show: boolean
}

const RecruitmentStudentsModalWindow: React.FC<RecruitmentStudentsModalProps> = ({
  studentsRecruitmentId,
  entities,
  onHide,
  show,
}) => {
  const { t } = useTranslation()
  ReactModal.setAppElement('#root')
  return (
    <>
      <ReactModal isOpen={show} onRequestClose={onHide} style={customStyles}>
        <ModalWrapper>
          <ModalHeader>{`Cтуденты набора #${studentsRecruitmentId !== null ? studentsRecruitmentId : ''}`}</ModalHeader>
          <ModalContent>
            {entities.length > 0 ? (
              <table className="table table-striped admin-users-table">
                <thead>
                  <tr>
                    <td>id</td>
                    <td>email</td>
                    <td>{t('FirstName')}</td>
                    <td>{t('LastName')}</td>
                    <td>{t('hasBotRegistration')}</td>
                  </tr>
                </thead>
                <tbody>
                  {entities.map(user => {
                    const { id, email, lastName, firstName, hasBotRegistration } = user
                    return (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>{email}</td>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{hasBotRegistration ? t('Yes') : t('No')}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <p>в этом потоке пока нет студентов</p>
            )}
          </ModalContent>
          <ModalFooter>
            <ModalButton action="back" onClick={onHide}>
              Назад
            </ModalButton>
          </ModalFooter>
        </ModalWrapper>
      </ReactModal>
    </>
  )
}

export default RecruitmentStudentsModalWindow
