import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import ReportCard from '../report-card'

import styles from './report-list.module.scss'
import { ReportGetDto } from '../report-wraper/report-wraper'

const cn = classNames.bind(styles)

type Props = {
  reports: ReportGetDto[]
}

const ReportList: FC<Props> = ({ reports }) => {
  const { t } = useTranslation()

  const reportsList = reports.map(report => {
    return <ReportCard key={report.id} report={report} />
  })

  return <div className={cn('report-list')}>{reportsList.length ? reportsList : t('NoReportData')}</div>
}

export default ReportList
