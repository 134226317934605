import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { withPagination } from '../../../hoc'
import CourseList from './courses-list'
import CourseService from '../../../../services/admin-services/admin-course-service'
import CourseForm from './course-form'

const CoursesPage = () => {
  useSetDocumentTitle('Администратор | Все курсы')
  const service = new CourseService()
  const PaginationCoursesList = withReactAlert(withPagination(CourseList, service, 'Все курсы'))
  return (
    <div className="admin-content">
      <Switch>
        <Route path="/admin/directions/courses" exact render={props => <PaginationCoursesList {...props} />} />
        <Route
          path="/admin/directions/courses/edit/:courseId"
          exact
          render={props => <CourseForm {...props} callback={service.update} />}
        />
      </Switch>
    </div>
  )
}

export default CoursesPage
