import React from 'react'
import { FormControl } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import RecruitmentsInfinitySelect from '../recruitments-infinity-select'
import UserFormSelect from '../user-form-select'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'

import styles from './user-form.module.scss'

const headerUserTitle = currentRole => {
  switch (currentRole) {
    case RoleEnum.PAY_STUDENT:
      return 'студента по предоплате'
    case RoleEnum.STUDENT:
      return 'студента'
    default:
      return 'пользователя'
  }
}
const UserForm = ({
  validate,
  initialValues,
  roles = [],
  paymentTypes = [],
  directions = [],
  currentRole = '',
  error,
  setCurrentRole = role => {},
  setCurrentValues = values => {},
  user = {},
  onSubmitHandler,
}) => {
  return (
    <>
      <h1 className="page-header">
        {'id' in user ? 'Редактирование ' : 'Создание '}
        {headerUserTitle(currentRole)}{' '}
      </h1>
      <Formik
        enableReinitialize
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues()}
        onSubmit={onSubmitHandler}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col col-centred">
                <label>Email: </label>
                <FormControl
                  type="text"
                  name="email"
                  placeholder="Введите email"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && <div className={styles.error}>{errors.email}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>Пароль: </label>
                <FormControl
                  type="password"
                  name="password"
                  autoComplete="on"
                  placeholder="Новый пароль"
                  onChange={handleChange}
                  value={values.password}
                />
                {errors.password && <div className={styles.error}>{errors.password}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>Имя: </label>
                <FormControl
                  type="text"
                  name="firstName"
                  placeholder="Введите имя"
                  onChange={handleChange}
                  value={values.firstName}
                />
                {errors.firstName && <div className={styles.error}>{errors.firstName}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>фамилия: </label>
                <FormControl
                  type="text"
                  name="lastName"
                  placeholder="Введите фамилию"
                  onChange={handleChange}
                  value={values.lastName}
                />
                {errors.lastName && <div className={styles.error}>{errors.lastName}</div>}
              </div>
            </div>
            {!('id' in user) && (
              <UserFormSelect
                label="Роль:"
                value={values.role}
                options={roles}
                error={errors.role}
                name="role"
                placeholder="Выберите роль"
                onChange={option => {
                  setCurrentRole(option.label)
                  setCurrentValues(values)
                  setFieldValue('role', option)
                }}
              />
            )}
            {currentRole === 'PAY_STUDENT' && (
              <>
                <RecruitmentsInfinitySelect
                  label="Поток:"
                  value={values.recruitmentStudentId}
                  placeholder="Выберите поток"
                  onChange={option => {
                    setFieldValue('recruitmentStudentId', option)
                  }}
                  error={errors.recruitmentStudentId}
                  name="recruitmentStudentId"
                />
                <UserFormSelect
                  label="Тип оплаты:"
                  value={values.paymentType}
                  placeholder="Выберите тип оплаты"
                  options={paymentTypes}
                  error={errors.paymentType}
                  name="paymentType"
                  onChange={option => {
                    setFieldValue('paymentType', option)
                  }}
                />
              </>
            )}
            {currentRole === 'STUDENT' && (
              <>
                <UserFormSelect
                  label="Направление:"
                  value={values.direction}
                  placeholder="Выберите направление"
                  options={directions}
                  error={errors.direction}
                  name="direction"
                  onChange={option => {
                    setFieldValue('direction', option)
                  }}
                />
                <div className="form-group row">
                  <div className="col col-centred">
                    <label>Дата начала подготовки: </label>
                    <FormControl
                      type="date"
                      value={
                        values.startPreparationDate ? values.startPreparationDate.toISOString().substring(0, 10) : ''
                      }
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const inputValue = event.target.value
                        if (inputValue) {
                          setFieldValue('startPreparationDate', new Date(inputValue))
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col col-centred">
                    <label>Дата завершения подготовки: </label>
                    <FormControl
                      type="date"
                      value={values.endPreparationDate ? values.endPreparationDate.toISOString().substring(0, 10) : ''}
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const inputValue = event.target.value
                        if (inputValue) {
                          setFieldValue('endPreparationDate', new Date(inputValue))
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <div className="col col-centred">
                <label>Дата рождения: </label>
                <FormControl
                  type="date"
                  value={values.birthday.toISOString().substring(0, 10)}
                  className="ScheduleSettings__date"
                  onChange={event => {
                    const inputValue = event.target.value
                    if (inputValue) {
                      setFieldValue('birthday', new Date(inputValue))
                    }
                  }}
                />
              </div>
            </div>
            {error !== '' && <div className={styles.error}>{error.text}</div>}
            <div className="form-group row">
              <div className="col col-centred">
                <button type="submit" className="btn btn-success">
                  Сохранить
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UserForm
