import React, { FC, useEffect, useState } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import SimpleLoader from '../../../../../../simple-loader/simple-loader'
import { AdditionalCoursesDto } from '../../../../../../../model/courses-dto/additional-courses-dto'
import { ChiefAdditionalCoursesService } from '../../../../../../../services/chief-services/chief-additional-courses-service'
import Popup from '../../../../../../popup'
import AdditionalCoursesElement from './AdditionalCoursesElement'
import AdditionalCoursesModalAdd from './AdditionalCoursesModalAdd'
import { RoleEnum } from '../../../../../../../utils/select-state/RoleEnum'
import { AdminAdditionalCoursesService } from '../../../../../../../services/admin-services/admin-additional-courses-service'
import { CuratorAdditionalCoursesService } from '../../../../../../../services/curator-services/curator-additional-courses-service'
import { AdminDirectionsService } from '../../../../../../../services/admin-services/admin-directions-service'
import { ChiefDirectionsService } from '../../../../../../../services/chief-services/chief-directions-service'
import { CuratorDirectionsService } from '../../../../../../../services/curator-services/curator-direction-service'
import { AdminCoursesService } from '../../../../../../../services/admin-services/admin-courses-service'
import { ChiefCoursesService } from '../../../../../../../services/chief-services/chief-courses-service'
import { CuratorCoursesService } from '../../../../../../../services/curator-services/curator-courses-service'
import { CoursesServiceInterface } from '../../../../../../../services/interfaces/curator-services/curator-courses-service'
import { AdditionalCoursesServiceInterface } from '../../../../../../../services/interfaces/curator-services/curator-additional-courses-service'
import { DirectionServiceInterface } from '../../../../../../../services/interfaces/direction-service'

export enum ModalStatus {
  EDITING = 'EDITING',
  ADDING = 'ADDING',
}
export interface IAdditionalCourses {
  studentId: number
  studentName: string
  showAdditionalCourses: boolean
  setShowAdditionalCourses: (show: boolean) => void
  role: RoleEnum.CHIEF_MENTOR | RoleEnum.ADMIN | RoleEnum.CURATOR
}

const additionalCoursesServicesMap = {
  [RoleEnum.ADMIN]: AdminAdditionalCoursesService,
  [RoleEnum.CHIEF_MENTOR]: ChiefAdditionalCoursesService,
  [RoleEnum.CURATOR]: CuratorAdditionalCoursesService,
}

const directionsServicesMap = {
  [RoleEnum.ADMIN]: AdminDirectionsService,
  [RoleEnum.CHIEF_MENTOR]: ChiefDirectionsService,
  [RoleEnum.CURATOR]: CuratorDirectionsService,
}

const coursesServicesMap = {
  [RoleEnum.ADMIN]: AdminCoursesService,
  [RoleEnum.CHIEF_MENTOR]: ChiefCoursesService,
  [RoleEnum.CURATOR]: CuratorCoursesService,
}

const AdditionalCoursesModal: FC<IAdditionalCourses> = ({
  studentId,
  studentName,
  showAdditionalCourses,
  setShowAdditionalCourses,
  role,
}: IAdditionalCourses): JSX.Element => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const [additionalCourses, setAdditionalCourses] = useState<AdditionalCoursesDto[] | []>([])
  const [isAdditionalCoursesLoading, setIsAdditionalCoursesLoading] = useState<boolean>(false)
  const [isAddingCourse, setIsAddingCourse] = useState<boolean>(false)

  const additionalCoursesService: AdditionalCoursesServiceInterface = additionalCoursesServicesMap[role]

  const directionsService: DirectionServiceInterface = directionsServicesMap[role]

  const coursesService: CoursesServiceInterface = coursesServicesMap[role]

  const loadCourses = () => {
    setIsAdditionalCoursesLoading(true)
    additionalCoursesService
      .getAdditionalStudentCourses(studentId)
      .then((result: AdditionalCoursesDto[]) => {
        setIsAdditionalCoursesLoading(false)
        setAdditionalCourses((prevState: AdditionalCoursesDto[]) => [...prevState, ...result])
      })
      .catch(error => {
        setIsAdditionalCoursesLoading(false)
        catchErrorAlert(error)
      })
  }

  useEffect(() => {
    loadCourses()
  }, [studentId])

  const removeCourse = (courseId: number): void => {
    additionalCoursesService
      .removeCourseFromUser(courseId, studentId)
      .then(() => {
        setAdditionalCourses(additionalCourses.filter((course: AdditionalCoursesDto) => course.id !== courseId))
        reactAlert.success(`Курс успешно удалён`)
      })
      .catch(err => catchErrorAlert(err))
  }

  const resetCourse = (courseId: number): void => {
    additionalCoursesService
      .resetCourseFromUser(courseId, studentId)
      .then(() => {
        setAdditionalCourses(
          additionalCourses.map((course: AdditionalCoursesDto) => {
            if (course.id === courseId) course.studentPoints = 0
            return course
          })
        )
        reactAlert.success(`Курс успешно обнулён`)
      })
      .catch(err => catchErrorAlert(err))
  }

  const handleIsAddingCoursesModalStatus = (value: boolean) => {
    if (!value) {
      setAdditionalCourses([])
      loadCourses()
    }
    setIsAddingCourse(value)
  }

  return (
    <>
      <Popup
        title={`Список Курсов ${studentName}`}
        isOpened={showAdditionalCourses}
        onClose={() => setShowAdditionalCourses(false)}
      >
        <div className="direction-modal--content content" style={{ marginBottom: '10px' }}>
          {isAdditionalCoursesLoading ? (
            <SimpleLoader />
          ) : (
            <div className="list-wrapper">
              <ul className="list-group list-task">
                {additionalCourses.map(
                  ({ id, name, coursePoints, studentPoints }: AdditionalCoursesDto): JSX.Element => {
                    const progress = Math.floor((studentPoints / coursePoints) * 100)
                    return (
                      <AdditionalCoursesElement
                        key={id}
                        id={id}
                        name={name}
                        progress={progress}
                        modalStatus={ModalStatus.EDITING}
                        resetCourse={resetCourse}
                        removeCourse={removeCourse}
                      />
                    )
                  }
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="modal-actions-inline">
          <button type="button" className="btn-modal btn-modal--success" onClick={() => setIsAddingCourse(true)}>
            Добавить новый курс
          </button>
          <button
            type="button"
            className="btn-modal btn-modal--decline"
            onClick={() => setShowAdditionalCourses(false)}
          >
            Назад
          </button>
        </div>
      </Popup>

      {isAddingCourse && (
        <AdditionalCoursesModalAdd
          directionService={directionsService}
          coursesService={coursesService}
          additionalCoursesService={additionalCoursesService}
          studentId={studentId}
          isAddingCourse={isAddingCourse}
          setIsAddingCourse={handleIsAddingCoursesModalStatus}
        />
      )}
    </>
  )
}

export default AdditionalCoursesModal
