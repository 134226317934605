import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { Wrapper } from '../../../../../../hoc/pagination-component/pagination-component'
import SimpleLoader from '../../../../../../simple-loader/simple-loader'
import { ButtonTypes, lLoadingButtons, RecruitmentTableRenderProps } from '../recruitment-wrapper'

const RecruitmentTableRender: React.FC<Wrapper<RecruitmentDto, RecruitmentTableRenderProps>> = ({
  entitiesArray,
  WrapperPropsObj,
}) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { t } = useTranslation()
  const [isSlackCreating, setSlackCreating] = useState<boolean>(false)
  const [loadingbuttons, setLoadingbuttons] = useState<lLoadingButtons[]>([])

  const ckeckButtonLoading = (id: number): boolean => {
    return !!loadingbuttons.find(element => element.buttonId === id)
  }
  const clearButtonsLoading = (id: number): void => {
    setLoadingbuttons((prevState: lLoadingButtons[]) =>
      prevState.filter((loadedBtn: lLoadingButtons) => loadedBtn.buttonId !== id)
    )
  }

  const createSlackChat = (id: number) => {
    if (WrapperPropsObj) {
      setLoadingbuttons(prev => [...prev, { buttonId: id, buttonType: ButtonTypes.SLACK_CHAT }])
      setSlackCreating(true)
      WrapperPropsObj.createSlackChat(id)
        .then(() => {
          setSlackCreating(false)
          clearButtonsLoading(id)
          if (WrapperPropsObj?.fetchPage) {
            WrapperPropsObj.fetchPage()
          }
          reactAlert.success(`Чат в слаке был успешно создан`)
        })
        .catch(error => {
          clearButtonsLoading(id)
          setSlackCreating(false)
          catchErrorAlert(error)
        })
    }
  }

  return (
    <tbody>
      {entitiesArray?.map(recruitmentObj => {
        const {
          id,
          directionName,
          name,
          startTraining,
          commonRecruitmentChannelName,
          mentorName,
          channelName,
        } = recruitmentObj
        const isButtonLoading = ckeckButtonLoading(id)
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>{name}</td>
            <td>{channelName ?? '-'}</td>
            <td>{commonRecruitmentChannelName}</td>
            {!WrapperPropsObj?.isChief && <td>{directionName}</td>}
            <td>{startTraining}</td>
            <td>{mentorName === null ? 'нет ментора' : mentorName}</td>
            <td>
              <Button
                onClick={() => {
                  document.body.classList.add('direction__model__container')
                  WrapperPropsObj!.setStudentsRecruitmentId(id)
                }}
              >
                {t('ShowStudents')}
              </Button>
            </td>
            <td>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={isSlackCreating && isButtonLoading}
                  className="direction__action__slack"
                  onClick={() => createSlackChat(id)}
                >
                  {isSlackCreating && isButtonLoading && (
                    <div className="container-loader">
                      <SimpleLoader width={20} height={20} />
                    </div>
                  )}
                </Button>
                <Button
                  variant="outline-secondary btn-light"
                  size="sm"
                  onClick={() => {
                    if (WrapperPropsObj) {
                      WrapperPropsObj.setIsEditModalShow(true)
                      WrapperPropsObj.setRecruitmentIdToEdit(recruitmentObj.id)
                    }
                  }}
                >
                  <i className="mdi mdi-pencil" />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export { RecruitmentTableRender }
