import React, { useRef } from 'react'
import { FormControl } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'

const ModuleModal = ({ module, onClose, onSave, show }) => {
  const moduleFormRef = useRef(null)
  const { id, name, description, control } = module
  const alert = useAlert()

  const submitModuleForm = () => {
    if (moduleFormRef.current) {
      moduleFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }
  const validate = ({ nameVal }) => {
    const errors = {}
    if (!nameVal || nameVal === '') {
      errors.name = 'Не заполенено обязательное поле'
    }
    return errors
  }
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Редактирование модуля</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              nameVal: name || '',
              descriptionVal: description || '',
              control,
            }}
            onSubmit={({ nameVal, descriptionVal, control: updatedControl }) => {
              onSave({ id, name: nameVal, description: descriptionVal, control: updatedControl }, () => {
                alert.success('Курс успешно сохранен')
              })
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} ref={moduleFormRef}>
                <div className="form-group">
                  <label>Название модуля</label>
                  <FormControl type="text" name="nameVal" onChange={handleChange} value={values.nameVal} />
                  {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                </div>
                <div className="form-group">
                  <label>Описание модуля</label>
                  <FormControl
                    type="text"
                    name="descriptionVal"
                    onChange={handleChange}
                    value={values.descriptionVal}
                  />
                  {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => submitModuleForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default ModuleModal
