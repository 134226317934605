import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AuthService } from '../../services/auth-service'
import { RoleEnum } from '../../utils/select-state/RoleEnum'
import { studentHost } from '../../config'
import LinkButton from '../link-button'

type Props = {
  renderBreadCrumbs?: boolean
}

const NotFound: React.FC<Props & RouteComponentProps<any>> = ({ renderBreadCrumbs, location }) => {
  const user = AuthService.currentUserValue()

  const infoRoutes = ['/user/contacts', '/user/legal_entity', '/user/public_offer']

  if (infoRoutes.includes(location.pathname)) {
    return <></>
  }

  const renderDescription = () => {
    if (user?.role?.name && [RoleEnum.STUDENT, RoleEnum.PAY_STUDENT].includes(user?.role?.name)) {
      return (
        <div className="not-found-text">
          Вы авторизованы в качестве {user?.role?.name}, для которого не существует указанной страницы. <br />
          Вы можете перейти в <a href={studentHost}>панель студента</a>,{' '}
          <LinkButton onClick={AuthService.logout}>выйти из аккаунта</LinkButton> или скорректировать ссылку вручную.
        </div>
      )
    }

    return (
      <div className="not-found-text">
        К сожалению, запрашиваемая вами страница не найдена. <br />
        Вернитесь на <Link to="/">главную</Link>.
      </div>
    )
  }

  return (
    <>
      {renderBreadCrumbs && (
        <div className="middle-header all-courses">
          <div className="container">
            <div className="page-title-wrap">
              <div className="breadcrumbs">
                <span className="link">
                  <Link to="/">Главная</Link>
                </span>
              </div>
              <div className="page-title">Страница не найдена</div>
            </div>
          </div>
        </div>
      )}
      <div className="simple-page">
        <div className="container">
          <div className="content page-not-found">
            <div className="big-text">404</div>
            <div className="not-found-head">Страница не найдена</div>

            {renderDescription()}
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
