import { getQueryString } from 'src/utils/getQueryString'
import { MentorReviewWeekParams } from 'src/model/review/review-params'
import {
  CodeStudentCourseTaskAnswerDto,
  CodeStudentCourseTaskAnswerDtoForCodeReview,
  MentorTaskReviewCommentDto,
  ReviewDtoForCodeReview,
  StudentCodeTasks,
  StudentReviewCommentDto,
  StudentsDebtsSavingData,
  MentorTaskReviewCommentType,
} from '../../model/mentor-model'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { CourseDto } from '../../model/courses-dto/course-dto'
import { StudentQuestionDto } from '../../model/student-model'
import { HTTPMethods } from '../http-enums-methods'
import { MentorReviewServiceInterface } from '../interfaces/mentor-services/mentor-review-service'
import { UserDto } from '../../model/user-dto/user-dto'
import { ModuleDto } from '../../model/module-dto/module-dto'
import { ReviewForWeekDto } from '../../model/review/review-for-week-dto'
import { ReviewPlanningMentorDto } from '../../model/review/review-planning-mentor-dto'
import { ChiefReviewDto } from '../../model/review/chief-review-dto'
import { ReviewForMentorDto } from '../../model/review/review-for-mentor-dto'
import { MentorReviewRequestDto } from '../../model/review/mentor-review-request-dto'
import { serverHost } from '../../config'

export default class MentorReviewService implements MentorReviewServiceInterface {
  sourceURL = `${serverHost}/api/mentor/review`

  public getReviewById = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<ReviewForMentorDto>(`${this.sourceURL}/get-review/${id}`, queryOption)
  }

  public getReviewDtoForCodeReviewById = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<ReviewDtoForCodeReview>(`${this.sourceURL}/get-code-review/${id}`, queryOption)
  }

  public saveStudentsDebts = (data: StudentsDebtsSavingData) => {
    const { studentsReviewsId, questionsId } = data
    const queryOption = {
      method: HTTPMethods.POST,
      body: `studentsReviewsId=${studentsReviewsId}&questionsId=${questionsId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/theory/questions/save`, queryOption)
  }

  public closeStudentQuestionByStudentReviewId = (studentQuestionId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<void>(`${this.sourceURL}/theory/questions/close/${studentQuestionId}`, queryOption)
  }

  public getAllCodeTaskToStudent = (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<StudentCodeTasks>(`${this.sourceURL}/get-tasks/${studentReviewId}`, queryOption)
  }

  public getAnswerByCourseTaskIdAndStudentId = (courseTaskId: number, studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<CodeStudentCourseTaskAnswerDtoForCodeReview>(
      `${this.sourceURL}/answer/${courseTaskId}/${studentReviewId}`,
      queryOption
    )
  }

  public submitCommentReview = (
    studReviewId: number,
    courseTaskId: number,
    value: string,
    mentorTaskReviewCommentType: MentorTaskReviewCommentType,
    mentorCommentId: number
  ) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      body: JSON.stringify({
        mentorCommentId,
        value,
        mentorTaskReviewCommentType,
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<MentorTaskReviewCommentDto>(
      `${this.sourceURL}/comment/${studReviewId}/${courseTaskId}`,
      queryOption
    )
  }

  public finishTheoryReview = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<void>(`${this.sourceURL}/theory/finish/${id}`, queryOption)
  }

  public finishCodeReview = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<void>(`${this.sourceURL}/code/finish/${id}`, queryOption)
  }

  /**
   * @deprecated Такого эндпоинта не существует на бекенде. Должно быть, имелось ввиду /mentor
   */
  public getAllMentors = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<UserDto[]>(`${this.sourceURL}/mentors`, queryOption)
  }

  /**
   * @deprecated Такого эндпоинта не существует на бекенде. Должно быть, имелось ввиду /course
   */
  public getAllCourse = (mentorId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseDto[]>(`${this.sourceURL}/courses/${mentorId}`, queryOption)
  }

  public getModulesByCourseId = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleDto[]>(`${this.sourceURL}/modules/${courseId}`, queryOption)
  }

  public getAvailableToReviewStudents = (moduleId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<UserDto[]>(`${this.sourceURL}/students/${moduleId}`, queryOption)
  }

  /**
   * @deprecated Такого эндпоинта не существует на бекенде
   * @todo Описать типы, когда станет понятно, что это за эндпоинт
   */
  // @ts-ignore
  public addReview = (time, mentorId, moduleId, usersId) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: `time=${time}&mentorId=${mentorId}&moduleId=${moduleId}&usersId[]=${usersId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/add`, queryOption)
  }

  public getOpenedStudentQuestionsByStudentReviewId = (studentReviewId: number) => {
    const queryOption = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<StudentQuestionDto[]>(
      `${this.sourceURL}/theory/all/opened/student/question?studentReviewId=${studentReviewId}`,
      queryOption
    )
  }

  public setTheoryStudentReviewCompleteByStudentReviewId = (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/theory/student/complete/${studentReviewId}`, queryOption)
  }

  public setTheoryStudentReviewMissedByStudentReviewId = (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/theory/student/miss/${studentReviewId}`, queryOption)
  }

  public resetTheoryStudentReviewCompleteByStudentReviewId = (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/theory/student/reset/${studentReviewId}`, queryOption)
  }

  public getReviewReportByReviewIdAndStudentReviewId = (reviewId: number, studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/report/${reviewId}/${studentReviewId}`, queryOption)
  }

  public updateReviewStudentsByReviewId = (ids: number[], reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      body: `reviewId=${reviewId}&usersIds=${ids}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/update`, queryOption)
  }

  public discardReview = (moduleId: number, studentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(
      `${this.sourceURL}/discardReview?moduleId=${moduleId}&studentId=${studentId}`,
      queryOption
    )
  }

  public assignReview = (reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${reviewId}`, queryOption)
  }

  public refuseReview = (reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${reviewId}`, queryOption)
  }

  public getStudentReviewCommentDtos = (studentReviewId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<StudentReviewCommentDto[]>(
      `${this.sourceURL}/student-review/${studentReviewId}/comment`,
      queryOption
    )
  }

  public creatOrUpdateStudentReviewComment = (studentReviewId: number, comment: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: `comment=${comment}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<StudentReviewCommentDto[]>(
      `${this.sourceURL}/student-review/${studentReviewId}/comment`,
      queryOption
    )
  }

  public deleteStudentReviewCommentByStudentReviewId = (studentReviewId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<StudentReviewCommentDto[]>(
      `${this.sourceURL}/student-review/${studentReviewId}/comment`,
      queryOption
    )
  }

  public sendLinkToChannels = (reviewId: number, link: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: link,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${reviewId}/start`, queryOption)
  }

  // методы для работы со старыми вопросами на ревью
  public getAllOldQuestionByStudentsReviewId = (studentReviewId: number) => {
    const queryOption = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<StudentQuestionDto[]>(
      `${this.sourceURL}/theory/all/opened/student/old_question?studentReviewId=${studentReviewId}`,
      queryOption
    )
  }

  public setOldStudentQuestionStatusSuccess = (studentReviewId: number, studentQuestionId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(
      `${this.sourceURL}/theory/student/old_question/success?studentReviewId=${studentReviewId}&studentQuestionId=${studentQuestionId}`,
      queryOption
    )
  }

  public setOldStudentQuestionStatusFailed = (studentReviewId: number, studentQuestionId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(
      `${this.sourceURL}/theory/student/old_question/failed?studentReviewId=${studentReviewId}&studentQuestionId=${studentQuestionId}`,
      queryOption
    )
  }

  public getReviewForWeekByType = (params: MentorReviewWeekParams) => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ReviewForWeekDto>(`${this.sourceURL}/review?${urlParams}`, queryOption)
  }

  public getMentorsForWeek = (params: MentorReviewWeekParams) => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ReviewPlanningMentorDto[]>(`${this.sourceURL}/mentorDto?${urlParams}`, queryOption)
  }

  public getCourses = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseDto[]>(`${this.sourceURL}/course`, queryOption)
  }

  public getMentors = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<UserDto[]>(`${this.sourceURL}/mentor`, queryOption)
  }

  public getChiefReviewDtoByReviewId = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ChiefReviewDto>(`${this.sourceURL}/review/${id}`, queryOption)
  }

  public updateReviewByReviewId = (data: MentorReviewRequestDto, reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/${reviewId}`, queryOption)
  }

  public createReview = (data: MentorReviewRequestDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}`, queryOption)
  }

  deleteReviewById = (reviewId: number) => {
    const queryOption = {
      method: 'DELETE',
      headers: {
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/${reviewId}/personal`, queryOption)
  }

  public getUsersTaskSolutions = (courseTaskId: number, studentId: number) => {
    const url = `${this.sourceURL}/${courseTaskId}/${studentId}/solutions`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CodeStudentCourseTaskAnswerDto[]>(url, queryOption)
  }

  public deleteMentorCommentById = (mentorCommentId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/comment/${mentorCommentId}`, queryOption)
  }
}
