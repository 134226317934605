import React from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import RecruitmentService from 'src/services/filter-pagination-service'
import ReactModal from 'react-modal'
import RecruitmentComponentWrapper from '../recruitment-component-wrapper'
import {
  RecruitmentWrapperProps,
  RecruitmentEntities,
} from '../recruitment-component-wrapper/recruitment-component-wrapper'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../shared/modal-view'

interface RecruitmentComponentProps {
  WrapperPropsObj: RecruitmentWrapperProps
  isRecruitmentOpen: boolean
}

const RecruitmentComponent: React.FC<RecruitmentComponentProps> = ({ WrapperPropsObj, isRecruitmentOpen }) => {
  const recruitmentService = new RecruitmentService<RecruitmentEntities>('/api/curator/recruitment')
  const CustomPaginationComponent = PaginationComponent<RecruitmentEntities, RecruitmentWrapperProps>()

  const { openCloseChooseWindow } = WrapperPropsObj

  ReactModal.setAppElement('#root')

  return (
    <ReactModal
      isOpen={isRecruitmentOpen}
      onRequestClose={() => {
        openCloseChooseWindow()
      }}
      ariaHideApp
      shouldFocusAfterRender
      shouldCloseOnEsc
      style={customStyles}
    >
      <ModalWrapper>
        <ModalHeader>Доступные потоки</ModalHeader>
        <ModalContent>
          <CustomPaginationComponent
            Wrapper={RecruitmentComponentWrapper}
            paginationParamPairs={[]}
            service={recruitmentService}
            headerText=""
            WrapperPropsObj={WrapperPropsObj}
            showDateAnalytics
          />
        </ModalContent>
        <ModalFooter>
          <ModalButton
            action="back"
            onClick={() => {
              openCloseChooseWindow()
            }}
          >
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default RecruitmentComponent
