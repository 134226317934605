import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import ReviewStepTaskService from '../../../../services/admin-services/tasks/review-step-task-service'
import ReviewStepTaskForm from './review-step-task-form'
import ReviewStepTaskList from './review-step-task-list'
import { withPagination } from '../../../hoc/index'

const service = new ReviewStepTaskService()

class ReviewStepTaskPage extends Component {
  componentDidMount() {
    document.title = 'Администратор | Шаг для записи на ревью'
  }

  render() {
    const PaginationReviewStepTasksList = withReactAlert(
      withPagination(ReviewStepTaskList, service, 'Шаг для записи на ревью')
    )
    return (
      <div className="admin-content">
        <Switch>
          <Route
            path="/admin/tasks/review-step-task/"
            exact
            render={props => <PaginationReviewStepTasksList {...props} />}
          />
          <Route
            path="/admin/tasks/review-step-task/add"
            exact
            render={props => <ReviewStepTaskForm {...props} taskId={0} callback={service.add} />}
          />
          <Route
            path="/admin/tasks/review-step-task/edit/:taskId"
            exact
            render={props => <ReviewStepTaskForm {...props} callback={service.update} />}
          />
        </Switch>
      </div>
    )
  }
}

export default ReviewStepTaskPage
