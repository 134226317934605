import React from 'react'
import { FormControl, Form } from 'react-bootstrap'
import './combo-box-courses.css'
import { useAlert } from 'react-alert'

const ComboBoxCourses = ({
  availableCourses,
  handleChange,
  onAddCourseInDirection,
  minPrevCourseProgressForNextCourse,
  handleMinCourseProgressChange,
  onSetMinCourseProgress,
}) => {
  const alert = useAlert()
  const courses = availableCourses.map(course => {
    const { id, name } = course
    return (
      <option key={`course-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  return (
    <div className="review-questions-container">
      <div className="review-questions-field">
        <FormControl as="select" onChange={handleChange} id="course-id-select">
          {courses}
        </FormControl>
        <button
          type="button"
          className="btn btn-info review-questions-button"
          id="add-course-button"
          onClick={() => {
            onAddCourseInDirection()
            alert.success('Курс успешно добавлен в направление')
          }}
        >
          Добавить
        </button>
      </div>
      <div className="review-questions-field">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="review-question-wrapper">
              <Form.Control
                type="number"
                min={0}
                max={100}
                class="review-questions-input-range"
                value={minPrevCourseProgressForNextCourse}
                onChange={handleMinCourseProgressChange}
              />
              <Form.Label class="review-questions-label-range ml-3">Прогресс для доступа к следующему курсу</Form.Label>
            </div>
          </Form.Group>
          <button
            type="button"
            className="btn btn-info"
            id="add-course-button"
            onClick={() => {
              onSetMinCourseProgress()
              alert.success('Минимальный прогресс для доступа к следующему курсу успешно изменен')
            }}
          >
            Сохранить
          </button>
        </Form>
      </div>
    </div>
  )
}

export default ComboBoxCourses
