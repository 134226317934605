import React, { Component } from 'react'
import './review-questions-header.css'

class ReviewQuestionsHeader extends Component {
  render() {
    const {
      modules,
      onChangeSelectorModule,
      openModal,
      courses,
      onChangeSelectorCourse,
      onChangeSelectorDirection,
      directions,
      isNotFoundCourse,
      isNotFoundModule,
    } = this.props

    const modulesList = modules.map(module => {
      const { id, name } = module
      return (
        <option key={`module-id-${id}`} value={id}>
          {name}
        </option>
      )
    })

    const coursesList = courses.map(course => {
      const { id, name } = course
      return (
        <option key={`course-id-${id}`} value={id}>
          {name}
        </option>
      )
    })

    const directionList = directions.map(direction => {
      const { id, name } = direction
      return (
        <option key={`direction-id-${id}`} value={id}>
          {name}
        </option>
      )
    })

    const checkIsNotFound = () => {
      return isNotFoundCourse || isNotFoundModule
    }

    return (
      <div className="review-questions-header">
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          className="btn btn-info"
          id="add-question-button"
          onClick={() => openModal(true)}
          disabled={checkIsNotFound()}
        >
          Добавить
        </button>
        <select onChange={event => onChangeSelectorDirection(event)} id="course-id-select" className="form-control">
          {directionList}
        </select>
        <select onChange={event => onChangeSelectorCourse(event)} id="course-id-select" className="form-control">
          {isNotFoundCourse ? (
            <option key="course-id-0" value="0">
              {' '}
              --{' '}
            </option>
          ) : (
            coursesList
          )}
        </select>
        <select onChange={event => onChangeSelectorModule(event)} id="module-id-select" className="form-control">
          {isNotFoundModule ? (
            <option key="module-id-0" value="0">
              {' '}
              --{' '}
            </option>
          ) : (
            modulesList
          )}
        </select>
      </div>
    )
  }
}

export default ReviewQuestionsHeader
