import React, { Dispatch, SetStateAction, useEffect } from 'react'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import produce from 'immer'
import ReactMarkdown from 'react-markdown'
import 'react-markdown-editor-lite/lib/index.css'

import MdEditor from 'react-markdown-editor-lite'
import { FormControl } from 'react-bootstrap'
import {
  MultiInputTaskWithItemsDto,
  MultiInputTaskItemDto,
} from '../../../../services/admin-services/tasks/multi-input-task-service'
import classes from './multi-test-task.module.scss'
import isNullish from '../../../../utils/isNullish'

type Task = Partial<MultiInputTaskWithItemsDto>

export interface MultiInputTaskFormProps {
  task: Task
  setTask: Dispatch<SetStateAction<Task>>
}

export const MultiInputTaskForm = ({ task, setTask }: MultiInputTaskFormProps) => {
  useEffect(() => {
    setTask({
      items: [],
    })
  }, [])

  const setTaskField = <Key extends keyof Task>(fieldName: Key, fieldValue: Task[Key]) => {
    setTask({
      ...task,
      [fieldName]: fieldValue,
    })
  }

  const setTaskItemField = <Key extends keyof MultiInputTaskItemDto>(
    itemKey: string,
    fieldName: Key,
    fieldValueOrResolveFieldValue:
      | MultiInputTaskItemDto[Key]
      | ((item: MultiInputTaskItemDto) => MultiInputTaskItemDto[Key])
  ) => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        if (!draftItems) {
          return
        }

        const updatedItem = draftItems.find(item => item.key === itemKey)

        if (!updatedItem) {
          return
        }

        updatedItem[fieldName] =
          fieldValueOrResolveFieldValue instanceof Function
            ? fieldValueOrResolveFieldValue(updatedItem)
            : fieldValueOrResolveFieldValue
      })
    )
  }

  const deleteItemFromArray = <T,>(array: T[] | undefined, key: string, resolveId: (item: T) => string) => {
    if (!array) {
      return
    }

    const deletedItemIndex = array.findIndex(item => resolveId(item) === key)

    if (isNullish(deletedItemIndex)) {
      return
    }

    array.splice(deletedItemIndex, 1)
  }

  const deleteTaskItem = (itemKey: string) => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        deleteItemFromArray(draftItems, itemKey, item => item.key || '')
      })
    )
  }

  const addItemToArray = <T,>(array: T[] | undefined, item: T) => {
    if (!array) {
      return
    }

    array.push(item)
  }

  const createTaskItem = () => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        addItemToArray(draftItems, {
          id: undefined,
          answerText: '',
          questionText: '',
          key: uuidv4(),
        })
      })
    )
  }

  return (
    <div>
      <div className={cn('form-group', classes.questionList)}>
        <label>Вопросы к задаче</label>
        {task.items?.map((item, index) => (
          <div key={item.key} className={classes.questionTextInput}>
            <label className={classes.questionTextInput__label}>{index + 1})</label>
            <div>
              <MdEditor
                value={item.questionText}
                onChange={html => setTaskItemField(item.key || '', 'questionText', html.text)}
                renderHTML={text => <ReactMarkdown source={text} />}
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: true,
                    fullScreen: true,
                    hideMenu: true,
                  },
                  table: {
                    maxRow: 5,
                    maxCol: 6,
                  },
                  syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
                }}
              />
              <div className={classes.answer}>
                <label>Ответ</label>
                <div className={classes.inputWithIcon}>
                  <FormControl
                    value={item.answerText}
                    onChange={e => setTaskItemField(item.key || '', 'answerText', e.target.value)}
                    className={cn('form-control', classes.inputWithIcon__input)}
                    type="text"
                    placeholder="Текст вопроса"
                  />

                  <button
                    onClick={() => deleteTaskItem(item.key || '')}
                    type="button"
                    className={cn('btn', 'btn-sm', classes.iconButton, classes.inputWithIcon__icon)}
                  >
                    <i className="mdi mdi-delete-outline" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <button type="button" className={cn('btn', 'btn-link', classes.linkButton)} onClick={createTaskItem}>
          Добавить вопрос
        </button>
      </div>
    </div>
  )
}
