import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { ChiefCoursesService } from 'src/services/chief-services/chief-courses-service'
import { CuratorCoursesService } from 'src/services/curator-services/curator-courses-service'
import RecruitmentStudentsModalWindow from '../recruitment-students-modal-window'
import { CuratorDirectionsService } from '../../../../../../services/curator-services/curator-direction-service'
import { CuratorMentorService } from '../../../../../../services/curator-services/curator-mentor-service'
import { CuratorRecruitmentService } from '../../../../../../services/curator-services/curator-recruitment-service'
import TableHeaderSorting from '../../../../../shared/table-header-sorting'
import { DirectionGetDto } from '../../../../../../model/direction-model'
import { RecruitmentTableRender } from './recruitment-table-render'
import { EditRecruitmentModal } from './recruitment-modal/edit-recruitment-modal'
import { AuthService } from '../../../../../../services/auth-service'
import { RoleEnum } from '../../../../../../utils/select-state/RoleEnum'
import { ChiefDirectionsService } from '../../../../../../services/chief-services/chief-directions-service'
import ChiefMentorsService from '../../../../../../services/chief-services/chief-mentor-service'
import { ChiefRecruitmentService } from '../../../../../../services/chief-services/chief-recruitment-service'
import './recruitment-wrapper.scss'
import { RecruitmentStudentEntities } from '../../../../../../model/recruitment-dto/recruitment-student-entities'
import { MentorGetDto } from '../../../../../../model/mentor-model'
import { CreateRecruitmentModal } from './recruitment-modal/create-recruitment-modal/create-recruitment-modal'
import { useReactAlert } from '../../../../../../hooks/useReactAlert'
import { RecruitmentServiceInterface } from '../../../../../../services/interfaces/curator-services/curator-recruitment-service'
import { DirectionServiceInterface } from '../../../../../../services/interfaces/direction-service'
import { MentorsServiceInterface } from '../../../../../../services/interfaces/mentors-service'
import { CoursesServiceInterface } from '../../../../../../services/interfaces/curator-services/curator-courses-service'
import { DirectionCourseDto } from '../../../../../../model/courses-dto/direction-course-dto'

enum SortTypes {
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  MENTOR_ASC = 'MENTOR_ASC',
  MENTOR_DESC = 'MENTOR_DESC',
}

const RecruitmentComponentWrapper: React.FC<Wrapper<RecruitmentDto>> = ({
  entitiesArray,
  setSortType,
  sortType,
  fetchPage,
}) => {
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  const [currentCourses, setCurrentCourses] = useState<DirectionCourseDto[]>([])
  const [recruitmentIdToEdit, setRecruitmentIdToEdit] = useState<number | null>(null)
  const [entityToEdit, setEntityToEdit] = useState<RecruitmentDto | null>(null)
  const [directions, setDirections] = useState<DirectionGetDto[]>([])
  const [currentDirectionId, setCurrentDirectionId] = useState<string>('')
  const [mentors, setMentors] = useState<MentorGetDto[]>([])
  const [studentsRecruitmentId, setStudentsRecruitmentId] = useState<number | null>(null)
  const [recruitmentStudents, setRecruitmentStudents] = useState<RecruitmentStudentEntities[] | null>(null)
  const [isCreateModalShow, setIsCreateModalShow] = useState<boolean>(false)
  const [isEditModalShow, setIsEditModalShow] = useState<boolean>(false)
  const currentRole = AuthService.currentUserValue()?.role.name
  const isChief = RoleEnum.CHIEF_MENTOR === currentRole

  let recruitmentService: RecruitmentServiceInterface
  let directionService: DirectionServiceInterface
  let mentorService: MentorsServiceInterface
  let courseService: CoursesServiceInterface

  if (isChief) {
    recruitmentService = ChiefRecruitmentService
    directionService = ChiefDirectionsService
    mentorService = ChiefMentorsService
    courseService = ChiefCoursesService
  } else {
    recruitmentService = CuratorRecruitmentService
    directionService = CuratorDirectionsService
    mentorService = CuratorMentorService
    courseService = CuratorCoursesService
  }

  useEffect(() => {
    if (studentsRecruitmentId !== null) {
      recruitmentService
        .getRecruitmentStudents(studentsRecruitmentId)
        .then(data => {
          setRecruitmentStudents(data)
        })
        .catch(catchErrorAlert)
    }
  }, [studentsRecruitmentId])

  //  Загрузка списка направлений при открытии модалки
  useEffect(() => {
    if (isCreateModalShow) {
      directionService
        .getAll()
        .then(setDirections)
        .catch(catchErrorAlert)
    } else {
      setCurrentDirectionId('')
      setMentors([])
    }
  }, [isCreateModalShow])

  useEffect(() => {
    if (isEditModalShow && recruitmentIdToEdit) {
      directionService
        .getAll()
        .then(setDirections)
        .catch(catchErrorAlert)
      recruitmentService
        .getRecruitmentById(recruitmentIdToEdit)
        .then(setEntityToEdit)
        .catch(catchErrorAlert)
    } else {
      setCurrentDirectionId('')
      setMentors([])
      setEntityToEdit(null)
    }
  }, [isEditModalShow])

  // Загрузка менторов по выбранному направлению
  useEffect(() => {
    if (currentDirectionId || entityToEdit || isChief) {
      const value = entityToEdit ? entityToEdit.directionId : +currentDirectionId
      mentorService
        .getMentors(value)
        .then(setMentors)
        .catch(catchErrorAlert)
      if (currentDirectionId) {
        courseService
          .getAll({
            directionId: Number(currentDirectionId),
          })
          .then(setCurrentCourses)
          .catch(catchErrorAlert)
      } else {
        courseService
          .getAll({})
          .then(setCurrentCourses)
          .catch(catchErrorAlert)
      }
    }
    setMentors([])
  }, [currentDirectionId, entityToEdit])

  const WrapperProps: RecruitmentTableRenderProps = {
    createSlackChat: recruitmentService.createSlackChat,
    setIsEditModalShow,
    setStudentsRecruitmentId,
    setRecruitmentIdToEdit,
    fetchPage,
    isChief,
  }

  let tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: t('recruitment'),
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('recruitment')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('recruitment')} ↑` },
      },
    },
    {
      header: t('ChannelName'),
    },
    {
      header: t('CommonSlackChannel'),
    },
    {
      header: t('PreparationDirection'),
      sortOptions: {
        asc: { action: SortTypes.DIRECTION_ASC, label: `${t('PreparationDirection')} ↑` },
        desc: { action: SortTypes.DIRECTION_DESC, label: `${t('PreparationDirection')} ↓` },
      },
    },
    {
      header: t('startTraining'),
      sortOptions: {
        asc: {
          action: SortTypes.DATE_ASC,
          label: `${t('startTraining')} ↑`,
        },
        desc: {
          action: SortTypes.DATE_DESC,
          label: `${t('startTraining')} ↓`,
        },
      },
    },
    {
      header: t('mentor'),
      sortOptions: {
        asc: {
          action: SortTypes.MENTOR_ASC,
          label: `${t('mentor')} ↑`,
        },
        desc: {
          action: SortTypes.MENTOR_DESC,
          label: `${t('mentor')} ↓`,
        },
      },
    },
    {
      header: t('DirectionStudents'),
    },
  ]

  if (isChief) {
    tableHeaders = tableHeaders.filter(headerItem => headerItem.header !== t('PreparationDirection'))
  }

  return (
    <>
      <div className="pagination-wrapped">
        <Button
          variant="success"
          onClick={() => {
            setIsCreateModalShow(true)
          }}
        >
          Добавить набор
        </Button>
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {tableHeaders.map(header => (
                <th key={`${header.header}`}>
                  <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <RecruitmentTableRender entitiesArray={entitiesArray} WrapperPropsObj={WrapperProps} />
        </table>
      </div>
      <RecruitmentStudentsModalWindow
        studentsRecruitmentId={studentsRecruitmentId}
        show={studentsRecruitmentId !== null && recruitmentStudents !== null}
        onHide={() => {
          setRecruitmentStudents(null)
          setStudentsRecruitmentId(null)
        }}
        entities={recruitmentStudents !== null ? recruitmentStudents : []}
      />
      {isEditModalShow && (
        <EditRecruitmentModal
          entity={entityToEdit}
          recruitmentService={recruitmentService}
          show={isEditModalShow}
          mentors={mentors}
          directions={directions}
          currentCourses={currentCourses}
          setCurrentDirectionId={setCurrentDirectionId}
          onHide={() => {
            setIsEditModalShow(false)
            setEntityToEdit(null)
            setRecruitmentIdToEdit(null)
            if (fetchPage) fetchPage()
          }}
        />
      )}
      <CreateRecruitmentModal
        recruitmentService={recruitmentService}
        show={isCreateModalShow}
        mentors={mentors}
        directions={directions}
        setCurrentDirectionId={setCurrentDirectionId}
        currentCourses={currentCourses}
        onHide={() => {
          setIsCreateModalShow(false)
          setEntityToEdit(null)
          setRecruitmentIdToEdit(null)
          setCurrentCourses([])
          if (fetchPage) fetchPage()
        }}
      />
    </>
  )
}

export interface RecruitmentTableRenderProps {
  createSlackChat: (recruitmentId: number) => Promise<unknown>
  setIsEditModalShow: Dispatch<SetStateAction<boolean>>
  setStudentsRecruitmentId: Dispatch<SetStateAction<number | null>>
  setRecruitmentIdToEdit: Dispatch<number | null>
  fetchPage?: () => void
  isChief: boolean
}

export enum ButtonTypes {
  SLACK_CHAT = 'SLACK_CHAT',
}

export type lLoadingButtons = {
  buttonId: number
  buttonType: keyof typeof ButtonTypes
}

export default RecruitmentComponentWrapper
