import React from 'react'
import Modal from 'react-modal'
import './planning-review-create-modal.css'
import { zoomAccounts } from '../../../../../../../../config'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    margin: '44px 0',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const PlanningReviewCreateModal = function PlanningReviewCreateModal({
  courses,
  modules,
  selectedDate,
  selectedTime,
  onChangeCourseSelector,
  onChangeModuleSelector,
  onChangeZoomAccount,
  availableSlots,
  onAvailableSlotsChange,
  createReview,
  modalShowed,
  onClose,
  setDefaultReviewData,
}) {
  const coursesNames = courses.map(course => {
    const { id, name } = course
    return (
      <option key={id} value={id}>
        {name}
      </option>
    )
  })

  const modulesNames = modules.map(module => {
    const { id, name } = module
    return (
      <option key={id} value={id}>
        {name}
      </option>
    )
  })

  const zoomAccs = zoomAccounts.map(acc => {
    return (
      <option key={acc} value={acc}>
        {acc}
      </option>
    )
  })

  return (
    <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <div className="planning-review-modal">
        <header className="add">
          <h3 id="planning-review-modal-header">Планирование нового ревью</h3>
        </header>
        <main>
          <span className="label-box">
            <label>
              Дата создания ревью: {selectedDate} в {selectedTime}
            </label>
          </span>
          <span className="selector-box">
            <label>
              <span>Курс:</span>
              <select className="course-selector form-control" onChange={onChangeCourseSelector}>
                <option defaultValue>Выбери курс</option>
                {coursesNames}
              </select>
            </label>
            <label>
              <span>Модуль:</span>
              <select className="module-selector form-control" onChange={onChangeModuleSelector}>
                <option defaultValue>Выбери модуль</option>
                {modulesNames}
              </select>
            </label>
          </span>
          <span className="selector-box">
            <label htmlFor="reviewMembersCount">
              <span>Количество человек:</span>
              <input
                name="availableSlots"
                className="form-control"
                type="text"
                inputMode="numeric"
                value={availableSlots}
                onChange={onAvailableSlotsChange}
              />
            </label>
            <label htmlFor="zoomAcc">
              <span>Назначить Zoom аккаунт:</span>
              <select name="zoomAcc" className="module-selector form-control" onChange={onChangeZoomAccount}>
                <option defaultValue>Зум аккаунт не выбран</option>
                {zoomAccs}
              </select>
            </label>
          </span>
        </main>
        <footer>
          <span className="planning-review-modal-group-button">
            <span className="wrap-block">
              <button
                type="button"
                className="create-button"
                onClick={() => {
                  createReview()
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Создать
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Отменить
              </button>
            </span>
          </span>
        </footer>
      </div>
    </Modal>
  )
}

export default PlanningReviewCreateModal
