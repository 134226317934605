import React, { useEffect, useState } from 'react'

import './student-comment.css'
import date from 'date-and-time'

const StudentComment = ({
  studentReviewId,
  comment,
  creatOrUpdateStudentReviewComment,
  deleteStudentReviewComment,
}) => {
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const commentsListElement = document.querySelector('.drop-down__list')
    if (commentsListElement && edit) {
      commentsListElement.style.maxHeight = `${commentsListElement.scrollHeight}px`
    }
  }, [edit])

  const editCurrentComment = () => {
    setEdit(true)
  }

  const { studentReviewId: studentReviewIdComment, mentorName, reviewTime: dateReview, commentValue } = comment

  const reviewDate = new Date(dateReview[0], dateReview[1] - 1, dateReview[2])
  const dateFormat = date.format(reviewDate, 'DD.MM.YYYY', false)
  const key = `comment${studentReviewIdComment}`

  const onEditStudentReviewComment = () => {
    const commentText = document.getElementById('idCommentText').value
    creatOrUpdateStudentReviewComment(studentReviewId, commentText)
    setEdit(false)
  }

  const onDeleteStudentReviewComment = () => {
    deleteStudentReviewComment(studentReviewId)
  }

  const editComment = (
    <div className="comment-edit">
      <textarea id="idCommentText" className="comment-text-edit" rows="5" name="text">
        {commentValue}
      </textarea>
      <div className="button-save" onClick={() => onEditStudentReviewComment()}>
        <div className="comment-button-save-text">Сохранить</div>
      </div>
    </div>
  )

  return (
    <>
      {edit ? (
        editComment
      ) : (
        <div key={key} className="comment-item">
          <div className="comment-text">
            <p className="mentorName">
              {mentorName} ({dateFormat})
            </p>
            <div className="mentorMessage">{commentValue}</div>
          </div>
          {studentReviewIdComment === studentReviewId ? (
            <div className="comment-button">
              <div className="button-edit" title="Редактирование комментария" onClick={() => editCurrentComment()}>
                <i className="mdi mdi-pencil" />
              </div>
              <div className="button-clear" title="Очистить комментарий" onClick={() => onDeleteStudentReviewComment()}>
                <i className="mdi mdi-close" />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default StudentComment
