import { AuthService } from './auth-service'
import JSONUtil from '../utils/JSONUtil'
import { Response as AppResponse } from '../model/response-dto/response-model'

export const CLIENT_ERRORS_STATUS: number[] = [401, 403]

class ApiService {
  static handleFunctions = <T>(response: Response): Promise<T> => {
    return ApiService.handleResponse<T>(response).then(handledResponse => {
      if (!handledResponse.success) {
        throw handledResponse.error
      }
      return handledResponse.data
    })
  }

  public static request = (<T>(url: string, init?: RequestInit) => {
    return fetch(url, init).then(ApiService.handleFunctions)
  }) as RequestFn

  public static generateControllerRequestHelper(controllerUrl: string) {
    return <T>(url: string, init: RequestInit = {}): Promise<T> => {
      const options: RequestInit = {
        ...init,
        headers: {
          ...init.headers,
          ...AuthService.authHeaders(),
        } as Headers,
      }

      return ApiService.request<T>(`${controllerUrl}/${url}`, options)
    }
  }

  private static handleError(err: Error) {
    AuthService.logout()
    return Promise.reject(err)
  }

  private static handleResponse<T>(response: Response): Promise<AppResponse<T>> {
    return response.text().then(text => {
      const data = JSONUtil.tryParseJSON(text)
      if (data.error) {
        const currentCodeStatus = data.error.code
        if (CLIENT_ERRORS_STATUS.includes(currentCodeStatus)) {
          return ApiService.handleError(data.error)
        }
      }
      return data
    })
  }
}

type RequestFn = {
  <T = unknown>(url: string, init?: RequestInit, type?: 'json'): Promise<T>
}

export default ApiService
