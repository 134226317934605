import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { AuthService } from '../auth-service'
import { HTTPMethods } from '../http-enums-methods'
import { RecruitmentServiceInterface } from '../interfaces/curator-services/curator-recruitment-service'
import { RecruitmentServiceDto } from '../../model/recruitment-dto/recruitment-service-dto'
import { RecruitmentStudentEntities } from '../../model/recruitment-dto/recruitment-student-entities'

const Url = {
  ROOT: `${serverHost}/api/curator/recruitment`,
}

const requestRecruitmentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorRecruitmentService: RecruitmentServiceInterface = class CuratorRecruitmentService {
  static createSlackChat = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${Url.ROOT}/${recruitmentId}/slack`
    return ApiService.request<void>(url, queryOption)
  }

  static createRecruitment = (recruitmentData: RecruitmentServiceDto) => {
    const init = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(recruitmentData),
    }
    return requestRecruitmentController('', init)
  }

  static editRecruitment = (recruitmentId: number, recruitmentData: RecruitmentServiceDto) => {
    const init = {
      method: HTTPMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(recruitmentData),
    }
    return requestRecruitmentController(`${recruitmentId}`, init)
  }

  static getRecruitmentStudents = (recruitmentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${Url.ROOT}/${recruitmentId}/student`
    return ApiService.request<RecruitmentStudentEntities[]>(url, queryOption)
  }

  static getRecruitmentById = (recruitmentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${Url.ROOT}/${recruitmentId}`
    return ApiService.request<RecruitmentDto>(url, queryOption)
  }
}
